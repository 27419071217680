<template>
  <div>
    <div id="interneerfassung-modal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">
              {{ $t("global.internalcodeentry") }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="projekterfassung.internercode"
                  :options="internecodes"
                  label="bezeichnung"
                  :placeholder="$t('global.internalcode')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.internalcode") }}</label>
              </div>
            </div>
            <div class="row col-xl-12 d-flex justify-content-between">
              <div class="col-xl-8">
                <div class="form-group">
                  <input
                    v-model.trim="projekterfassung.stunden"
                    @change="notifyIfError"
                    class="form-control"
                  />
                  <label>{{ $t("global.hours") }}</label>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="form-group">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="setMaxTime"
                  >
                    {{ $t("max") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model="projekterfassung.bemerkung"
                  placeholder=""
                  rows="3"
                  class="form-control"
                ></textarea>
                <label>{{ $t("global.remarks") }}</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="isValid"
              class="btn btn-primary"
              @click="uebernehmen"
            >
              {{ $t("global.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import ModalCloseButton from "@/components/global/ModalCloseButton";
import { LADE_AUSWAHLWERTE_ZEITERFASSUNG } from "@/store/zeiterfassung/actions.type";

export default {
  name: "InterneerfassungModal",
  components: { ModalCloseButton },
  props: {
    arbeitszeit: {
      type: Number,
      required: true,
    },
    rapportierteStunden: {
      type: Number,
      required: true,
      default: 0,
    },
    nichtrapportierteStunden: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      projekterfassung: {
        stunden: null,
        timesheet: null,
        id: null,
        internercode: { bezeichnung: "" },
      },
      stundenVorUpdate: 0,
      shown: false,
    };
  },
  computed: {
    isValid: function () {
      if (
        this.projekterfassung.internercode?.id &&
        this.projekterfassung.stunden > 0 &&
        this.rapportierteStunden +
          (parseFloat(this.projekterfassung.stunden) - this.stundenVorUpdate) <=
          this.arbeitszeit
      )
        return false;
      else return true;
    },
    internecodes: {
      get() {
        return this.$store.state.zeiterfassung.internecodes;
      },
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("interneerfassung-modal", (data) => {
      if (!this.shown) {
        if (data) {
          if (data.projekterfassung)
            this.projekterfassung = Object.assign({}, data.projekterfassung);

          this.projekterfassung.timesheet = data.timesheet;
        }
        this.stundenVorUpdate = this.projekterfassung.stunden;
        this.openModal();
      }
    });

    if (this.internecodes.length == 0)
      this.$store.dispatch(`zeiterfassung/${LADE_AUSWAHLWERTE_ZEITERFASSUNG}`);
  },
  methods: {
    openModal() {
      this.shown = true;

      $("#interneerfassung-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      this.$emit("abbrechen");
      $("#interneerfassung-modal").modal("hide");
      this.projekterfassung = {
        stunden: null,
        timesheet: null,
        id: null,
        internercode: { bezeichnung: "" },
      };
    },

    uebernehmen() {
      this.$emit("uebernehmeInterneErfassung", this.projekterfassung);
      this.closeModal();
    },

    //wird onchange des InterneStundenerfassungs-Inputs überprüft
    notifyIfError() {
      if (
        this.projekterfassung.internercode?.id &&
        this.projekterfassung.stunden > 0 &&
        this.rapportierteStunden +
          (parseFloat(this.projekterfassung.stunden) - this.stundenVorUpdate) >
          this.arbeitszeit
      ) {
        this.$notify({
          type: "error",
          title: this.$t("notification.actionunsuccessful"),
          text: "Mehr rapportierte Stunden als Arbeitszeit!",
        });
      }
    },
    setMaxTime() {
      this.projekterfassung.stunden = this.nichtrapportierteStunden;
    },
  },
};
</script>

<style>
</style>
