<template>
  <div
    id="fachkompetenzen-uebernehmen-modal"
    class="modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-passwortAendern">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            Bearbeite Fachkompetenzen & Sozialverhalten
          </h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <p class="text-danger ml-3">
              Bemerkungen werden allen Präsenzen angehängt.
            </p>
            <div class="col-xl-12 form-group mb-1">
              <textarea
                type="text"
                v-model="fachkompetenz"
                class="form-control"
                :required="true"
                placeholder=""
              />
              <label>{{
                $t("global.commentonsubjectprofessionalskills")
              }}</label>
            </div>
            <div class="col-xl-12 form-group mb-1">
              <textarea
                type="text"
                v-model="sozialverhalten"
                class="form-control"
                :required="true"
                placeholder=""
              />
              <label>{{ $t("global.commentonworksocialbehavior") }}</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="speichern">
            {{ $t("global.save") }}
          </button>
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { modal } from "@/mixins/Modal";

import store from "@/store";

export default {
  name: "FachkompetenzenUebernehmenModal",
  mixins: [modal],
  components: { ModalCloseButton },
  store,
  props: {
    id: {
      type: String,
      default: "fachkompetenzen-uebernehmen-modal",
    },
  },
  data() {
    return {
      shown: false,
      fachkompetenz: "",
      sozialverhalten: "",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    speichern() {
      this.$emit("confirmed", {
        fachkompetenz: this.fachkompetenz,
        sozialverhalten: this.sozialverhalten,
      });
      this.closeModal();
    },
  },
};
</script>

<style>
</style>
