<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="dozentenbuchung-dozent"
                      :person="filters['dozent']"
                      :label="$t('global.lecturer')"
                      :allowOpen="false"
                      :editable="true"
                      :tabindex="3"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['person.personName']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.lecturer") }}</label>
                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        placeholder=" "
                        :initial="filters['veranstaltung.startdatum']"
                        :show-icon="true"
                        field="veranstaltung.startdatum"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.fromdate") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        placeholder=" "
                        :initial="filters['veranstaltung.enddatum']"
                        :show-icon="true"
                        field="veranstaltung.enddatum"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.untildate") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="dozentenbuchung-veranstaltung"
                      :label="$t('global.event')"
                      :veranstaltung="filters.veranstaltung"
                      :allowOpen="false"
                      editable
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.semester"
                        :options="semester"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :placeholder="$t('global.semester')"
                        multiple
                      >
                      </v-select>
                      <label>{{ $t("global.semester") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.dozentenstatus"
                        :options="dozentenstati"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statuslecturer')"
                        multiple
                      >
                      </v-select>
                      <label>{{ $t("global.statusbooking") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.abrechnungsstatus"
                        :options="abrechnungsstati"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statusbilling')"
                        multiple
                      >
                      </v-select>
                      <label>{{ $t("global.statusbilling") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.anstellungsart"
                        :options="anstellungsarten"
                        :reduce="(aa) => aa.id"
                        label="bezeichnung"
                        :placeholder="$t('global.typesofemployment')"
                      >
                      </v-select>
                      <label>{{ $t("global.typesofemployment") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.lecturerbookinglist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <!--
                    <router-link
                      class="btn btn-success"
                      v-if="berechtigungen.m_dozierende.create"
                      :to="{ name: 'Neue Dozentenbuchung' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                    -->

                    <button
                      class="btn btn-primary ml-2"
                      @click="einsatzvertragAusgeben"
                      v-if="isAdmin"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      {{ $t("global.outputdeploymentcontract") }}
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      @click="exportToExcel(listData, 'dozentenbuchungen')"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.export") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_dozierende.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="dozentenbuchung-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="dozentenbuchungLoeschen"
    />

    <messagebox-count
      :headerText="$t('global.lecturerbookingdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import server from "@/server";
import excelExport from "@/mixins/ExcelExport";
import DatePicker from "@/components/Datepicker";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";
import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";

export default {
  name: "Dozentenbuchungsliste",
  components: {
    Navbar,
    LoeschenModal,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
    SingleSelectVeranstaltung,
    SingleSelectPerson,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.lecturerbookings");
      },
    };
  },
  mixins: [page, dashboard, excelExport, filter],
  store,
  data() {
    return {
      pdfPath: "",
      pdfFiles: [],
      buchungObj: {},
      isAdmin: false,
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.lecturer"),
        },
        {
          key: "veranstaltung.bezeichnungKuerzel",
          searchable: true,
          sortable: true,
          label: this.$t("global.event"),
        },
        {
          key: "dozentenstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.statuslecturer"),
        },
        {
          key: "lohnansatz.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.wagerate"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.dozent = this.filters.dozent?.id;
      params.filterPerson = this.filters["person.personName"];
      params.anstellung = this.filters.anstellungsart;
      params.veranstaltung = this.filters.veranstaltung?.id;
      params.periode = this.setQueryParamList(this.filters.semester);
      params.dozentenstatus = this.setQueryParamList(
        this.filters.dozentenstatus
      );
      params.abrechnungsstatus = this.setQueryParamList(
        this.filters.abrechnungsstatus
      );
      params.filterVon = this.filters["veranstaltung.startdatum"];
      params.filterBis = this.filters["veranstaltung.enddatum"];

      // TODO

      params.VBezKuerzel = this.filters["veranstaltung.bezeichnungKuerzel"];
      // paramsDSBez. = this.filters['dozentenstatus.bezeichnung']
      // paramsLABez. = this.filters['lohnansatz.bezeichnung']

      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },
    dozentenstati: {
      get() {
        return this.$store.state.dozent.dozentenstati;
      },
    },
    abrechnungsstati: {
      get() {
        return this.$store.state.dozent.abrechnungsstati;
      },
    },
    anstellungsarten: {
      get() {
        return this.$store.state.geschaeftspartner.anstellungsarten;
      },
    },
    semester: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
  },
  created() {
    this.initFilter("dozentenbuchung-liste", "dozentenbuchung/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (this.anstellungsarten.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    if (this.dozentenstati.length == 0)
      this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);

    if (this.semester.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    setFilterDozent(person) {
      this.$set(this.filters, "dozent", person);
    },

    details(dozentenbuchungen) {
      this.$router.push({
        name: "dozentenbuchung",
        params: { id: dozentenbuchungen.id, anzeige: 0 },
      });
    },

    dozentenbuchungLoeschen() {
      Api.delete("dozentenbuchung/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.$bus.$emit("searchWithFilter");
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "dozentenbuchung-loeschen-modal");
    },

    einsatzvertragAusgeben() {
      this.buchungObj = {};
      this.pdfFiles = [];
      let sortierteBuchungen = [];

      if (this.selectedObjects?.length > 0) {
        this.selectedObjects.forEach((buchung) => {
          if (!this.buchungObj[buchung?.person?.id]) {
            this.buchungObj[buchung?.person?.id] = {
              personName: buchung.person?.personName,
              buchungIds: buchung.id,
              anzahlBuchungen: 1,
            };
          } else {
            this.buchungObj[buchung?.person?.id].buchungIds += "," + buchung.id;
            this.buchungObj[buchung?.person?.id].anzahlBuchungen++;
          }
        });

        sortierteBuchungen = this.alphabetischSortieren(
          Object.values(this.buchungObj)
        );

        //Momentan noch keine Begrenzung fuer Anzahl Einsaetze. Einbauen anhand von anzahlEinsaetze und size anpassen, falls gefordert
        sortierteBuchungen.forEach((entry, index) => {
          this.generatePDF(index, entry);
        });
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nolecturerbookingselected"),
        });
      }
    },
    generatePDF(index, buchung) {
      let json = {
        idlist: buchung.buchungIds,
      };

      Api.post("printcache/", json, { params: {} }).then((response) => {
        let cacheId = response.data["cache-id"];
        let filename = buchung.personName;
        filename += new Intl.DateTimeFormat("ch").format(new Date());
        filename = filename.split(" ").join("");
        let size = buchung?.personName;
        if (size) size = size.trim();

        let pdfPath =
          "pdfgenerator/pdfcache.act?filename=Dozenteneinsatzvertrag" +
          filename +
          ".pdf&report=/api/pdfgenerator/dozenteneinsatzvertrag.htm&cache=" +
          cacheId;

        Api.get(pdfPath).then((response) => {
          let retValue = {
            index: index,
            url: server.url + response.data.filepath,
            withCredentials: true,
            count: Object.entries(this.buchungObj).length,
            size: size,
          };

          this.pdfFiles.push(retValue);
        });
      });
    },
    alphabetischSortieren(buchungen) {
      return buchungen.sort((a, b) => {
        if (!a.personName) {
          a.personName = "Name not found";
        }
        if (!b.personName) {
          b.personName = "Name not found";
        }
        return a.personName.localeCompare(b.personName);
      });
    },
    /*
    ausgebenEinzelvertrag() {
      if (this.selectedIds.length > 0) {
        this.selectedIds = Array.from(new Set(this.selectedIds));
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=EV${filename}.pdf&report=/pdfgenerator/einsatzvertrag.htm&ids=${this.selectedIds}`;
        this.$refs.pdfLayer.show();
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nolecturerbookingselected"),
        });
    },
*/
    setVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
    },
  },
};
</script>

<style></style>
