<template>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie px-3">
              <div class="body">
                <div class="row">
                  <div class="mut-header-bg ml-0">
                    <div 
                      class="mut-header pointer" 
                      style="height: 50px"
                      :class="{ 'mut-selected': activeHeadline === null }"
                      @click="setAlle"
                    >
                      <div class="mut-headers">
                        <span class="primary-headline-text">{{ $t("global.allpending") }}</span>
                      </div>
                    </div>
                    <div 
                      class="mut-header pointer" 
                      style="height: 50px"
                      :class="{ 'mut-selected': activeHeadline === false }"
                      @click="setPersoenlich"
                    >
                      <div class="mut-headers">
                        <span class="primary-headline-text">{{ $t("global.personal") }}</span>
                      </div>
                    </div>
                    <div 
                      class="mut-header pointer"
                      style="height: 50px" 
                      :class="{ 'mut-selected': activeHeadline === true }"
                      @click="setKontaktbasiert"
                    >
                      <div class="mut-headers">
                        <span class="primary-headline-text">{{ $t("global.contactbased") }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-between">
                    <div class="ml-4">
                        <button
                        class="btn btn-success"
                        @click="neueAktivitaet"
                        >
                        <font-awesome-icon
                            icon="fa-duotone fa-comments-alt"
                            class="mr-2"
                        />
                        <span>{{ $t("global.new") }}</span>
                        </button>
                    </div>
                   </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 block belegliste br-t-l-0">
                    <b-table
                      ref="todoTable"
                      class="text-center"
                      thead-class="sticky"
                      tbody-tr-class="item p-1"
                      responsive
                      hover
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-compare="sortDates"
                      :busy="pendenzenIsBusy"
                      show-empty
                      :items="aktivitaeten"
                      :fields="fields"
                      @row-dblclicked="aktivitaetOeffnen"
                    >
                      <template
                        v-slot:cell(datum)="row"
                      >
                        <div class="datum" :class="datumColor(row.item)">
                          {{ row.item.geplantdatum }}
                        </div>
                      </template>
                      <template
                        v-slot:cell(status)="row"
                      >
                        <div class="status" :class="statusBackground(row.item)">
                          {{ row.item.status.bezeichnung }}
                        </div>
                      </template>
                      <template #cell(details)="row">
                        <b-button @click="row.toggleDetails" class="m-0" variant="outline-primary" size="sm">
                          <font-awesome-icon :icon="row.detailsShowing ? 'fa-chevron-up' : 'fa-chevron-down'" />                      </b-button>
                      </template>
                      <template #row-details="row">       
                        <div class="mb-2">
                            {{ row.item.beschreibung || $t("global.nodescription") }}
                        </div>                      
                      </template>
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle"></b-spinner>
                        </div>
                      </template>
  
                      <template #empty>
                        <div
                          v-if="pendenzenLadeFehler"
                          class="text-center text-danger my-2"
                        >
                          <strong>{{ $t("global.errorwhileloading") }}</strong>
                        </div>
                        <div
                          v-if="!pendenzenLadeFehler"
                          class="text-center text-primary my-2"
                        >
                          <strong>{{
                            $t("global.therearenopendingtodos")
                          }}</strong>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aktivitaet-modal
        :angemeldeterMitarbeiter="angemeldeterMitarbeiter"
        @aktivitaetCreated="aktivitaetErstellt"
        @aktivitaetChanged="aktivitaetGeaendert"
        @aktivitaetDeleted="aktivitaetDeleted"
      />
    </div>
  </template>
  
  <script>
  import Api from "@/Api";
  import store from "@/store";
  
  import { apiErrorToAlert } from "@/utils/Errorhandler";
  import { BSpinner } from "bootstrap-vue";
  import AktivitaetModal from "@/components/crm/AktivitaetModal";
  import page from "@/mixins/Page";
  
  export default {
    name: "PersonPendenzen",
    components: { BSpinner, AktivitaetModal },
    store,
    mixins: [ page ],
    props: {
        person: {
        type: String,
        required: false,
        },
    },
    data() {
      return {
        pendenzenIsBusy: false,
        pendenzenLadeFehler: false,
  
        aktivitaeten: [],
        sortBy: "geplantdatum",
        sortDesc: false,
        activeHeadline: null,
      };
    },
    created() {
      if (this.angemeldeterMitarbeiter) {
        this.getAktivitaeten();
      }
    },
    mounted() {},
    watch: {
      angemeldeterMitarbeiter: function (val) {
        if (val) {
          this.getAktivitaeten();
        }
      },
    },
    computed: {
      fields() {
        return [
          {
            key: "bezeichnung",
            sortable: true,
            label: this.$t("global.task"),
          },
          {
            key: "typ.bezeichnung",
            sortable: true,
            label: this.$t("global.type"),
          },
          {
            key: "datum",
            sortable: true,
            label: this.$t("global.dashboard_planneddate"),
          },
          {
            key: "person.personName",
            sortable: true,
            label: this.$t("global.creator"),
          },
          {
            key: "status",
            sortable: true,
            label: this.$t("global.status"),
          },
          {
            key: "details",
            sortable: false,
            label: "",
          },
        ];
      },
    },
    methods: {
      getAktivitaeten() {
        this.pendenzenIsBusy = true;
        let params = {};
  
        if (this.activeHeadline === null) {
          // Parameter für alle Aktivitäten
          params.personIdOr = this.person;
        } else if (this.activeHeadline === true) {
          // Nur kontaktbasierte Aktivitäten
          params.personId = this.person;
          params.art = true;
        } else if (this.activeHeadline === false) {
          // Nur persönliche Aktivitäten
          params.personId = this.person;
          params.art = false;
        }
  
        Api.get("crm/aktivitaet/", { params })
          .then((response) => {
            this.aktivitaeten = response.data;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.pendenzenLadeFehler = true;
          })
          .finally(() => {
            this.pendenzenIsBusy = false;
          });
      },
  
      neueAktivitaet() {
        let json = {
          person: this.ansprechpartner,
          neu: true,
        };
        this.$bus.$emit("aktivitaet-modal-show", json);
      },
  
      aktivitaetOeffnen(aktivitaet) {
        this.$bus.$emit("aktivitaet-modal-show", aktivitaet);
      },
  
      aktivitaetDeleted(aktivitaet) {
        Api.delete("crm/aktivitaet/", {
          params: { ids: aktivitaet.id },
        }).then(() => {
          this.getAktivitaeten();
        });
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text: this.$t("global.activitydeleted"),
        });
      },
      aktivitaetErstellt(aktivitaet) {
  
        let json = aktivitaet;
        json.lead = this.lead;
  
        Api.post("crm/aktivitaet/", json)
          .then(() => {
            this.getAktivitaeten();
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.activitysavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
      aktivitaetGeaendert(aktivitaet) {
  
        let json = aktivitaet;
        json.lead = this.lead;
  
        Api.put("crm/aktivitaet/", json, { params: { id: aktivitaet.id } })
          .then(() => {
            this.getAktivitaeten();
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.activitysavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
      setAlle () {
        this.activeHeadline = null;
        this.getAktivitaeten();
      },
      setPersoenlich () {
        this.activeHeadline = false;
        this.getAktivitaeten();
      },
      setKontaktbasiert () {
        this.activeHeadline = true;
        this.getAktivitaeten();
      },
      statusBackground(item) {
        let status = item.status;
  
        if (status) {
          //Geplant:
          if (status.id == "17FD0A1A4A1") {
            return "statusWarn";
          }
          //Durchgeführt:
          else if (status.id == "17FD0A1BEE5") {
            return "statusSuccess";
          }
          //Abgesagt:
          else if (status.id == "17FD0A1E99E") {
            return "statusDanger";
          } 
          // in Arbeit
          else {
            return "statusPrimary";
          }
        } else return "";
      },
      datumColor(datum) {
        //wenn datum oder datum.geplantdatum null ist, nichts returnen
        if (!datum || !datum.geplantdatum) return "";
        // Umwandeln des heutigen Datums in das numerische Format
      var today = ((date) => {
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          return parseInt(year, 10) * 10000 + parseInt(month, 10) * 100 + parseInt(day, 10);
      })(new Date());
  
      // Umwandeln von geplantdatum in das numerische Format für den Vergleich
      var datumNum = ((datumStr) => {
          const [day, month, year] = datumStr.split(".");
          return parseInt(year, 10) * 10000 + parseInt(month, 10) * 100 + parseInt(day, 10);
      })(datum.geplantdatum);
  
        if (datumNum < today) {
          return "pastDate";
        }
      }
    },
  };
  </script>
  