<template>
  <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  :readonly="!editable"
                  v-if="korrespondenz.translation && lang"
                  v-model.trim="
                    korrespondenz.translation[lang.kuerzel.toLowerCase()]
                      .betreff
                  "
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.subject") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <input
                  :readonly="!editable"
                  v-model.trim="korrespondenz.ort"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.city") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <v-select
                  v-model="unterschrift1"
                  :options="unterschriften"
                  :disabled="!editable"
                  @input="unterschrift1Updated"
                  :placeholder="$t('global.signature')"
                  :getOptionLabel="
                    (unterschrift) => unterschrift.person.personName
                  "
                >
                  <template #option="{ person }">
                    {{ person.personName }}
                  </template>
                  <template #selected-option="{ person }">
                    {{ person.id ? person.personName : $t("global.notlinked") }}
                  </template>
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.signature1") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <v-select
                  v-model="unterschrift2"
                  :options="unterschriften"
                  :disabled="!editable"
                  @input="unterschrift2Updated"
                  :placeholder="$t('global.signature')"
                  :getOptionLabel="
                    (unterschrift) =>
                      typeof unterschrift.person == 'string'
                        ? unterschrift.person
                        : unterschrift.person.personName
                  "
                >
                  <template #option="{ person }">
                    {{ person.personName }}
                  </template>
                  <template #selected-option="{ person }">
                    {{ person.id ? person.personName : $t("global.notlinked") }}
                  </template>
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.signature2") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6"></div>
            <div class="col-xl-6">
              <div class="form-group">
                <textarea
                  :readonly="!editable"
                  v-model.trim="korrespondenz.beilagen"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.Attachments") }}</label>
              </div>
            </div>
          </div>

          <!-- ENDE Korrespondenzversand -->

          <div class="row" v-if="korrespondenz.translation && lang">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :disabled="!editable"
                  :editor="editor"
                  v-model="
                    korrespondenz.translation[lang.kuerzel.toLowerCase()].text
                  "
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

import page from "@/mixins/Page";
import ClassicEditor from "/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_KORRESPONDENZ } from "@/store/korrespondenz/actions.type";

export default {
  name: "Korrespondenz",
  components: {},
  metaInfo() {},
  mixins: [page],
  store,
  props: {
    lang: {
      type: Object,
      required: true,
    },
    korrespondenz: {
      type: Object,
      required: true,
    },
    navbarTitel: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unterschrift1: { person: { personName: this.$t("global.notlinked") } },
      unterschrift2: { person: { personName: this.$t("global.notlinked") } },
      editor: ClassicEditor,
      //inhalt: "<p>Content of the editor.</p>",
      inhalt: null,
    };
  },
  computed: {
    unterschriften: {
      get() {
        return this.$store.state.korrespondenz.unterschriften;
      },
    },
    /*  unterschrift1Label() {
      if (this.unterschrift1?.person?.personName)
        return this.unterschrift1.person.personName;
      else return "";
    }, */
  },
  watch: {
    korrespondenz: {
      deep: true,
      handler(val) {
        if (val.unterschrift1 && val.unterschrift1.person) {
          this.unterschrift1 = val.unterschrift1;
        } else {
          this.unterschrift1 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }

        if (val.unterschrift2 && val.unterschrift2.person) {
          this.unterschrift2 = val.unterschrift2;
        } else {
          this.unterschrift2 = {
            person: { personName: this.$t("global.notlinked") },
          };
        }
      },
    },
  },

  created() {
    if (
      this.korrespondenz &&
      this.korrespondenz.unterschrift1 &&
      this.korrespondenz.unterschrift1.person
    ) {
      this.unterschrift1 = this.korrespondenz.unterschrift1;
    } else {
      this.unterschrift1 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }

    if (
      this.korrespondenz &&
      this.korrespondenz.unterschrift2 &&
      this.korrespondenz.unterschrift2.person
    ) {
      this.unterschrift2 = this.korrespondenz.unterschrift2;
    } else {
      this.unterschrift2 = {
        person: { personName: this.$t("global.notlinked") },
      };
    }
  },

  mounted() {
    if (this.unterschriften.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_KORRESPONDENZ}`);
  },

  methods: {
    unterschrift1Updated(val) {
      if (val && val.id) this.$emit("unterschrift1updated", val);
      else this.$emit("unterschrift1updated", null);
    },

    unterschrift2Updated(val) {
      if (val && val.id) this.$emit("unterschrift2updated", val);
      else this.$emit("unterschrift2updated", null);
    },
  },
};
</script>

<style></style>
