<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4>{{ this.bereich.bezeichnung }}</h4>
        </div>
        <div class="modal-body">
          <div class="row d-flex justify-content-between">
            <div class="col-xl-5 form-group">
              <v-select
                :disabled="!editable"
                v-model="sourceLanguage"
                label="kuerzel"
                :options="sourceLanguageList"
                :placeholder="$t('global.sourcelanguage')"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.sourcelanguage") }}</label>
            </div>
            <div class="col-xl-2 text-bottom my-auto mx-auto text-center">
              {{ $t("global.to") }}
            </div>
            <div class="col-xl-5 form-group">
              <v-select
                :disabled="!sourceLanguage || !editable"
                ref="mySelect"
                v-model="targetLanguageList"
                label="kuerzel"
                :options="avaibleTargetLanguageList"
                :placeholder="$t('global.targetlanguages')"
                :reduce="(el) => el.deepl_kuerzel"
                multiple
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.targetlanguages") }}</label>
            </div>
          </div>
          <div class="row p-0">
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  :readonly="!editable"
                  v-model="selectedItem.de.text"
                  class="form-control"
                  placeholder=" "
                  ref="inputEinstellungen"
                />
                <label>{{ $t("global.designation") }} - DE</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  :readonly="!editable"
                  v-model="selectedItem.en.text"
                  class="form-control"
                  placeholder=" "
                  ref="inputEinstellungen"
                />
                <label>{{ $t("global.designation") }} - EN</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  :readonly="!editable"
                  v-model="selectedItem.fr.text"
                  class="form-control"
                  placeholder=" "
                  ref="inputEinstellungen"
                />
                <label>{{ $t("global.designation") }} - FR</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  :readonly="!editable"
                  v-model="selectedItem.it.text"
                  class="form-control"
                  placeholder=" "
                  ref="inputEinstellungen"
                />
                <label>{{ $t("global.designation") }} - IT</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button
            v-if="berechtigungen.g_einstellungen.update"
            class="btn btn-primary"
            @click="translate"
            :disabled="!sourceLanguage || targetLanguageList.length === 0"
          >
            {{ $t("global.translate") }}
          </button>
          <button
            v-if="berechtigungen.g_einstellungen.update"
            class="btn btn-primary"
            :key="buttonReset"
            @click.once="uebernehmen"
            :disabled="!selectedItem.de.text && !selectedItem.en.text && !selectedItem.fr.text && !selectedItem.it.text"
          >
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import Auswahlwerte from "@/Auswahlwerte";
import { apiErrorToAlert } from "@/utils/Errorhandler";


export default {
  name: "EinstellungenModal",
  props: {
    id: {
      type: String,
      required: true,
    },
    path: {
      type: String,
    },
    bereich: {
      type: Object,
    },
    sourceLanguageList: {
      type: Array,
    },
  },
  data() {
    return {
      selectedItem: {},
      selectedItemDefault: {
        id: "",
        bezeichnung: "",
        de: {
          id: null,
          text: "",
          language: null,
        },
        en: {
          id: null,
          text: "",
          language: null,
        },
        fr: {
          id: null,
          text: "",
          language: null,
        },
        it: {
          id: null,
          text: "",
          language: null,
        },
      },
      shown: false,
      buttonReset: 1,
      sourceLanguage: "",
      avaibleTargetLanguageList: [],
      targetLanguageList: [],
    };
  },
  components: {
    ModalCloseButton,
  },
  computed: {
    editable() {
      return this.berechtigungen.g_einstellungen.update
    }
  },
  watch: {
      sourceLanguageList(){
      if(this.sourceLanguageList.length >= 0){
        this.selectedItemDefault.de.language = this.getLanguageId("de")
        this.selectedItemDefault.en.language = this.getLanguageId("en")
        this.selectedItemDefault.fr.language = this.getLanguageId("fr")
        this.selectedItemDefault.it.language = this.getLanguageId("it")
      }
        },

    sourceLanguage(val) {
      this.targetLanguageList = [];
      if (val) {
        this.avaibleTargetLanguageList = this.sourceLanguageList.filter(
          (language) => {
            return language !== val;
          }
        );
        this.targetLanguageList = this.targetLanguageList.filter((language) => {
          return language !== val;
        });
      } else {
        this.avaibleTargetLanguageList = [];
        this.targetLanguageList = [];
      }
    },
  },

  beforeMount() {
    this.selectedItem = this.selectedItemDefault;
  },
  mounted() {
    this.$bus.$on("openEinstellungenModal", (id, item) => {
      if (!this.shown) {
        this.openModal(id, item);
      }
    });
  },
  methods: {
    getLanguageId(val){
        return this.sourceLanguageList.find((obj) => obj.kuerzel == val).id
    },

    openModal(id, item) {
      if (item.id) {
        this.selectedItem = item;
      } else {
        this.selectedItem = JSON.parse(JSON.stringify(this.selectedItemDefault));
      }
      this.shown = true;

      $("#" + id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
      this.buttonReset++;

      this.sourceLanguage = "";
      this.avaibleTargetLanguageList = [];
      this.targetLanguageList = [];
    },

    translate() {
      this.isBusy = true;
      var json = Object.assign({
        multi_translation_request: {
          sourceLanguage: this.sourceLanguage.deepl_kuerzel,
          targetLanguageList: this.targetLanguageList,
          sourceText: this.selectedItem[this.sourceLanguage.kuerzel].text,
        },
        bezeichnung: this.selectedItem.bezeichnung,
        de: this.selectedItem.de,
        en: this.selectedItem.en,
        fr: this.selectedItem.fr,
        it: this.selectedItem.it,
      });
      Auswahlwerte.post("translate", json).then((response) => {
        this.selectedItem.bezeichnung = response.data.bezeichnung;
        this.selectedItem.de = response.data.de;
        this.selectedItem.en = response.data.en;
        this.selectedItem.fr = response.data.fr;
        this.selectedItem.it = response.data.it;
      }).catch((e) => {
        this.$notify(apiErrorToAlert(e));
      });
    },
    uebernehmen() {
      this.isBusy = true;

      var json = Object.assign({
        id: this.selectedItem.id,
        table_id: this.bereich.id,
        bezeichnung: this.selectedItem.bezeichnung,
        de: this.selectedItem.de,
        en: this.selectedItem.en,
        fr: this.selectedItem.fr,
        it: this.selectedItem.it,
      });

      if (this.selectedItem.id) {
        Auswahlwerte.put(
          this.path + "/" + this.selectedItem.id,
          json,
          this.bereich.id,
          {}
        )
          .then((response) => {
            this.isBusy = false;
            this.ladeFehler = false;
            this.closeModal();
            this.$emit("confirmed");
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.selectionvaluesuccessfullyedited"),
            });
          })
          .catch((e) => {
            this.ladeFehler = true;
            this.isBusy = false;
            this.$notify(apiErrorToAlert(e));
          });
      } else {
        Auswahlwerte.post(this.path, json)
          .then((response) => {
            this.ladeFehler = false;
            this.closeModal();
            this.$emit("confirmed");
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.selectionvaluesuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.ladeFehler = true;
            this.isBusy = false;
            this.$notify(apiErrorToAlert(e));
          });
      }
    },
  },
};
</script>

<style></style>
