<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.email')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button class="btn btn-success" @click="neueMail" v-if="editable">
              <font-awesome-icon
                icon="fa-duotone fa-envelope-open-text"
                class="mr-2"
              />
              <span>{{ $t("global.new") }}</span>
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
              :disabled="notYetSent"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="mailliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-compare="sortDatum"
              :busy="isBusy"
              show-empty
              sort-icon-left
              style="max-height: 300px"
              fixed
              responsive
              sticky-header
              selectable
              select-mode="range"
              @row-dblclicked="details"
              small
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="mail-loeschen-modal"
      @confirmed="mailLoeschen"
      :selectedIds="Array.from(new Set(selectedIds))"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import reiter from "@/mixins/Reiter";
export default {
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    person: {
      type: String,
      required: false,
    },
    firma: {
      type: String,
      required: false,
    },
    anmeldung: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    mailadressen: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      notYetSent: false,
      infiniteId: +new Date(),
      anzahlProPage: 100,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      mailliste: [],
      selected: [],
      selectedIds: [],
      selectedObjects: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "zeitpunkt",
          sortable: true,
          label: this.$t("global.createdon"),
        },
        { key: "betreff", sortable: true, label: this.$t("global.subject") },
        {
          key: "absender.personName",
          sortable: true,
          label: this.$t("global.sender"),
        },
        {
          key: "empfaengermail",
          sortable: true,
          label: this.$t("global.receiver"),
          formatter: (value, key, item) => {
            return item.isMassMail ? "Massenmail" : value;
          },
        },
        { key: "gesendet", sortable: true, label: this.$t("global.senton") },
        {
          key: "status",
          sortable: true,
          label: this.$t("global.processingstatus"),
          formatter: (value, key, item) => {
            return item.gesendet ? "Versendet" : "Entwurf";
          },
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  watch: {
    shown(val) {
      if (val && this.mailliste.length == 0) this.getMails();
    },
  },
  mounted() {
    if (this.shown && this.mailliste.length == 0) this.getMails();
  },
  created() {},
  methods: {
    getMails() {
      if (this.mailliste.length == 0) {
        this.isBusy = true;

        Api.get("/mail/", {
          params: {
            person: this.person,
            firma: this.firma,
            anmeldung: this.anmeldung,
            anzeige: 0,
            count: 50,
          },
        })
          .then((response) => {
            this.mailliste = response.data;
            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    details(mailliste) {
      this.$router.push({
        name: "mail",
        params: { id: mailliste.id, anzeige: 0 },
      });
    },
    /*
    neueMail() {
      this.$router.push({
        name: "mail",
        params: { id: null, anzeige: 0 },
      });
    },
*/

    neueMail() {
      if (this.mailadressen && this.mailadressen.length > 0) {
        let json = {
          empfaenger: [this.person],
          firma: [this.firma],
          empfaengermail: this.mailadressen[0].bezeichnung,
          betreff: "E-Mail vorbereitet",
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
          anmeldung: this.anmeldung,
        };

        if (json.empfaenger[0] == null) {
          json.empfaenger = null;
        }
        if (json.firma[0] == null) {
          json.firma = null;
        }

        Api.post("mail/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "mail",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      } else {
        this.$notify({
          type: "warning",
          title: this.$t("global.hint"),
          text: this.$t("global.nomailaddressdeposited"),
        });
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.checkAllowSend();
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedObjects.push(row.item);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
        this.selectedObjects = this.selectedObjects.filter(
          (item) => item.id != row.item.id
        );
      }
      this.checkAllowSend();
    },
    checkAllowSend() {
      if (this.selectedObjects.some((obj) => obj.gesendet !== null)) {
        this.notYetSent = true;
      } else {
        this.notYetSent = false;
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "mail-loeschen-modal");
    },

    mailLoeschen() {
      Api.delete("mail", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.$emit("confirmed");
        this.selectedIds = [];
        this.getMails();
      });
    },
    sortDatum(datumA, datumB, key) {
      if ((!key.includes("gesendet"), !key.includes("zeitpunkt"))) {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolveTermin(key, datumA);
          b = this.resolveTermin(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
