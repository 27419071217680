<template>
  <div class="row">
    <notifications></notifications>
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.documents')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="oeffneDokumentModal"
              v-if="dokumentEditable"
            >
              <font-awesome-icon
                icon="fa-duotone fa-file-import"
                class="mr-2"
              />
              <span>{{ $t("global.new") }}</span>
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="dokumentEditable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              style="max-height: 300px"
              :items="dokumentenliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-compare="sortDates"
              :busy="isBusy"
              show-empty
              sort-icon-left
              responsive
              selectable
              select-mode="range"
              @row-dblclicked="oeffneDokument"
              small
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #cell(edit)="row">
                <div
                  class="ml-5 d-flex align-items-center"
                  role="button"
                  @click="dokumentBearbeiten(row)"
                >
                  <font-awesome-icon icon="fa-regular fa-pencil" size="sm" />
                </div>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <dokument-modal
      :person="this.person"
      :foreigntable="this.foreignTable"
      :foreignkey="this.foreignKey"
      :gptyp="gptyp"
      :aktivitaet="this.aktivitaet"
      id="dokument-modal"
    ></dokument-modal>
    <loeschen-modal
      id="dokument-loeschen-modal"
      @confirmed="dokumentloeschen"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayerDokument"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import DokumentModal from "../Modals/DokumentModal.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import reiter from "@/mixins/Reiter";
import server from "@/server";

export default {
  name: "Dokumentenliste",
  components: {
    ContainerHeadline,
    DokumentModal,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    person: {
      type: String,
      required: false,
    },
    foreignKey: {
      type: String,
      required: false,
    },
    foreignTable: {
      type: String,
      required: false,
    },
    anmeldungid: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    dokumentEditable: {
      type: Boolean,
      required: true,
    },
    gptyp: {
      type: String,
      required: false,
    },
    aktivitaet: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 100,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      pdfFileName: "",
      dokumentenliste: [],
      newDokument: false,
      DokumentModalID: "dokument-modal",
      selected: [],
      selectedIds: [],
      pdfPath: "",
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        { key: "erstellt", label: this.$t("global.created"), class: this.aktivitaet ? "display-none" : ""},
        { key: "ersteller.personName", label: this.$t("global.creator"), class: this.aktivitaet ? "display-none" : ""},
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        { key: "name", sortable: true, label: this.$t("global.name"), class: this.aktivitaet ? "display-none" : ""},
        { key: "edit", sortable: false, label: this.$t("global.edit"), class: this.aktivitaet ? "display-none" : ""},
      ];
    },
  },
  watch: {
    shown(val) {
      if (val && this.dokumentenliste.length == 0) this.getDokumente();
    },
    person() {
      if (this.shown && this.dokumentenliste.length == 0) this.getDokumente();
    },
    aktivitaet() {
      if (this.shown && this.dokumentenliste.length == 0) this.getDokumente();
    },
  },
  mounted() {
    if (this.shown && this.dokumentenliste.length == 0) this.getDokumente();
  },
  created() {},
  methods: {
    getDokumente(newDokument) {
      if (this.dokumentenliste.length == 0 || newDokument) {
        this.isBusy = true;

        Api.get("dokument/", {
          params: {
            person: this.person,
            aktivitaet: this.aktivitaet,
            foreignkey: this.foreignKey,
            foreigntable: this.foreignTable,
          },
        })
          .then((response) => {
            this.dokumentenliste = response.data;
            this.ladeFehler = false;
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.ladeFehler = true;
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    details(dokument) {
      if (dokument.mimetype == "application/pdf") {
        // this.pdfPath = server.url + dokument.dokument;
        // this.$refs.pdfLayerDokumentReiter.show();
        this.$router.push({
          path: "/ressourcen/dokument/" + dokument.id,
        });
      } else {
        this.$router.push({
          path: "/ressourcen/dokument/" + dokument.id,
        });
      }
    },

    dokumentBearbeiten(row) {
      this.$router.push({
        path: "/ressourcen/dokument/" + row.item.id,
      });
    },

    oeffneDokumentModal() {
      $("#dokument-modal").modal("show");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "dokument-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nodocumentselected"),
        });
      }
    },

    dokumentloeschen() {
      Api.delete("dokument/", {
        params: { ids: this.selectedIds.join(",") },
      })
        .then(() => {
          this.$emit("updateComponent");
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: `${this.selectedIds.length} ${this.$t(
              "global.documentsdeleted"
            )}`,
          });
        })
        .finally(() => {
          this.getDokumente();
        });
    },
    oeffneDokument(d) {
      this.pdfPath = server.url + "/" + d.dokument;
      this.$refs.pdfLayerDokument.show();
    },
  },
};
</script>
