<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row mb-4">
                  <div class="col-xl-12">
                    <month-picker
                      @change="showDate"
                      lang="de"
                      :default-month="filters.month"
                      :default-year="filters.year"
                      style="width: 100%; font-size: 12px"
                    ></month-picker>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="mitarbeiter"
                      :person="filters.mitarbeiter"
                      :label="$t('global.employee')"
                      :allowOpen="false"
                      editable
                      :rollen="filterMitarbeitende"
                      @confirmed="setMitarbeiter"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.timesheets')"
              :anzahl="zeiterfassungListe.length"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <!--  Neues Zeitkonto erstellen -->
                    <button
                      class="btn btn-success mr-2"
                      @click="openTimeaccount"
                      v-if="berechtigungen.m_timereporting.create"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus"/>
                      Neues Zeitkonto
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_timereporting.delete"
                      :disabled="false"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      <span>{{ $t("timereporting.resettimesheets") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Start aktuelle Stunden Karten -->
            <div
              v-if="angemeldeterMitarbeiter"
              class="container-fluid tile-container p-0"
            >
              <div
                class="row pt-1 pb-1"
                v-if="berechtigungen.m_timereporting.read"
              >
                <div class="col-lg-12">
                  <b-card-group deck>
                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Feriensaldo"
                            style="font-size: 24px"
                            icon="fa-duotone fa-tree-palm"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                          >{{ zeitkonto.feriensaldo }} h
                          </b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Gleitzeitsaldo"
                            style="font-size: 24px"
                            icon="fa-duotone fa-house-laptop"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                          >{{ zeitkonto.gleitzeitsaldo }} h
                          </b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Stunden krank in diesem Jahr"
                            style="font-size: 24px"
                            icon="fa-duotone fa-face-mask"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                          >{{ zeitkonto.kranksaldo }} h
                          </b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Stunden Zivildienst in diesem Jahr"
                            style="font-size: 24px"
                            icon="fa-duotone fa-hand-holding-hand"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                          >{{ zeitkonto.zivildienstsaldo }} h
                          </b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Stunden Militär in diesem Jahr"
                            style="font-size: 24px"
                            icon="fa-duotone fa-person-rifle"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                          >{{ zeitkonto.militaersaldo }} h
                          </b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>
                  </b-card-group>
                </div>
              </div>
            </div>
            <!-- aktuelle Stunden Karten Ende -->

            <!-- Tabelle Start -->
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    hover
                    borderless
                    :tbody-tr-class="rowClass"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
            <!-- Tabelle Ende -->
          </div>
        </div>
      </div>
    </div>
    <messagebox-count
      :headerText="$t('global.timesheetsreset')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loeschen-modal
      id="timesheet-loeschen-modal"
      @confirmed="resetTimesheets"
      :multiple="true"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import store from "@/store";
import page from "@/mixins/Page";
import MessageboxCount from "@/components/Modals/MessageboxCount.vue";

import filter from "@/mixins/Filter";
import dashboard from "@/mixins/Dashboard";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
//import DatePicker from "@/components/Datepicker";

import {LADE_AUSWAHLWERTE_ZEITERFASSUNG} from "@/store/zeiterfassung/actions.type";

export default {
  name: "Zeiterfassung",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    //DatePicker,
    SingleSelectPerson,
    MessageboxCount,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.timereporting");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      currentMitarbeiter: null,
      zeiterfassungListe: [],
      isDisabled: true,
      selected: [],
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,

      zeitkonto: {},

      fields: [
        {key: "selected", label: ""},
        {
          key: "datum",
          label: this.$t("global.date"),
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },
        {
          key: "mitarbeiter",
          sortable: true,
          label: this.$t("global.employee"),
          searchable: false,
          formatter: (value) => {
            if (value) return value.personName;
            else return "";
          },
        },
        {
          key: "stunden",
          sortable: true,
          label: this.$t("global.stunden"),
          //tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
          formatter: (value) => {
            return value.toFixed(2);
          },
          tdClass(value, key, item) {
            let thisClass = "";
            const nums = item.datum.split(".");
            let dateString = nums[2] + "-" + nums[1] + "-" + nums[0];
            let thisdate = new Date(dateString);
            let today = new Date().getTime();
            if (item.stunden > 0 && item.stunden <= item.sollstunden * 1.1) {
              thisClass = "table-medium-green";
            }
            if (item.stunden > 0 && item.stunden > item.sollstunden * 1.1) {
              thisClass = "table-warning";
            }
            if (item.krank > 0 || item.militaer > 0 || item.zivildienst > 0) {
              thisClass = "table-dark";
            }
            if (
              item.stunden === 0 &&
              thisdate < today &&
              item.sollstunden !== 0
            ) {
              thisClass = "table-danger";
            }
            return "text-center " + thisClass;
          },
        },
        {
          key: "sollstunden",
          sortable: true,
          label: this.$t("global.targethours"),
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
          formatter: (value) => {
              if(value) return value?.toFixed(2);
              else return 0
          },
        },
        {
          key: "status",
          sortable: true,
          label: this.$t("global.status"),
          searchable: false,
          formatter: (value) => {
            if (value) return value.bezeichnung;
            else return "";
          },
        },
      ],
    };
  },
  watch: {},
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.ma = this.setQueryParam(this.filters.mitarbeiter?.id);
      params.filterCount = this.setQueryParam(this.filters.count);
      params.mo = this.setQueryParam(this.filters.month);
      params.y = this.setQueryParam(this.filters.year);

      return params;
    },

    timesheetstati: {
      get() {
        return this.$store.state.zeiterfassung.timesheetstati;
      },
    },
  },
  created() {
    this.currentMitarbeiter = this.$route.params.currentMitarbeiter;
    this.initFilter("zeiterfassung-liste", "zeiterfassung/timesheet/", true);
  },
  mounted() {
    if (this.timesheetstati.length == 0)
      this.$store.dispatch(`zeiterfassung/${LADE_AUSWAHLWERTE_ZEITERFASSUNG}`);
    /*  if (
      this.gruppen.find(
        (gruppe) => gruppe.id === this.$CONST("GRUPPEN").GENERICUSER
      )
    ) {

    } */
    if (this.currentMitarbeiter) {
      this.setMitarbeiter(this.currentMitarbeiter);
    } else {
      this.setMitarbeiter(this.angemeldeterMitarbeiter.person);
    }

    this.$set(this.filters, "month", new Date().getMonth() + 1);
    this.$set(this.filters, "year", new Date().getFullYear());

    this.setHeaders("global", [
      "",
      "date",
      "employee",
      "hours",
      "targethours",
      "status",
    ]);
    this.getZeitKonto();
    this.filterSearch();
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;

      let thisClass = "";
      if (item.sollstunden === 0) {
        thisClass = "table-secondary";
      }
      return "item " + thisClass;
    },
    // kopiert aus Anmeldungsliste.vue, funktioniert
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    // auch aus Anmeldungsliste.vue
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
        this.selectedIds = Array.from(new Set(this.selectedIds));
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    showDate(date) {
      if (!this.filters.mitarbeiter) return;
      this.$set(this.filters, "month", date.monthIndex);
      this.$set(this.filters, "year", date.year);

      this.filterSearch();
    },
    getZeitKonto() {
      let now = new Date();
      now =
        now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear();

      Api.get("zeiterfassung/account/", {
        params: {
          ma: this.filters.mitarbeiter?.id,
          date: now,
        },
      }).then((response) => {
        this.zeitkonto = response.data[0];
      });
    },
    details(timesheet) {
      this.$router.push({
        name: "timesheet",
        params: {
          id: timesheet.id,
          datum: timesheet.date,
          fromRoute: "timesheet-liste",
        },
      });
    },

    neuesTimesheet() {
      this.$router.push({
        name: "timesheet",
        params: {},
      });
    },

    resetTimesheets() {
      Api.delete("zeiterfassung/timesheet/", {data: this.selectedIds}).then(
        (response) => {
          let success = response.data.success;
          let error = response.data.error;
          this.$bus.$emit("openMessageBoxCount", {
            id: "count-delete-alert-msgbox",
            successText: "Erfolgreich: ",
            errorText: "Fehlgeschlagen: ",
            successCount: success.length,
            errorsCount: error.length,
          });
          this.resetLoading(this);
          /* this.initFilter(
            "zeiterfassung-liste",
            "zeiterfassung/timesheet/",
            true
          );
          this.getZeitKonto(); */
        }
      );

      /*Api.delete("zeiterfassung/timesheet/", { data: id }
      (response) => {
        this.schliesseLoeschenModal();

        this.listData = this.listData.filter(
          (v) => !response.data.success.includes(v.id)
        );
      }); */
    },

    schliesseLoeschenModal() {
      $("#timesheet-loeschen-modal").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#timesheet-loeschen-modal").modal("show");
    },

    setMitarbeiter(person) {
      /*      console.log("setMitarbeiter läuft");
      console.log("person.id: ", person.id);
      console.log("filters.mitarbeiter?.id: ", this.filters.mitarbeiter?.id); */
      if (!this.filters.mitarbeiter) {
        this.$set(this.filters, "mitarbeiter", person);
        this.getZeitKonto();
      } else if (person.id !== this.filters.mitarbeiter?.id) {
        this.$set(this.filters, "mitarbeiter", person);
        this.getZeitKonto();
        this.filterSearch();
      }
    },

    openTimeaccount() {
      this.$router.push({
        name: "timeaccount",
      });
    },
  },
};
</script>

<style></style>
