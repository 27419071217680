<template>
  <div class="col-xl-12 pr-4">
    <container-headline
      :headline="$t('global.receiversender')"
      :col="3"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <input
                    :readonly="!editable"
                    v-model.trim="mail.bezeichnung"
                    v-bind:class="{ 'border-danger': !passed }"
                    class="form-control"
                    placeholder=" "
                  />
                  <label
                    v-bind:class="{ 'text-danger': !passed }"
                  >
                    
                    {{ $t("global.designation") }}
                  </label>
                </ValidationProvider>
              </div>
            </div>
            <!--<div class="col-xl-2">
              <div class="form-group">
                <input
                  v-model.trim="mail.absendername"
                  class="form-control"
                  placeholder=" "
                />
                <label>Absendername</label>
              </div>
            </div>-->

            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <v-select
                    v-model="mail.mailvorlagentyp"
                    label="bezeichnung"
                    v-bind:class="{ 'border-danger': !passed }"
                    :options="mailvorlagetypen"
                    :disabled="!editable"
                    :placeholder="$t('global.type')"
                  >
                    <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                  </v-select>
                  <label
                    v-bind:class="{ 'text-danger': !passed }"
                  >
                    {{ $t("global.type") }}
                  </label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <v-select
                  :disabled="!editable"
                  v-model="mail.wartelistemailvorlage"
                  :getOptionLabel="(vorlage) => vorlage.bezeichnung"
                  :options="mailvorlagen"
                  :clearable="true"
                  :placeholder="$t('global.waitinglistmailtemplate')"
                  :reduce="(vorlage) => vorlage.id"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.waitinglistmailtemplate") }}</label>
              </div>
            </div>
            <div class="col-xl-2">
              <div class="form-group">
                <ValidationProvider
                  rules="required"
                  immediate
                  v-slot="{ passed }"
                >
                  <v-select
                    :disabled="!editable"
                    v-model="mail.defaultsprache"
                    v-bind:class="{ 'border-danger': !passed }"
                    label="bezeichnung"
                    :options="langs"
                    :placeholder="$t('global.defaultlanguage')"
                    :reduce="(lang) => lang.id"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label v-bind:class="{ 'text-danger': !passed }">{{
                    $t("global.defaultlanguage")
                  }}</label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-xl-1">
              <div class="form-group">
                <button 
                  v-if="berechtigungen.m_korrespondenz.update"
                  class="btn btn-primary" 
                  @click="openTranslationModal"
                >
                  <span>{{ $t("global.translate") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <translation-modal
      ref="TranslationModal"
      id="translation-modal"
      :mail="mail"
      :langs="langs"
      @translate="translate"
    >
    </translation-modal>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import TranslationModal from "@/components/Kommunikation/TranslationModal";

import store from "@/store";

//import page from "@/mixins/Page";

import { LADE_AUSWAHLWERTE_MAIL } from "@/store/korrespondenz/actions.type";

export default {
  name: "MailVorlageStammdaten",
  components: {
    ContainerHeadline,
    TranslationModal,
  },
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    navbarTitel: {
      type: String,
      required: false,
    },
    langs: {
      type: Array,
      required: false,
    },
    mailvorlagen: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    mailvorlagetypen: {
      get() {
        return this.$store.state.korrespondenz.mailvorlagetypen;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.mailvorlagetypen.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_AUSWAHLWERTE_MAIL}`);
  },

  methods: {
    openTranslationModal() {
      this.$bus.$emit("open-modal", "translation-modal");
    },
    translate(sourceLang, targetLangs) {
      this.$emit("translate", sourceLang, targetLangs);
    },
  },
};
</script>

<style></style>
