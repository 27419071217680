import {
  SET_TIMESHEETSTATI,
  SET_PROJEKTE,
  SET_KUNDEN,
  SET_INTERNECODES,
  SET_PROJEKTSTATI,
} from "./mutations.type";

import { LADE_AUSWAHLWERTE_ZEITERFASSUNG } from "./actions.type";

import Api from "../../Api";

export default {
  namespaced: true,
  state: {
    timesheetstati: [],
    projekte: [],
    kunden: [],
    internecodes: [],
    projektstati: [],
  },
  mutations: {
    [SET_TIMESHEETSTATI](state, val) {
      state.timesheetstati = val;
    },
    [SET_PROJEKTE](state, val) {
      state.projekte = val;
    },
    [SET_KUNDEN](state, val) {
      state.kunden = val;
    },
    [SET_INTERNECODES](state, val) {
      state.internecodes = val;
    },
    [SET_PROJEKTSTATI](state, val) {
      state.projektstati = val;
    },
  },
  actions: {
    async [LADE_AUSWAHLWERTE_ZEITERFASSUNG](context) {
      let response = await Api.get("zeiterfassung/auswahlwerte/");
      context.commit(SET_TIMESHEETSTATI, response.data.timesheetstati);
      context.commit(SET_PROJEKTE, response.data.projekte);
      context.commit(SET_KUNDEN, response.data.Kunden);
      context.commit(SET_INTERNECODES, response.data.internecodes);
      context.commit(SET_PROJEKTSTATI, response.data.projektstati);
    },
  },
};
