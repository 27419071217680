<template>
    <div :id="id" class="modal fade" style="z-index: 9999">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <!--<modal-close-button @confirmed="closeModal" /> -->
            <h4 class="modal-title">Bildungsgang suche</h4>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-xl-4">
                <div class="form-group">
                  <input
                    v-model.trim="kuerzel"
                    class="form-control"
                    placeholder=" "
                    @keyup.enter="resetLoading()"
                  />
                  <label>{{ $t("global.shortdesignation") }}</label>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="form-group">
                  <input
                    v-model.trim="bezeichnung"
                    class="form-control"
                    placeholder=" "
                    @keyup.enter="resetLoading()"
                  />
                  <label>{{ $t("global.designation") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 block modal-liste br-t-l-0">
                <div style="height: 300px !important">
                  <b-table
                    responsive
                    sticky-header
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"
                    @row-dblclicked="rowDblClick"
                    tbody-tr-class="item"
                    :items="lehrgaenge"
                    :fields="fields"
                    :busy="isBusy"
                    :show-empty="loaded"
                  >
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
  
                    <template #empty>
                      <div class="text-center text-primary my-2">
                        {{ $t("global.nodatafound") }}
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="abbrechen">
              {{ $t("global.cancel") }}
            </button>
  
            <button
              type="button"
              class="btn btn-danger mr-2"
              @click="entfernen"
              v-if="filterLehrgang && filterLehrgang.bezeichnung"
            >
              {{ $t("global.remove") }}
            </button>
  
            <button type="button" class="btn btn-primary" @click="uebernehmen">
              {{ $t("global.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Api from "@/Api";
  import ModalCloseButton from "@/components/global/ModalCloseButton";
  import { BSpinner } from "bootstrap-vue";
  
  export default {
    name: "LehrgangSelectModal",
    components: { ModalCloseButton, BSpinner },
    props: {
      id: {
        type: String,
        required: true,
      },
      remove: {
        type: Boolean,
        default: false,
      },
      filterLehrgang: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        isBusy: false,
        loaded: false,
        anzahlProPage: 99,
        lehrgaenge: [],
        kuerzel: null,
        bezeichnung: null,
        selected: [],
      };
    },
    computed: {
      fields() {
        return [
          {
            key: "kuerzel",
            sortable: true,
            label: this.$t("global.shortdesignation"),
          },
          {
            key: "bezeichnung",
            sortable: true,
            label: this.$t("global.designation"),
          },
          {
            key: "lehrgangstatus.bezeichnung",
            sortable: true,
            label: this.$t("global.status"),
          },
        ];
      },
      queryParams: function () {
        var params = {
          page: this.page,
          count: this.anzahlProPage,
        };
    
        if (this.kuerzel) params.filterKurzbezeichnung = this.kuerzel;
  
        if (this.bezeichnung) params.filterBezeichnung = this.bezeichnung;
  
        return params;
      },
    },
    watch: {},
    mounted() {},
    methods: {
      onRowSelected(items) {
        this.selected = items;
      },
  
      rowDblClick(item) {
        this.selected = [item];
        this.uebernehmen();
      },
  
      resetLoading() {
        this.isBusy = true;
  
        Api.get("lehrgaenge/", {
          params: this.queryParams,
        })
          .then((response) => {
            //response.data.shift();
            this.lehrgaenge = response.data;
          })
          .finally(() => {
            this.isBusy = false;
            this.loaded = true;
          });
      },
      uebernehmen() {
        this.$emit("confirmed", this.selected);
        this.closeModal();
        this.clear();
        //$("#" + this.id).modal("hide");
      },
      abbrechen() {
        this.$bus.$emit("sucheModalClose");
        $("#" + this.id).modal("hide");
        this.clear();
      },
      entfernen() {
        this.closeModal();
        this.$emit("confirmed", {
          lehrgangTitel: this.$t("global.notlinked"),
        });
        this.clear();
      },
      closeModal() {
        this.$bus.$emit("sucheModalClose");
        this.shown = false;
        $("#" + this.id).modal("hide");
      },
      clear() {
        this.bezeichnung = null;
        this.kuerzel = null;
      },
    },
  };
  </script>
  
  <style></style>
  