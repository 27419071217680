<template>
  <div id="termin-raum-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4>{{ $t("global.changeroom") }}</h4>
        </div>
        <div class="modal-body">
          <div class="row">
           <div class="col-xl-12">
              <div class="form-group">
               <!-- v-select für Education -->
                 <v-select
                  v-model="selectedGebaeude"
                  label="bezeichnung"
                  :options="gebaeude"
                  v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                  :reduce="(r) => r.id"
                  :placeholder="$t('global.building')"

                  >
                    <span slot="no-options">{{
                       $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.building") }}</label>
                </div>
              </div>
              </div>
            <div class="row">  
                    <div class="col-xl-6">
                      <div class="form-group">
                        <!-- v-select für Education -->
                        <v-select
                          v-model="selectedRaum"
                          label="bezeichnung"
                          :options="filteredRaeume"
                          v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                          :reduce="(r) => r.id"
                          :placeholder="$t('global.room')"
                          multiple
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.room") }}</label>
                      </div>
                    </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button class="btn btn-primary" @click="raeumeSpeichern">
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import store from "@/store";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "TerminRaumAendernModal",
  components: {
    ModalCloseButton,
  },
  metaInfo() {},
  mixins: [],
  props: {
    termine: {
      type: Array,
      required: true,
    },
  },
  store,
  data() {
    return {
      selectedGebaeude: null,
      selectedRaum: [],
      shown: false,
      raumliste: [],
    };
  },
  computed: {
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
      },
        gebaeude: {
          get() {
        return this.$store.state.ressourcen.gebaeude;
      },
    },
        filteredRaeume: function () {
      // Filter raeume based on the selectedGebaeude
      if (this.selectedGebaeude) {
        return this.raeume.filter((raum) => raum.gebaeude?.id === this.selectedGebaeude);
      }
      return this.raeume; // If no gebaeude is selected, show all raeume
    },
  },
  watch: {
            selectedGebaeude: function () {
      // Reset selectedRaum when a new gebaeude is selected
      if(this.selectedRaum){
        let filteredRaumByGebaeudeID = this.raeume.filter((raum) => raum.id === this.selectedRaum[0])[0]
        if(filteredRaumByGebaeudeID?.gebaeude?.id != this.selectedGebaeude || this.selectedGebaeude == null){
          this.selectedRaum = null;
        }
      }      
    },
      "termin.raumliste": function () {
      // Fill selectedRaum with the ids from this.termin.raumliste
      this.selectedRaum = this.termin.raumliste.map((raum) => raum.id);
    },
    selectedRaum: function () {
      // Set gebaeude based on the selectedRaum
      if(!this.selectedGebaeude && this.selectedRaum && this.raeume.length){
        let filteredGebaeude = this.raeume.filter((raum) => raum.id === this.selectedRaum[0]);
        this.selectedGebaeude = filteredGebaeude[0]?.gebaeude?.id;
      }
    },},
  mounted: function () {
    if (this.raeume.length == 0)
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);

    this.$bus.$on("openTerminRaumModal", () => {
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    raeumeSpeichern() {
      let json = {
        entityname: "Termin",
        fields: ["Raumliste", "Gebaeude"],
        values: [this.selectedRaum ? this.selectedRaum : [], this.selectedGebaeude],
        ids: this.termine,
      };

      if (json.ids.length > 0) {
        Api.put("tools/massupdate/", json)
          .then(() => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.roomssuccessfullychange"),
            });

            this.$emit("updated");
          })
          .catch((e) => {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.roomsnotchanged"),
            });
          });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noappointmentsselected"),
        });
      }
      this.closeModal();
    },

    openModal() {
      this.raumliste = [];
      $("#termin-raum-modal").modal({
        backdrop: "static",
        keyboard: false,
      });

      this.shown = false;
    },

    closeModal() {
      $("#termin-raum-modal").modal("hide");
    },
  },
};
</script>

<style></style>
