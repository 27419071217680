<template>
  <div class="list">
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              item-text="key"
              :showIcons="false"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="modul"
                        label="bezeichnung"
                        :options="modulList"
                        :placeholder="$t('global.module')"
                        :clearable="false"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.module") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="bereich"
                        :getOptionLabel="(bereich) => bereich.bezeichnung"
                        :options="bereichList"
                        :placeholder="$t('global.Section')"
                        :clearable="false"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.Section") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterValueDE"
                        @input="bezeichnungFilter(filterValueDE, 'de')"
                        class="form-control input"
                        type="text"
                        field="bezeichnung"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }} - DE</label>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterValueEN"
                        @input="bezeichnungFilter(filterValueEN, 'en')"
                        class="form-control input"
                        type="text"
                        field="bezeichnung"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }} - EN</label>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterValueFR"
                        @input="bezeichnungFilter(filterValueFR, 'fr')"
                        class="form-control input"
                        type="text"
                        field="bezeichnung"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }} - FR</label>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filterValueIT"
                        @input="bezeichnungFilter(filterValueIT, 'it')"
                        class="form-control input"
                        type="text"
                        field="bezeichnung"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }} - IT</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.settings') + ': ' + this.kachel"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <button
                      v-if="berechtigungen.g_einstellungen.create"
                      class="btn btn-success"
                      @click="oeffneEinstellungenModal"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button 
                      v-if="berechtigungen.g_einstellungen.delete"
                      class="btn btn-danger" 
                      @click="oeffneLoeschenModal"
                    >
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="list"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :busy="isBusy"
                    show-empty
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="oeffneEinstellungenModal"
                  >
                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>

                    <template #empty>
                      <div
                        v-if="ladeFehler"
                        class="text-center text-danger my-2"
                      >
                        <strong>{{ $t("global.errorwhileloading") }}</strong>
                      </div>
                      <div
                        v-if="!ladeFehler"
                        class="text-center text-primary my-2"
                      >
                        <strong>{{ $t("global.nodataavailable") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="isBusy"></loading-overlay>
    <einstellungen-modal
      id="einstellungen-modal"
      :selectedItem="selectedItem"
      :sourceLanguageList="this.sourceLanguageList"
      :bereich="this.bereich"
      @confirmed="getData"
      path="settings-page"
    />
    <loeschen-modal
      id="einstellungen-loeschen-modal"
      @confirmed="einstellungLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import EinstellungenModal from "@/components/Modals/EinstellungenModal";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import Auswahlwerte from "@/Auswahlwerte";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import { BSpinner } from "bootstrap-vue";
import store from "@/store";

export default {
  name: "EinstellungenAuswahlwerte",
  components: {
    HeadMenu,
    HeadMenuFilter,
    BSpinner,
    Navbar,
    EinstellungenModal,
    LoeschenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.businesspartner");
      },
    };
  },
  mixins: [page, dashboard],
  props: {},
  store,
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      filterBezeichnung: null,
      filterValueDE: null,
      filterValueEN: null,
      filterValueFR: null,
      filterValueIT: null,
      tileId: null,

      selected: [],
      selectedIds: [],
      selectedItem: {},

      list: [],
      listData: [],
      bereich: {},
      bereichList: [],
      modul: [],
      modulList: [],
      kachel: "",
      sourceLanguageList: [],

      sortBy: "bezeichnung",
      sortDesc: false,
      editable: true
    };
  },

  computed: {
    fields() {
      return [
        {
          key: "selected",
          label: "",
        },
        {
          key: "de.text",
          sortable: true,
          label: this.$t("global.designation") + " - DE",
        },
        {
          key: "en.text",
          sortable: true,
          label: this.$t("global.designation") + " - EN",
        },
        {
          key: "fr.text",
          sortable: true,
          label: this.$t("global.designation") + " - FR",
        },
        {
          key: "it.text",
          sortable: true,
          label: this.$t("global.designation") + " - IT",
        },
      ];
    },
  },

  watch: {
    modul(val) {
      if (val) {
        this.bereichList = val.table_selection_value_list;
        this.bereich = val.table_selection_value_list[0];
      }
    },

    bereich(val) {
      if (this.listData.length > 0) {
        this.list = this.listData.find(
          (obj) => obj.id === val.id
        ).selection_values;
      }
    },

    tileId() {
      this.getData();
      this.getSelectionValues();
    },
  },

  created() {
    this.isBusy = true;
    this.tileId = this.$route.params.tileId;
  },

  mounted() {},

  methods: {
    bezeichnungFilter(val, lang) {
      if (val != "") {
        this.list = this.listData
          .find((obj) => obj.id === this.bereich.id)
          .selection_values.filter((el) =>
            el[lang].text.toLowerCase().includes(val.toLowerCase())
          );
      } else {
        this.list = this.listData.find(
          (obj) => obj.id === this.bereich.id
        ).selection_values;
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal", "einstellungen-loeschen-modal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.norecordselected"),
        });
      }
    },

    oeffneEinstellungenModal(selectedRow) {
      this.selectedItem = Object.assign({}, selectedRow);
      this.$bus.$emit(
        "openEinstellungenModal",
        "einstellungen-modal",
        selectedRow
      );
    },

    einstellungLoeschen() {
      this.isBusy = true;
      Auswahlwerte.delete("settings-page/" + this.bereich.id, {
        params: {
          ids: this.selectedIds.join(","),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.getData();
            this.ladeFehler = false;
            this.isBusy = false;

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.selectionvaluesdeleted"),
            });
          }
        })
        .catch((e) => {
          this.ladeFehler = true;
          this.isBusy = false;
          this.$notify(apiErrorToAlert(e));
        });
    },

    getSelectionValues() {
      Auswahlwerte.get("selection-values", {
        params: {
          id: this.tileId,
        },
      })
        .then((response) => {
          this.kachel = response.data.tileList[0].bezeichnung;
          this.modul = response.data.modulList[0];
          this.modulList = response.data.modulList;
          this.sourceLanguageList = response.data.languageList;
        })
        .catch((e) => {
          this.ladeFehler = true;
          this.isBusy = false;
          this.$notify(apiErrorToAlert(e));
        });
    },

    getData() {
      Auswahlwerte.get("settings", {
        params: {
          id: this.tileId,
          size: 1000,
        },
      })
        .then((response) => {
          this.listData = response.data.content;
          this.list =
            this.bereich == null
              ? response.data.content[0].selection_values
              : response.data.content.find((obj) => obj.id === this.bereich.id)
                  .selection_values;
          this.isBusy = false;
        })
        .catch((e) => {
          this.ladeFehler = true;
          this.isBusy = false;
          //this.$notify(apiErrorToAlert(e));
        });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = true;
        });
        this.selectedObjects = this.$refs.selectableTable.items;
        this.selectedIds = this.$refs.selectableTable.items.map(
          (item) => item.id
        );

        this.anzahlDaten = this.selectedIds.length;
      } else {
        this.$refs.selectableTable.items.forEach((a) => {
          a.selected = false;
        });
        this.selectedIds = [];
        this.selectedObjects = [];
        this.anzahlDaten = 0;
      }
    },

    onRowSelected() {
      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 100) return;
      }

      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedIds = this.selectedObjects.map((item) => item.id);
      this.anzahlDaten = this.selectedIds.length;
    },
  },
};
</script>
