<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.selectionconfiguration") }}</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12 pr-4">
            <!-- START Stammdaten -->
            <container-headline
              :headline="$t('global.masterdata')"
              :col="6"
            ></container-headline>

            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="selectiontype"
                          label="designation"
                          :options="selectiontypes"
                          :placeholder="$t('global.type')"
                          required
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label
                          v-bind:class="{ 'text-danger': !typeValidated }"
                          >{{ $t("global.type") }}</label
                        >
                      </div>
                    </div>
                    <!--    <div class="col-xl-3">
                      <div class="form-group">
                        <input
                          class="form-control"
                          placeholder=" "
                          v-model.number="sort"
                        />
                        <label>{{ $t("global.sorting") }}</label>
                      </div>
                    </div> -->
                  </div>
                  <!--  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model="designation"
                          class="form-control"
                          placeholder=" "
                          rows="2"
                        ></textarea>
                        <label
                          v-bind:class="{ 'text-danger': !desValidated }"
                          >{{ $t("global.designation") }}</label
                        >
                      </div>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model="description"
                          class="form-control"
                          placeholder=" "
                          rows="4"
                        ></textarea>
                        <label>{{ $t("global.comment") }}</label>
                      </div>
                    </div>
                  </div>
                  <!--Range-->
                  <!--  <div v-if="questiontype.id == '1864FF4F374'" class="row">
                    
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model="rangestart"
                          class="form-control"
                          placeholder=""
                        />
                        <label
                          >{{ $t("skjv.start") }}
                          </label
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model="rangeend"
                          class="form-control"
                          placeholder=""
                        />
                        <label
                          >{{ $t("skjv.end") }}
                          </label
                        >
                      </div>
                    </div>
                  </div> -->
                  <!--         <div
                    v-if="selectiontype.designation == 'Multiplechoice'"
                    class="row"
                  >
                   
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="antwortcount"
                          class="form-control"
                          placeholder=""
                        />
                        <label>{{ $t("skjv.answercount") }} </label>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- ENDE Stammdaten -->

            <div
              v-if="
                selectiontype.designation == 'Singlechoice' ||
                selectiontype.designation == 'Multiplechoice'
              "
            >
              <!--Multiplechoice || Singlechoice-->
              <container-headline
                :headline="$t('skjv.answers')"
                :col="6"
              ></container-headline>

              <div class="row mb-3">
                <div class="col-xl-12 block br-t-l-0">
                  <div
                    class="eingabe-panel-kopf p-3 abteilungPanel"
                    ref="abteilungPanel"
                    :style="
                      answers.length > 3
                        ? abteilungPanelStyle
                        : 'height: 246.5px;'
                    "
                  >
                    <div class="row">
                      <div class="col-xl-12">
                        <button
                          class="btn btn-primary btn-sm"
                          @click="answerHinzufuegen()"
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-plus"
                            class="mr-1"
                          />
                          {{ $t("global.new") }}
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <b-table
                            ref="answerstable"
                            tbody-tr-class="item"
                            show-empty
                            :items="answers"
                            :fields="fields"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            sort-icon-left
                            borderless
                            thead-class=""
                          >
                            <!-- Column headers -->
                            <template #thead>
                              <tr v-if="showHeader">
                                <th v-for="field in fields" :key="field.key">
                                  <!-- Display label unless the key is 'delete' -->
                                  {{
                                    field.key !== "delete" ? field.label : ""
                                  }}
                                </th>
                              </tr>
                            </template>
                            <template
                              #cell(designation)="row"
                              class="d-flex align-items-center"
                            >
                              <input
                                v-model="row.item.designation"
                                class="form-control"
                                :placeholder="$t('skjv.answer')"
                              />
                            </template>

                            <template
                              #cell(default)="row"
                              class="d-flex align-items-center"
                            >
                              <input
                                type="checkbox"
                                v-model="row.item.is_default"
                                class="form-control"
                                :placeholder="$t('skjv.answer')"
                                @change="checkMaxDefaults(row.item)"
                              />
                            </template>
                            <template
                              #cell(sort)="row"
                              class="d-flex align-items-center"
                            >
                              <input
                                type="number"
                                v-model.number="row.item.sort"
                                class="form-control"
                                :placeholder="$t('skjv.answer')"
                              />
                            </template>

                            <template
                              #cell(delete)="row"
                              class="d-flex align-items-center"
                            >
                              <button
                                class="btn btn-danger btn-sm"
                                @click="answerLoeschen(row.item)"
                                style="height: 33px"
                              >
                                <font-awesome-icon
                                  icon="fa-duotone fa-trash"
                                  class="mr-1 pl-1"
                                />
                              </button>
                            </template>

                            <template #empty>
                              <div class="text-center text-primary my-2">
                                <strong>{{
                                  $t("global.nodataavailable")
                                }}</strong>
                              </div>
                            </template>

                            <template #table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"
                                :key="field.key"
                                :style="{
                                  width:
                                    field.key === 'designation' ? '70%' : '10%',
                                }"
                              />
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button
            type="button"
            :disabled="!typeValidated || !answersValid"
            class="btn btn-primary"
            @click="speichern"
            v-tooltip
            :title="
              !answersValid ? $t('global.pleasefillinalldesignations') : ''
            "
          >
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>

<script>
import QuestionaireApi from "@/QuestionaireApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";

import page from "@/mixins/Page";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import LoadingOverlay from "@/components/global/LoadingOverlay";

export default {
  name: "SelectionConfigModal",
  components: {
    ContainerHeadline,
    ModalCloseButton,
    LoadingOverlay,
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      loading: false,
      shown: false,
      antwortcount: 0,
      description: "",
      selectiontype: "",
      selectiontypes: [
        { designation: "Singlechoice" },
        { designation: "Multiplechoice" },
      ],
      answers: [],
      answerstodelete: [],
      abteilungPanelStyle: {
        height: "246.5px",
        "overflow-y": "scroll",
      },
      typeValidated: false,
      showHeader: false,
      feld: {},
    };
  },
  watch: {
    selectiontype(val) {
      if (val) {
        this.typeValidated = true;
      } else {
        this.typeValidated = false;
      }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "designation",
          sortable: true,
          label: this.$t("global.designation"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "default",
          sortable: false,
          label: this.$t("global.default"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "sort",
          sortable: false,
          label: this.$t("global.sorting"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "delete",
          sortable: false,
          label: "",
          tdClass: "p-0 pb-2 pl-3 d-flex align-items-center",
        },
      ];
    },
    answersValid() {
      if (
        this.answers.length === 0 || this.antwortcount
          ? this.answers.length < this.antwortcount
          : false
      ) {
        return false;
      } else {
        let hasAllDesignations = true;
        this.answers.forEach((answer) => {
          if (!answer.designation) {
            hasAllDesignations = false;
            return;
          }
        });
        return hasAllDesignations;
      }
    },
  },
  mounted() {
    this.$bus.$on("openSelectionConfigModal", (id, feld) => {
      this.feld = feld;
      this.description = "";
      this.answers = [];
      this.antwortcount = 0;
      this.selectiontype = "";
      this.selectionID = "";
      this.validated = false;
      this.answerstodelete = [];
      if (!this.shown) this.openModal(id);
    });

    this.$bus.$on("editSelectionConfigModal", (id, feld) => {
      if (!this.shown) this.openModal(id);
      if (feld.selectiongroup) {
        this.description = feld.selectiongroup.description;
        this.answers = feld.selectiongroup.answers.sort(
          (a, b) => a.sort - b.sort
        );
        this.antwortcount = feld.selectiongroup.antwort_minimum;
        this.selectiontype = feld.selectiongroup.multiplechoice
          ? this.selectiontypes.find(
              (type) => type.designation === "Multiplechoice"
            )
          : this.selectiontypes.find(
              (type) => type.designation === "Singlechoice"
            );
        this.selectionID = feld.selectiongroup.id;
        this.answerstodelete = [];
      }
    });
  },
  methods: {
    openModal(id) {
      this.shown = true;
      const thisId = id ? id : this.id;
      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    answerLoeschen(answer) {
      const indexToRemove = this.answers.findIndex(
        (item) => item.id === answer.id
      );

      // Check if the item with the given "id" exists in the array
      if (indexToRemove !== -1) {
        // Remove the item from the array
        this.answerstodelete.push(this.answers[indexToRemove]);
        this.answers.splice(indexToRemove, 1);
        for (let i = indexToRemove; i < this.answers.length; i++) {
          this.answers[i].sort -= 1;
        }
      }
      if (this.answers.length === 0) {
        this.showHeader = false;
        // this.$refs.answerstable.refresh();
      }
    },
    answerHinzufuegen() {
      this.answers.push({
        id: new Date().getTime(),
        designation: "",
        is_default: false,
        sort: this.answers.length + 1,
      });

      // Focus on the last added answer's 'designation' input field after Vue updates the DOM
      this.$nextTick(() => {
        if (!this.showHeader) {
          this.showHeader = true; // Show the header after adding the first answer
          //   this.$refs.answerstable.refresh();
        }

        const table = this.$refs.answerstable;
        if (table && table.$el) {
          const lastRow = table.$el.querySelector("tbody tr:last-child input");
          if (lastRow) {
            lastRow.focus(); // Focus on the 'designation' input of the last row
          }
        }
      });
    },

    speichern() {
      var json = {
        id: this.selectionID,
        description: this.description,
        answers: this.answers,
        antwortcount: this.antwortcount,
        selectiontype: this.selectiontype,
        answerstodelete: this.answerstodelete,
      };

      //   if (this.selectionID) {
      this.$emit("confirmed", this.feld, json);
      this.closeModal();

      /*  Api.post("selectionfield/", json)
          .then((response) => {
            //this.initializeQuestionaire(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.questionsavedsuccessfully"),
            });
            
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.closeModal()
            this.$emit("confirmed");
            
          }); */
      /* }  else {
        console.log("PUT", json);
          Api.put("selectionfield/", json)
          .then((response) => {
            //this.initializeQuestionaire(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.questionsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.closeModal()
            this.$emit("confirmed");
          }); 
      }*/
    },
    checkMaxDefaults(answer) {
      if (!answer.is_default) return;
      if (this.selectiontype.designation === "Singlechoice") {
        this.answers.map((thisanswer) => {
          if (thisanswer.id !== answer.id) {
            thisanswer.is_default = false;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
/* Firefox */
*.abteilungPanel {
  scrollbar-width: thin;
  scrollbar-color: #deedf6 #f5fbff;
}

/* Chrome, Edge, und Safari */
*.abteilungPanel::-webkit-scrollbar {
  width: 12px;
}

*.abteilungPanel::-webkit-scrollbar-track {
  background: #f5fbff;
  border-radius: 0 0 6px 0;
}

*.abteilungPanel::-webkit-scrollbar-thumb {
  background-color: #deedf6;
  border-radius: 20px;
  border: 3px solid #f5fbff;
}
</style>
