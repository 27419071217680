<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <!--Start Linke Seite/Filter-->
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['name']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.surname") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['vorname']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.firstname") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.status"
                        :options="aktivitaetsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.status')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row" v-show="!rolleProp">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-if="rollen"
                        v-model="filters.rolle"
                        :reduce="(r) => r.id"
                        :options="rollen"
                        label="bezeichnung"
                        :placeholder="$t('global.type')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.notypefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.type") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['funktion']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.function") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['viaFirma']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.company") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['korrAddress']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.address") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['kanton']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.canton") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.korrespondenzsprache"
                        :options="sprachen"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.language')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nolanguagefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.language") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.konkordat"
                        :reduce="(i) => i.id"
                        :options="konkordate"
                        label="bezeichnung"
                        :placeholder="$t('global.concordat')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nointerestfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.concordat") }}</label>
                    </div>
                  </div>
                </div>
                <div
                  v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                  class="row"
                >
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.funktionsbereich"
                        :reduce="(fb) => fb.id"
                        :options="funktionsbereiche"
                        label="bezeichnung"
                        :placeholder="$t('global.functionalarea')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nofunctionalareafound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.functionalarea") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.interesse"
                        :reduce="(i) => i.id"
                        :options="interessen"
                        label="bezeichnung"
                        :placeholder="$t('global.interests')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nointerestfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.interests") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.versand"
                        multiple
                        :reduce="(vl) => vl.id"
                        :options="versandlisten"
                        label="bezeichnung"
                        :placeholder="$t('global.dispatch')"
                      >
                        <span slot="no-options">{{
                          $t("global.nomailinglistfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.mailinglist") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button
                  @searchWithFilter="filterSearch"
                  @resetFilter="clearFilter"
                />
                <!--Oder Statement switch-->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <button
                        class="btn btn-secondary"
                        @click="toggleOrParameter"
                      >
                        {{
                          filters.orStatement
                            ? $t("global.orfilteractive")
                            : $t("global.orfilterinactive")
                        }}
                      </button>
                    </div>
                  </div>
                </div>
                <!--Ende Oder Statement switch-->
              </div>
            </div>
          </div>
        </div>
        <!--Ende Linke Seite/Filter-->

        <!--Start RechteSeite-->
        <!--Start Headline/Buttonbar-->
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="
                rolleProp && rolleObj
                  ? rolleObj.bezeichnung
                  : $t('global.personlist')
              "
              :date="'Mittwoch 29.Juli'"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between px-4 flex-wrap">
                  <router-link
                    v-if="
                      rolleProp && berechtigungen.m_geschaeftspartner.create
                    "
                    class="btn btn-primary mr-2 mb-1"
                    :to="{
                      name: 'NeuerGeschäftspartnerMitRolle',
                      params: { rolleProp: rolleProp },
                    }"
                  >
                    <font-awesome-icon icon="fa-regular fa-plus" />
                    {{
                      rolleProp && rolleObj
                        ? rolleObj.bezeichnung
                        : $t("global.businesspartner")
                    }}
                    {{ $t("global.add") }}
                  </router-link>
                  <router-link
                    v-if="
                      !rolleProp && berechtigungen.m_geschaeftspartner.create
                    "
                    class="btn btn-success mr-2 mb-1"
                    :to="{
                      name: 'geschaeftspartner',
                      params: { anzeige: 0 },
                    }"
                  >
                    <font-awesome-icon icon="fa-regular fa-plus" />
                    {{ $t("global.new") }}
                  </router-link>

                  <span>
                    <b-dropdown variant="primary mr-2 mb-1">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-print"
                          class="mr-2"
                        />
                        {{ $t("global.print") }}
                      </template>
                      <b-dropdown-item @click="druckeTischkarten">
                        {{ $t("global.placecards") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="druckeAdressEtiketten">
                        {{ $t("global.addresslabels") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="druckeNamensschilder">
                        {{ $t("global.nametags") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                  <div style="display: inline-flex" class="mr-2 mb-1">
                    <massenmutation-button
                      :mutatableFields="this.mutatableFields"
                      :mutatableFieldsMultiple="this.mutatableFieldsMultiple"
                      :mutatableValues="[
                        [this.aktivitaetsstati],
                        [this.rollen],
                      ]"
                      :mutatableMultipleValues="[[this.rollen]]"
                      :mutatableLabels="this.mutatableLabels"
                      :mutatableLabelsMultiple="this.mutatableLabelsMultiple"
                      :mutationPfad="this.mutationPfad"
                      :selectedIds="this.selectedIds"
                      :margins="'m-0'"
                      @mutated="refresh"
                    />
                  </div>

                  <b-dropdown
                    variant="primary mr-2 mb-1"
                    v-if="berechtigungen.m_korrespondenz.update"
                  >
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-regular fa-envelope"
                        class="mr-2"
                      />
                      {{ $t("global.createmassmail") }}
                    </template>
                    <b-dropdown-item @click="massenmailErstellen(true)">
                      {{ $t("global.selected") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="massenmailErstellen(false)">
                      {{ $t("global.alldisplayed") }}
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-dropdown
                    variant="primary mr-2 mb-1"
                    v-if="berechtigungen.m_korrespondenz.update"
                  >
                    <template slot="button-content">
                      <font-awesome-icon
                        icon="fa-regular fa-envelope"
                        class="mr-2"
                      />
                      {{ $t("global.createserialletter") }}
                    </template>
                    <b-dropdown-item
                      @click="massenkorrespondenzErstellen(true)"
                    >
                      {{ $t("global.selected") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="massenkorrespondenzErstellen(false)"
                    >
                      {{ $t("global.alldisplayed") }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <button
                    class="btn btn-primary mr-2 mb-1"
                    v-if="berechtigungen.m_geschaeftspartner.create"
                    @click="oeffneDublettenCheckModal"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-user-group"
                      class="mr-2"
                    />{{ $t("global.duplicatecheck") }}
                  </button>
                  <button
                    class="btn btn-primary mr-2 mb-1"
                    v-if="berechtigungen.m_geschaeftspartner.create"
                    v-tooltip.hover
                    :title="$t('global.pleaseselectmaxfiveentries')"
                    :disabled="
                      this.selectedIds.length < 2 || this.selectedIds.length > 5
                    "
                    @click="oeffneDublettenZusammenfuehrenModal"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-arrows-minimize"
                      class="mr-2"
                    />{{ $t("global.mergeselecteditems") }}
                  </button>

                  <!-- <button
                    class="btn btn-primary mr-2 mb-1"
                    v-if="berechtigungen.m_geschaeftspartner.create"
                    v-tooltip.hover
                    :title="$t('global.pleaseselectentries')"
                    :disabled="this.selectedIds.length === 0"
                    @click="oeffnePortalZugangErstellenModal"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-globe"
                      class="mr-2"
                    />{{ $t("global.createportalaccess") }}
                  </button> -->

                  <button
                    class="btn btn-danger ml-auto"
                    v-if="berechtigungen.m_geschaeftspartner.delete"
                    @click="oeffneLoeschenModal"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />{{ $t("global.delete") }}
                  </button>
                </div>
              </div>
            </div>
            <!--Ende Headline/Buttonbar-->

            <!--Start Liste-->
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <div class="form-group p-0 m-0">
                          <input
                            class="form-control"
                            v-if="
                              field.key != 'selected' &&
                              field.searchable != false
                            "
                            size="sm"
                            type="text"
                            v-model="filters[field.key]"
                            :placeholder="field.label"
                          />
                        </div>
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">
                      {{ $t("global.loadmorebusinesspartners") }}
                    </div>
                    <div slot="no-more">
                      {{ $t("global.nofurtherbusinesspartners") }}
                    </div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherbusinesspartnersfound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
            <!--Ende Liste-->
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="geschaeftspartner-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="geschaeftspartnerLoeschen"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>

    <messagebox-count
      :headerText="$t('global.personsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <!-- <portal-zugang-erstellen-messagebox
      :id="'portal-zugang-erstellen-msgbox'"
      :ok="true"
      :cancel="true"
      :okText="portalZugangOkText"
      :cancelText="$t('global.cancel')"
      @ok="createPortalZugang"
    />

    <portal-zugang-success-messagebox
      :id="'portal-zugang-success-msgbox'"
      :ok="true"
      :cancel="false"
    /> -->

    <dubletten-check-modal id="dubletten-check-modal" />
    <dubletten-zusammenfuehren-modal
      id="dubletten-zusammenfuehren-modal"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
//import PortalApi from "@/PortalApi";

import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

//import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";
//import PortalZugangErstellenMessagebox from "@/components/Modals/MessageboxEnhanced.vue";
//import PortalZugangSuccessMessagebox from "@/components/Modals/MessageboxEnhanced.vue";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

import store from "@/store";
import server from "@/server";

import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";
import { LADE_VERSANDLISTEN } from "@/store/korrespondenz/actions.type";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import { addresseToSingleLineString } from "@/models/Adresse";

import DublettenCheckModal from "@/components/crm/DublettenCheckModal.vue";
import DublettenZusammenfuehrenModal from "@/components/crm/DublettenZusammenfuehrenModal.vue";
import { LADE_AUSWAHLWERTE_EINSTELLUNGEN } from "@/store/einstellungendashboard/actions.type";
import { faCloudSleet } from "@fortawesome/pro-regular-svg-icons";

export default {
  name: "personen-liste",
  components: {
    Navbar,
    LoeschenModal,
    HeadMenu,
    HeadMenuFilter,
    //MessageboxEnhanced,
    MassenmutationButton,
    FilterSearchResetButton,
    DublettenCheckModal,
    DublettenZusammenfuehrenModal,
    //PortalZugangErstellenMessagebox,
    //PortalZugangSuccessMessagebox,
  },
  metaInfo() {
    const rolleObj = this.rolleObj;

    return {
      titleTemplate: () => {
        return rolleObj
          ? "BX: " + rolleObj.bezeichnung
          : "BX: " + this.$t("global.person");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {
    rolleProp: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      name: "Geschaeftspartnerliste",
      infiniteId: +new Date(),
      pdfPath: "",
      selected: [],
      selectedIds: [],
      msgboxOkText: this.$t("global.ok"),
      /*portalZugangText: this.$t(
        "global.wouldyouliketosendtheinvitationtoregisteronportal"
      ),
      portalZugangOkText: this.$t("global.createportalaccess"),*/
      mutatableFields: ["Status"],
      mutatableFieldsMultiple: ["Typ"],
      mutatableValues: [[]],
      mutatableMultipleValues: [[]],
      mutatableLabels: ["bezeichnung"],
      mutatableLabelsMultiple: ["bezeichnung"],
      mutationPfad: "geschaeftspartner/",
      firstVisit: true,
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "nummer",
          sortable: true,
          label: "Nr",
          tdClass: "col-number",
        },
        {
          key: "aktivitaetsstatus.bezeichnung",
          sortable: true,
          searchable: false,
          class: "text-center",
          label: this.$t("global.status"),
        },
        { key: "name",
          sortable: true,
          label: this.$t("global.surname") },
        {
          key: "vorname",
          sortable: true,
          label: this.$t("global.firstname"),
        },
        {
          key: "geburtsdatum",
          sortable: true,
          searchable: false,
          label: this.$t("global.dateofbirth"),
        },
        {
          key: "funktion",
          sortable: true,
          label: this.$t("global.function"),
        },
        {
          key: "mailadressen[0].bezeichnung",
          sortable: true,
          label: this.$t("global.email"),
        },
        { key: "telefon_g",
          sortable: true,
          label: this.$t("global.phoneb"), 
          tdClass: "minw-130-px",
        },
        {
          key: "viafirma.name",
          sortable: true,
          searchable: false,
          label: this.$t("global.company"),
        },
        {
          key: "kommunikationsadresse.adresseKomplett",
          sortable: true,
          searchable: false,
          label: this.$t("global.address"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.nummer = this.setQueryParam(this.filters.nummer);
      params.name = this.setQueryParam(this.filters["name"]);
      params.vorname = this.setQueryParam(this.filters["vorname"]);
      params.status = this.setQueryParamList(this.filters.status);
      let filterRollen;
      if (this.rolleProp) filterRollen = this.rolleProp;
      else
        filterRollen = this.filters.rolle ? this.filters.rolle.join(",") : null;

      params.funktion = this.setQueryParam(this.filters["funktion"]);
      params.viaFirma = this.setQueryParam(this.filters["viaFirma"]);
      params.korrAddress = this.setQueryParam(this.filters["korrAddress"]);
      params.kanton = this.setQueryParam(this.filters["kanton"]);
      params.telg = this.setQueryParam(this.filters.telefon_g);
      params.rollen = this.setQueryParam(filterRollen);
      params.email = this.setQueryParam(
        this.filters["mailadressen[0].bezeichnung"]
      );
      
      params.korrespondenzsprache = this.setQueryParamList(this.filters["korrespondenzsprache"]);
      params.interesse = this.setQueryParamList(this.filters.interesse);
      params.konkordat = this.setQueryParamList(this.filters.konkordat);
      params.fbereich = this.setQueryParamList(this.filters.funktionsbereich);
      params.versand = this.setQueryParamList(this.filters.versand);

      params.filterCount = this.setQueryParam(this.filters.count);
      params.orStatement = this.setQueryParam(
        this.filters.orStatement ? "true" : undefined
      );
      params.typ = "17EF8844E6B";
      params.incf = "1";

      return params;
    },

    sprachen: {
      get() {
        return this.$store.state.einstellungendashboard.sprachen;
      },
    },

    rollen: {
      get() {
        return this.$store.state.geschaeftspartner.partnerrollen;
      },
    },
    funktionsbereiche: {
      get() {
        return this.$store.state.geschaeftspartner.funktionsbereiche;
      },
    },
    interessen: {
      get() {
        return this.$store.state.geschaeftspartner.interessen;
      },
    },
    aktivitaetsstati: {
      get() {
        return this.$store.state.geschaeftspartner.aktivitaetsstati;
      },
    },
    versandlisten: {
      get() {
        return this.$store.state.korrespondenz.versandlisten;
      },
    },
    konkordate: {
      get() {
        return this.$store.state.geschaeftspartner.konkordate;
      },
    },

    rolleObj() {
      if (this.rolleProp && this.rollen)
        return this.rollen.find((r) => r.id === this.rolleProp);
      return null;
    },
    headline() {
      if (this.rolleProp && this.rollen) {
        let rolle = this.rollen.find((r) => r.id === this.rolleProp);
        if (rolle) return rolle.bezeichnung;
      }
      return "BX: " + this.$t("global.contacts");
    },
  },
  watch: {},
  created() {
    if (this.rollen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    if (this.versandlisten.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_VERSANDLISTEN}`);

    this.initFilter("geschaeftspartner-liste", "geschaeftspartner/", true);
  },
  mounted() {
    if (this.sprachen.length == 0) {
      this.$store.dispatch(
        `einstellungendashboard/${LADE_AUSWAHLWERTE_EINSTELLUNGEN}`
      );
    }

    this.$bus.$emit("searchWithFilter");
    //TODO: Mandantenspezifisches FrontEnd sauber loesen
    if (location.href.includes("healthspot")) {
      this.showLoginErstellenButton = true;
    }
    
    if (!this.filters.status && this.firstVisit) {
      // Setzen des Standardwerts nur beim ersten Besuch
      this.$set(this.filters, "status", ["1737FA777AA"]);
      this.firstVisit = false; // Markieren, dass der erste Besuch abgeschlossen ist
    }
  },
  methods: {
    adresse(geschaeftspartner) {
      if (geschaeftspartner.hauptadresse)
        return addresseToSingleLineString(geschaeftspartner.hauptadresse);
      else return "";
    },
    toggleOrParameter() {
      if (this.filters.orStatement) {
        this.$set(this.filters, "orStatement", false);
      } else {
        this.$set(this.filters, "orStatement", true);
      }
      this.filterSearch();
    },

    geschaeftspartnerLoeschen() {
      this.delete("geschaeftspartner/", this.selectedIds);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit(
          "openLoeschenModal",
          "geschaeftspartner-loeschen-modal"
        );
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonsselected"),
        });
      }
    },

    // oeffneLoginErstellenModal() {
    //   if (this.selectedIds.length > 0) {
    //     this.msgboxOkText = `${this.$t("global.ok")} (${
    //       this.selectedIds.length
    //     })`;
    //     let text = this.$t("global.createLoginInquiry");

    //     this.$bus.$emit("openMessageBoxEnhanced", {
    //       id: "create-login-alert-msgbox",
    //       text: text,
    //     });
    //   }
    // },

    ausgebenAvery365221() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/avery-3652-21.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    ausgebenAvery365314() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/avery-3653-14.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    ausgebenZweckform478427() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/zweckform-4784-27.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    massenmailErstellen(selected) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedIds
          : this.$refs.selectableTable.items.map((item) => item.id);

        let json = {
          empfaenger: tempList,
          isMassMail: true,
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        Api.post("mail/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "mail",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonsselected"),
        });
    },

    massenkorrespondenzErstellen(selected) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedIds
          : this.$refs.selectableTable.items.map((item) => item.id);

        let json = {
          empfaenger: [],
          person: [], // ist de facto person. Noch unsauber im Backend
          isMassKorrespondenz: true,
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter.person,
            datum: new Intl.DateTimeFormat("ch").format(new Date()),
            ort: this.$CONST("CONFIG").MANDANTSTADT,
            betreff: "",
          },
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        json.empfaenger = tempList;
        json.person = tempList;

        Api.post("korrespondenz/", json, { params: {} }).then((response) => {
          Api.post("korrespondenz/empfaenger/", json, {
            params: {
              korrespondenz: response.data.id,
              isPerson: true,
              isMassCorr: true,
            },
          }).then((empfresponse) => {}),
            this.$router.push({
              name: "korrespondenz",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
    },

    details(geschaeftspartner) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: geschaeftspartner.id, anzeige: 0 },
      });
    },

    refresh() {
      this.resetLoading(this);
    },

    oeffneDublettenCheckModal() {
      this.$bus.$emit("open-modal", "dubletten-check-modal");
    },
    oeffneDublettenZusammenfuehrenModal() {
      this.$bus.$emit("open-modal", "dubletten-zusammenfuehren-modal");
    },
    /*oeffnePortalZugangErstellenModal() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "portal-zugang-erstellen-msgbox",
        text: this.portalZugangText,
        headerText: this.$t("global.createportalaccess"),
      });
    },*/
    // createPortalZugang() {
    //   //TODO: Genauere Infos angeben, welche Logins nicht geklappt haben (fehlende Mailadresse oder bereits vorhandenes Login)
    //   PortalApi.post("registration/mail/", this.selectedIds).then(
    //     (response) => {
    //       console.log(response);
    //       this.$bus.$emit("openMessageBoxEnhanced", {
    //         id: "portal-zugang-success-msgbox",
    //         text: "Portal-Zugänge erfolgreich erstellt",
    //         headerText: this.$t("global.actionsuccessful"),
    //       });
    //     }
    //   );
    // },
    druckeTischkarten() {
      if (this.selectedIds?.length > 0 && this.selectedIds?.length < 150) {
        let ids = this.selectedIds.join(",");

        let filename = this.$t("global.placecards");

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/tischkarten-pdf.htm&landscape=true&ids=${ids}`;
        this.$refs.pdfLayer.show();
      } else if (this.selectedIds?.length > 150) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.toomanyentriesselected"),
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
      }
    },
    druckeAdressEtiketten() {
      if (this.selectedIds?.length > 0 && this.selectedIds?.length < 150) {
        let ids = this.selectedIds.join(",");

        let filename = this.$t("global.addresslabels");

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/adress-etiketten-pdf.htm&ids=${ids}`;
        this.$refs.pdfLayer.show();
      } else if (this.selectedIds?.length > 150) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.toomanyentriesselected"),
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
      }
    },
    druckeNamensschilder() {
      if (this.selectedIds?.length > 0 && this.selectedIds?.length < 150) {
        let ids = this.selectedIds.join(",");

        let filename = this.$t("global.nametags");

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/namensschilder-pdf.htm&ids=${ids}`;
        this.$refs.pdfLayer.show();
      } else if (this.selectedIds?.length > 150) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.toomanyentriesselected"),
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
      }
    },
  },
};
</script>

<style></style>
