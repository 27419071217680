<template>
  <div>
    <button
      class="btn btn-primary"
      :class="margins"
      @click="oeffneMassenmutationsModal"
      v-if="berechtigungen.m_bildung.create"
      :disabled="!editable"
    >
      {{ $t("global.massmutation") }}
    </button>
    <massenmutation-modal-generic
      :mutatableFields="this.mutatableFields"
      :mutatableFieldsMultiple="this.mutatableFieldsMultiple"
      :mutatableDateFields="this.mutatableDateFields"
      :mutatableDateNames="this.mutatableDateNames"
      :mutatableTimeNames="this.mutatableTimeNames"
      :mutatableTimeFields="this.mutatableTimeFields"
      :mutatableValues="this.mutatableValues"
      :mutatableMultipleValues="this.mutatableMultipleValues"
      :mutatableLabels="this.mutatableLabels"
      :mutatableLabelsMultiple="this.mutatableLabelsMultiple"
      :mutatableCheckboxes="this.mutatableCheckboxes"
      :mutationPfad="this.mutationPfad"
      :selectedIds="this.selectedIds"
      :customAddition="customAddition"
      :id="modalId"
      @mutated="mutated"
    >
      <!-- Named slot to pass content to modal -->
      <template #preModalContent>
        <slot name="preModalContent"></slot>
      </template>
    </massenmutation-modal-generic>
  </div>
</template>
<script>
import MassenmutationModalGeneric from "@/components/Modals/MassenmutationModalGeneric";

export default {
  name: "MassenmutationButton",
  components: { MassenmutationModalGeneric },
  props: {
    mutatableFields: {
      type: Array,
    },
    mutatableFieldsMultiple: {
      type: Array,
    },
    mutatableDateFields: {
      type: Array,
    },
    mutatableDateNames: {
      type: Array,
    },
    mutatableTimeFields: {
      type: Array,
    },
    mutatableTimeNames: {
      type: Array,
    },
    mutatableValues: {
      type: Array,
    },
    mutatableMultipleValues: {
      type: Array,
    },
    mutatableLabels: {
      type: Array,
    },
    mutatableLabelsMultiple: {
      type: Array,
    },
    mutatableCheckboxes: {
      type: Array,
    },
    mutationPfad: {
      type: String,
    },
    selectedIds: {
      type: Array,
    },
    margins: {
      type: String,
      default: "ml-2",
    },
    customAddition: {
      type: null, // not specified
      default: null,
    },
  },
  data() {
    return {
      editable: false,
      modalId: "massenmutation-modal-generic",
    };
  },
  watch: {
    selectedIds(val) {
      val != 0 ? (this.editable = true) : (this.editable = false);
    },
  },
  methods: {
    oeffneMassenmutationsModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("open-modal", this.modalId);
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.norecordselected"),
        });
      }
    },
    mutated() {
      this.$emit("mutated");
    },
  },
};
</script>
