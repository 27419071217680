<template>
  <!-- Table aus geschaeftspartnerliste übernommen (für Referenz) und abgeändert-->

  <div class="row block belegliste">
    <div class="w-100-p">
      <div>
        <b-table
          ref="selectableTable"
          small
          tbody-tr-class="item"
          :items="listData"
          :fields="fields"
          fixed
          selectable
          select-mode="range"
          @row-dblclicked="eineFunktion"
        >
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <b-input
                v-if="field.key != 'selected' && field.searchable != false"
                size="sm"
                v-model="filters[field.key]"
                :placeholder="field.label"
              />
            </td>
          </template>

          <!-- Select All Checkbox  funktioniert bisschen weird-->
          <template v-slot:head(selected)="header">
            <b-form-checkbox
              ref="headerCheckbox"
              v-model="header.selected"
              @input="selectAllRows(header)"
            ></b-form-checkbox>
          </template>
          <!-- Select single Checkbox -->
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              v-model="row.item.selected"
              @input="onRowSelected(row)"
            ></b-form-checkbox>
          </template>
        </b-table>

        <!-- handling vom Laden weiterer Datensätze -> müssen umbenannt werden  -->
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
          <div slot="spinner">
            {{ $t("global.loadmorebusinesspartners") }}
          </div>
          <div slot="no-more">
            {{ $t("global.nofurtherbusinesspartners") }}
          </div>
          <div slot="no-results">
            {{ $t("global.nofurtherbusinesspartnersfound") }}
          </div>
        </infinite-loading>
      </div>
    </div>
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import store from "@/store";

export default {
  name: "ProjekterfassungtableDefault",
  components: {},
  mixins: [page, dashboard, filter],
  store,
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
    filter: {
      type: Object,
      required: true,
      default: null,
    },
    kunden: {
      type: Array,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      listData: [],
      /* Key ist der Name des Keys, aus dem JSON, welches die Daten für den Table liefert. siehe andere Tables*/
      fields: [
        { key: "selected", label: "" },
        {
          key: "customer",
          sortable: true,
          label: this.$t("global.customer"),
        },
        /*   { key: "projekt", sortable: true, label: this.$t("global.project") }, */
        {
          key: "plannedHours",
          sortable: true,
          label: this.$t("global.plannedhours"),
        },
        {
          key: "actualHours",
          sortable: true,
          label: this.$t("global.actualhours"),
        },
        {
          key: "projectstatus",
          sortable: true,
          label: this.$t("global.projectstatus"),
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  created() {
    this.getData();

    this.initFilter(
      "projektuebersichtDefault-liste",
      "zeiterfassung/projektuebersicht/",
      true
    );
  },
  mounted() {
    this.setHeaders("global", [
      "",
      "customer",
      "plannedhours",
      "actualhours",
      "projectstatus",
    ]);
  },
  watch: {
    /* kunden(val) {
      this.listData = val;
    }, */
  },
  computed: {
    queryParams: function () {
      var params = {
        insightMode: this.filter.insightMode,
        page: this.page,
        count: this.anzahlProPage,
      };
      params.filterCustomer = this.filters["customer"];
      params.filterActualHours = this.filters["actualHours"];

      params.filterPlannedHours = this.filters["plannedHours"];

      params.filterProjectstatus = this.filters["projectstatus"];

      return params;
    },
  },
  methods: {
    eineFunktion() {},

    getData() {
      console.log("Filters: ");
      console.log(this.filters);
      Api.get("zeiterfassung/projektuebersicht/", {
        params: {
          insightMode: this.filter.insightMode,
        },
      }).then((response) => {
        this.listData = response.data;
      });
    },
  },
};
</script>

<style lang="scss"></style>
