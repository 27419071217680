var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xl-12 pr-4",class:{ 'col-xl-12': !_vm.showNavbar }},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0"},[_c('div',{staticClass:"eingabe-panel-kopf p-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"form-group"},[(_vm.korrespondenz.translation && _vm.lang)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                  _vm.korrespondenz.translation[_vm.lang.kuerzel.toLowerCase()]
                    .betreff
                ),expression:"\n                  korrespondenz.translation[lang.kuerzel.toLowerCase()]\n                    .betreff\n                ",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(
                  _vm.korrespondenz.translation[_vm.lang.kuerzel.toLowerCase()]
                    .betreff
                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz.translation[_vm.lang.kuerzel.toLowerCase()]
                    , "betreff", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t("global.subject")))])])]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.ort),expression:"korrespondenz.ort",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.korrespondenz.ort)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "ort", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("global.city")))])])]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"options":_vm.unterschriften,"disabled":!_vm.editable,"placeholder":_vm.$t('global.signature'),"getOptionLabel":function (unterschrift) { return unterschrift.person.personName; }},on:{"input":_vm.unterschrift1Updated},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                    var person = ref.person;
return [_vm._v(" "+_vm._s(person.personName)+" ")]}},{key:"selected-option",fn:function(ref){
                    var person = ref.person;
return [_vm._v(" "+_vm._s(person.id ? person.personName : _vm.$t("global.notlinked"))+" ")]}}]),model:{value:(_vm.unterschrift1),callback:function ($$v) {_vm.unterschrift1=$$v},expression:"unterschrift1"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.signature1")))])],1)]),_c('div',{staticClass:"col-xl-2"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"options":_vm.unterschriften,"disabled":!_vm.editable,"placeholder":_vm.$t('global.signature'),"getOptionLabel":function (unterschrift) { return typeof unterschrift.person == 'string'
                      ? unterschrift.person
                      : unterschrift.person.personName; }},on:{"input":_vm.unterschrift2Updated},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                      var person = ref.person;
return [_vm._v(" "+_vm._s(person.personName)+" ")]}},{key:"selected-option",fn:function(ref){
                      var person = ref.person;
return [_vm._v(" "+_vm._s(person.id ? person.personName : _vm.$t("global.notlinked"))+" ")]}}]),model:{value:(_vm.unterschrift2),callback:function ($$v) {_vm.unterschrift2=$$v},expression:"unterschrift2"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("global.taptosearch")))])]),_c('label',[_vm._v(_vm._s(_vm.$t("global.signature2")))])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"}),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.korrespondenz.beilagen),expression:"korrespondenz.beilagen",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"readonly":!_vm.editable,"placeholder":" "},domProps:{"value":(_vm.korrespondenz.beilagen)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.korrespondenz, "beilagen", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('label',[_vm._v(_vm._s(_vm.$t("global.Attachments")))])])])]),(_vm.korrespondenz.translation && _vm.lang)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"form-group"},[_c('ckeditor',{attrs:{"disabled":!_vm.editable,"editor":_vm.editor},model:{value:(
                  _vm.korrespondenz.translation[_vm.lang.kuerzel.toLowerCase()].text
                ),callback:function ($$v) {_vm.$set(_vm.korrespondenz.translation[_vm.lang.kuerzel.toLowerCase()], "text", $$v)},expression:"\n                  korrespondenz.translation[lang.kuerzel.toLowerCase()].text\n                "}})],1)])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }