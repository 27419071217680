<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        headline="Leistungen"
        :col="3"
      >

      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="leistungen"
                :fields="fields"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #cell(edit)="row" class="d-flex align-items-center">
                  <div
                    class="ml-5"
                    role="button"
                    @click="goToTimeSheet(row.item)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pencil" size="sm"/>
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

import reiter from "@/mixins/Reiter";
import KotlinAppApi from "@/KotlinAppApi";

export default {
  components: {
    ContainerHeadline,
  },
  mixins: [reiter],
  props: {
    lead: {
      type: String,
      required: false,
    },
    ansprechpartner: {
      type: Object,
      required: false,
    },
    firma: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 200,
      aktivitaeten: [],
      selectedIds: [],
      sortBy: "effektivdatum",
      sortDesc: false,
      fields: [
        {key: "selected", label: ""},
        {
          key: "aufgabe.projekt.endkunde.name",
          sortable: true,
          label: "Kunde",
        },
        {
          key: "aufgabe.projekt.bezeichnung",
          sortable: true,
          label: "Projekt",
        },
        {
          key: "aufgabe.bezeichnung",
          sortable: true,
          label: "Aufgabe",
        },
        {
          key: "stunden",
          sortable: true,
          label: "Stunden",
        },
        {
          key: "mitarbeiter",
          sortable: true,
          label: "Mitarbeiter",
          formatter: (value) => {
            return value.vorname + ' ' + value.nachname;
          }
        },
        {
          key: "datum",
          sortable: true,
          label: "Datum",
        },
        {key: "edit", sortable: false, label: "Edit"},
      ],
      leistungen: [],

    };
  },
  watch: {
    shown(val) {
      if (val && this.aktivitaeten.length == 0) this.getAktivitaeten();
    },
  },
  computed: {},
  mounted() {
    if (this.shown) this.getAktivitaeten();
  },
  created() {
  },
  methods: {
    getAktivitaeten() {
      KotlinAppApi.get("beratungsauftrag/timesheet/", {
        params: {
          id: this.lead,
          page: this.page,
          count: this.anzahlProPage,
        },
      }).then((response) => {
        this.leistungen = response.data;
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "aktivitaet-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noactivityiesselectedfordeletion"),
        });
      }
    },

    goToTimeSheet(item) {
      this.$router.push({ name: "timesheet", params: { id: item.timesheet_id } });
    }
  },
};
</script>
