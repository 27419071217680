<template>
  <div>
    <container-headline
      :headline="$t('global.portalaccess')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div v-if="users && users.length === 0">
            <div class="row">
              <div class="col-8 font-italic fs-14">
                {{ $t("global.noportalaccess") }}
              </div>
            </div>
          </div>

          <div class="pr-5" v-if="users && users.length != 0">
            <div
              class="row mx-auto font-weight-bold fs-14 border-bottom border-primary border-1 d-flex justify-between"
            >
              <div class="col-1">{{ $t("global.status") }}</div>
              <div class="col-8">{{ $t("global.username") }}</div>
            </div>
            <div
              class="row mx-auto py-2"
              v-for="(user, index) in users"
              :key="'user-' + index"
              :class="
                users.length > 1 && users.length - 1 != index
                  ? 'border-bottom'
                  : ''
              "
            >
              <div
                class="col-1 text-danger text-center"
                v-if="!user.bc_active || !user.portaluser_active"
                v-tooltip.hover
                :title="$t('global.userisinactive')"
              >
                <font-awesome-icon icon="fa-duotone fa-user-times" />
              </div>
              <div
                class="col-1 text-success text-center"
                v-if="user.bc_active && user.portaluser_active"
                v-tooltip.hover
                :title="$t('global.userisactive')"
              >
                <font-awesome-icon icon="fa-duotone fa-user-check" />
              </div>
              <div class="col-8 font-italic">{{ user.username }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "Geschaeftspartner-Portalzugaenge",
  components: {
    ContainerHeadline,
  },
  mixins: [],
  props: {
    personid: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
