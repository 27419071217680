<template>
  <!-- Table aus geschaeftspartnerliste übernommen (für Referenz) und abgeändert-->

  <div class="row block belegliste">
    <div class="w-100-p">
      <div>
        <b-table
          ref="selectableTable"
          small
          tbody-tr-class="item"
          :items="listData"
          :fields="fields"
          fixed
          selectable
          select-mode="range"
          @row-dblclicked="eineFunktion"
        >
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <b-input
                v-if="field.key != 'selected' && field.searchable != false"
                size="sm"
                v-model="filters[field.key]"
                :placeholder="field.label"
              />
            </td>
          </template>

          <!-- Select All Checkbox -->
          <template v-slot:head(selected)="header">
            <b-form-checkbox
              ref="headerCheckbox"
              v-model="header.selected"
              @input="selectAllRows(header)"
            ></b-form-checkbox>
          </template>
          <!-- Select single Checkbox -->
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              v-model="row.item.selected"
              @input="onRowSelected(row)"
            ></b-form-checkbox>
          </template>
        </b-table>

        <!-- handling vom Laden weiterer Datensätze -> müssen umbenannt werden  -->
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
          <div slot="spinner">
            {{ $t("global.loadmoreprojects") }}
          </div>
          <div slot="no-more">
            {{ $t("global.nofurtherprojects") }}
          </div>
          <div slot="no-results">
            {{ $t("global.nofurtherprojectsfound") }}
          </div>
        </infinite-loading>
      </div>
    </div>
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

export default {
  name: "ProjekterfassungtableProjekt",
  components: {},
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
    filter: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      /* Key wird durch Variabel ersetzt, die gewünschte Daten enthält, siehe andere Tables*/
      fields: [
        { key: "selected", label: "" },
        {
          key: "customer",
          sortable: true,
          label: this.$t("global.customer"),
        },
        { key: "project", sortable: true, label: this.$t("global.project") },
        {
          key: "project",
          sortable: true,
          label: this.$t("global.project"),
        },
        {
          key: "projecttask",
          sortable: true,
          label: this.$t("global.projecttask"),
        },
        {
          key: "plannedHours",
          sortable: true,
          label: this.$t("global.targethours"),
        },
        {
          key: "actualHours",
          sortable: true,
          label: this.$t("global.actualhours"),
        },
        {
          key: "abrechenbar",
          sortable: true,
          label: this.$t("global.billable"),
          formatter: (value) => {
            switch (value) {
              case true:
                return "yes";
              case false:
                return "no";
              default:
                return "-";
            }
          },
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    eineFunktion() {},

    getData() {
      Api.get("zeiterfassung/projektuebersicht/", {
        params: {
          insightMode: this.filter.insightMode,
          emplpoyeeId: this.filter.mitarbeiter,
          companyId: this.filter.kunde,
          projectId: this.filter.projekt,
          projectTaskId: this.filter.projektaufgabe,
        },
      }).then((response) => {
        console.log(response.data);
        this.listData = response.data;
      });
    },
  },
};
</script>

<style lang="scss">
</style>
