<template>
  <div>
    <container-headline
      :headline="$t('global.masterdata')"
      :col="2"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <input
                  :readonly="!editable"
                  v-model.trim="lehrgang.kuerzel"
                  class="form-control"
                  tabindex="1"
                  v-bind:class="{ 'border-danger': !passed }"
                  placeholder=" "
                />

                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.shortdesignation")
                }}</label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <input
                  :readonly="!editable"
                  v-model.trim="lehrgang.bezeichnung"
                  class="form-control"
                  v-bind:class="{ 'border-danger': !passed }"
                  tabindex="2"
                  placeholder=" "
                />
                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.designation")
                }}</label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <input
                :readonly="!editable"
                v-model.trim="lehrgang.bezeichnung_m"
                class="form-control"
                tabindex="3"
                placeholder=" "
              />
              <label>{{ $t("global.designationmale") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <input
                :readonly="!editable"
                v-model.trim="lehrgang.bezeichnung_f"
                class="form-control"
                tabindex="4"
                placeholder=" "
              />
              <label>{{ $t("global.designationfemale") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 form-group" :key="statuselement">
              <v-select
                :disabled="!editable"
                v-model="lehrgang.lehrgangstatus"
                :getOptionLabel="
                  (lehrgangstatus) =>
                    lehrgangstatus.bezeichnung
                      ? lehrgangstatus.bezeichnung
                      : lehrgangstatus.Bezeichnung
                "
                :options="lehrgangstati"
                eager="true"
                :tabindex="11"
                :placeholder="$t('global.status')"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.status") }}</label>
            </div>
            <div class="col-xl-3">
              <single-select-person
                id="bildungsgangleiter"
                :person="this.lehrgang.lehrgangleiter"
                :label="$t('global.courseinstructor')"
                :allowOpen="true"
                :editable="editable"
                :required="true"
                tabindex="6"
                @confirmed="setBildungsgangleiter"
              />
            </div>

            <div class="col-xl-3">
              <single-select-person
                id="kontakt"
                :person="this.lehrgang.kontakt"
                :label="$t('global.contact')"
                :allowOpen="true"
                :editable="editable"
                tabindex="6"
                @confirmed="setKontakt"
              />
            </div>

            <div
              class="col-xl-3 form-group"
              v-if="!isTemplate && lehrgang.templatelehrgang"
            >
              <input
                v-model="lehrgang.templatelehrgang.bezeichnung"
                class="form-control"
                readonly
                tabindex="10"
                placeholder=" "
              />
              <label>{{ $t("global.basedontemplate") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <multi-select-person
                id="verantwortliche"
                :personen="this.lehrgang.verantwortliche"
                :label="$t('global.responsible')"
                :allowOpen="false"
                :editable="editable"
                @confirmed="setVerantwortliche"
                tabindex="7"
              />
            </div>

            <div class="col-xl-6">
              <multi-select-person
                id="weitereVerantwortliche"
                :personen="this.lehrgang.verantwortliche2"
                :label="$t('global.additionalresponsible')"
                :allowOpen="false"
                :editable="editable"
                @confirmed="setVerantwortliche2"
                tabindex="7"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <v-select
                  :disabled="!editable"
                  v-model="lehrgang.bildungsgangtyp"
                  label="bezeichnung"
                  :options="bildungsgangtypen"
                  eager="true"
                  :tabindex="12"
                  :placeholder="$t('global.educationcoursetype')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.educationcoursetype")
                }}</label>
              </ValidationProvider>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="lehrgang.themen"
                label="bezeichnung"
                :options="themen"
                :disabled="!editable"
                :placeholder="$t('global.dashboard_topic')"
                multiple
                :tabindex="13"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.dashboard_topic") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="lehrgang.zielgruppen"
                :getOptionLabel="
                  (zielgruppe) =>
                    zielgruppe.bezeichnung
                      ? zielgruppe.bezeichnung
                      : zielgruppe.Bezeichnung
                "
                :options="zielgruppen"
                :disabled="!editable"
                :placeholder="$t('global.targetgroups')"
                multiple
                :tabindex="13"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.targetgroups") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <v-select
                  v-model="lehrgang.sprache"
                  v-bind:class="{ 'border-danger': !passed }"
                  :getOptionLabel="
                    (sprache) =>
                      sprache.bezeichnung
                        ? sprache.bezeichnung
                        : sprache.Bezeichnung
                  "
                  :options="sprachen"
                  :disabled="!editable"
                  multiple
                  :placeholder="$t('global.language')"
                  :tabindex="13"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label v-bind:class="{ 'text-danger': !passed }">{{
                  $t("global.language")
                }}</label>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-xl-2 form-group">
				<date-picker
				  :disabled="!editable"
				  class="mt-2 mb-3"
				  label="Gültig ab"
				  date-format="dd.mm.yy"
				  :initial="lehrgang.gueltig_ab"
				  placeholder=" "
				  :show-icon="true"
				  @update="(val) => (lehrgang.gueltig_ab = val)"
				/>
			  </div>
			  -->
            <div class="col-xl-2 form-group" v-if="!isTemplate">
              <date-picker
                :disabled="!editable"
                date-format="dd.mm.yy"
                :initial="lehrgang.startdatum"
                placeholer=" "
                :show-icon="true"
                :tabindex="14"
                @update="(val) => (lehrgang.startdatum = val)"
              />
              <label>{{ $t("global.start") }}</label>
            </div>
            <div class="col-xl-2 form-group" v-if="!isTemplate">
              <date-picker
                :disabled="!editable"
                date-format="dd.mm.yy"
                :initial="lehrgang.enddatum"
                placeholder=" "
                :show-icon="true"
                :tabindex="15"
                @update="(val) => (lehrgang.enddatum = val)"
              />
              <label>{{ $t("global.end") }}</label>
            </div>
            <div class="col-xl-1 form-group" v-if="!isTemplate">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.teilnehmer_max"
                class="form-control"
                tabindex="3"
                placeholder=" "
              />
              <label
                >{{ $t("global.participants") }}
                {{ $t("global.maximum") }}</label
              >
            </div>

            <div class="col-xl-1 form-group">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.startperiode"
                class="form-control"
                tabindex="16"
                placeholder=" "
              />
              <label>{{ $t("global.startperiod") }}</label>
            </div>
            <div class="col-xl-1 form-group">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.anzahl_perioden"
                class="form-control"
                tabindex="17"
                placeholder=" "
              />
              <label>{{ $t("global.numberofperiods") }}</label>
            </div>
            <div class="col-xl-1 form-group" v-if="isTemplate">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.teilnehmer_max"
                class="form-control"
                tabindex="18"
                placeholder=" "
              />
              <label
                >{{ $t("global.participants") }}
                {{ $t("global.maximum") }}</label
              >
            </div>
            <div class="col-xl-1 form-group" v-if="!isTemplate">
              <input
                :readonly="!editable"
                v-model.number="lehrgang.aktuelle_periode"
                class="form-control"
                tabindex="20"
                placeholder=" "
              />
              <label>{{ $t("global.currentperiod") }}</label>
            </div>
            <div class="col-xl-1">
              <div class="form-check">
                <input
                  :disabled="!editable"
                  v-model="lehrgang.warteliste_zulassen"
                  class="form-check-input"
                  type="checkbox"
                  tabindex="19"
                  :label="$t('global.allowwaitinglist')"
                />
                <label class="form-check-label">{{
                  $t("global.allowwaitinglist")
                }}</label>
              </div>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="lehrgang.nachweis"
                :getOptionLabel="
                  (nachweis) =>
                    nachweis.bezeichnung ? nachweis.bezeichnung : nachweis.id
                "
                :options="nachweise"
                :disabled="!editable"
                :placeholder="$t('global.proof')"
                :tabindex="20"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.proof") }}</label>
            </div>
          </div>
          <div class="row">
            <!--
			  <div class="col-xl-1 form-group">
				<input
				  :readonly="!editable"
				  v-model.trim="lehrgang.parallelklasse"
				  class="form-control"
				  placeholder=" "
				/>
				<label>Parallelklasse</label>
			  </div>
			  <div class="col-xl-1 form-group">
				<input
				  :readonly="!editable"
				  v-model.trim="lehrgang.kuerzel_parallelklasse"
				  class="form-control"
				  placeholder=" "
				/>
				<label>Kurzbezeichnung Parallelklasse</label>
			  </div>
			  -->

            <div class="col-xl-3 form-group">
              <v-select
                v-model="lehrgang.lehrgangsgruppe"
                :getOptionLabel="
                  (gruppe) =>
                    gruppe.bezeichnung ? gruppe.bezeichnung : gruppe.id
                "
                :options="filteredLehrgangsgruppen"
                :disabled="!editable"
                :placeholder="$t('global.coursegroup')"
                :tabindex="22"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.coursegroup") }}</label>
            </div>
            <div class="col-xl-3 form-group">
              <v-select
                v-model="lehrgang.lehrgangskategorie"
                :getOptionLabel="
                  (kategorie) =>
                    kategorie.bezeichnung ? kategorie.bezeichnung : kategorie.id
                "
                :options="
                  lehrgang.lehrgangsgruppe
                    ? lehrgang.lehrgangsgruppe.lehrgangskategorien
                    : lehrgangskategorien
                "
                :disabled="!editable"
                :placeholder="$t('global.coursecategory')"
                :tabindex="23"
              >
                <span slot="no-options">{{ $t("global.taptosearch") }}</span>
              </v-select>
              <label>{{ $t("global.coursecategory") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 form-group">
              <textarea
                :readonly="!editable"
                v-model.trim="lehrgang.beschreibung"
                class="form-control"
                tabindex="21"
                placeholder=" "
              ></textarea>
              <label>{{ $t("global.internalremarks") }}</label>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model="lehrgang.vorbereitung"
                  :disabled="!editable"
                ></ckeditor>
                <label>{{ $t("global.preparation") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import MultiSelectPerson from "@/components/global/MultiSelectPerson.vue";
import ClassicEditor from "/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "BildungsgangStammdaten",
  components: {
    ContainerHeadline,
    DatePicker,
    SingleSelectPerson,
    MultiSelectPerson,
  },
  props: {
    lehrgang: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    isTemplate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      statuselement: +new Date(),
      editor: ClassicEditor,
    };
  },
  computed: {
    lehrgangstati: {
      get() {
        return this.$store.state.veranstaltung.lehrgangstati;
      },
    },
    bildungsgangtypen: {
      get() {
        return this.$store.state.veranstaltung.bildungsgangtypen;
      },
    },
    themen: {
      get() {
        return this.$store.state.veranstaltung.themen;
      },
    },
    zielgruppen: {
      get() {
        return this.$store.state.veranstaltung.zielgruppen;
      },
    },
    sprachen: {
      get() {
        return this.$store.state.veranstaltung.sprachen;
      },
    },
    nachweise: {
      get() {
        return this.$store.state.veranstaltung.nachweise;
      },
    },
    lehrgangsgruppen: {
      get() {
        return this.$store.state.veranstaltung.lehrgangsgruppen;
      },
    },
    lehrgangskategorien: {
      get() {
        return this.$store.state.veranstaltung.lehrgangskategorien;
      },
    },
    filteredLehrgangsgruppen() {
      if (this.lehrgang.lehrgangskategorie) {
        return this.lehrgangsgruppen.filter((gruppe) => {
          return gruppe.lehrgangskategorien.some(
            (kategorie) => kategorie.id === this.lehrgang.lehrgangskategorie.id
          );
        });
      } else return this.lehrgangsgruppen;
    },
  },
  watch: {
    "lehrgang.lehrgangskategorie": function (newVal) {
      if (newVal && this.filteredLehrgangsgruppen.length === 1) {
        this.lehrgang.lehrgangsgruppe = this.filteredLehrgangsgruppen[0];
      }
    },
  },
  mounted: function () {
    if (this.lehrgangstati.length == 0) {
      this.$store
        .dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`)
        .then(() => {
          this.initializeData();
        });
    } else {
      this.initializeData();
    }
  },
  methods: {
    initializeData() {
      if (!this.lehrgang.lehrgangstatus) {
        const lehrgangStatusPlanung = this.$CONST("LEHRGANGSTATI").PLANNED;

        this.lehrgang.lehrgangstatus = this.lehrgangstati.find(
          ({ id }) => id == lehrgangStatusPlanung
        );
        this.statuselement += 1;
      }
      //   if (!this.lehrgang.sprache?.id) {
      //     const defaultSprache = this.$CONST("CONFIG").DEFAULTLANGUAGE;

      //     let defaultSprachenObj = this.sprachen.find(
      //       (sprache) => sprache.id === defaultSprache
      //     );
      //     this.$emit("spracheUpdated", defaultSprachenObj);
      //   }
    },

    setBildungsgangleiter(person) {
      this.lehrgang.lehrgangleiter = person;
    },
    setKontakt(person) {
      this.lehrgang.kontakt = person;
    },
    setVerantwortliche(verantwortliche) {
      console.log(verantwortliche);
      this.lehrgang.verantwortliche = verantwortliche;
    },
    setVerantwortliche2(verantwortliche2) {
      console.log(verantwortliche2);
      this.$set(this.lehrgang, "verantwortliche2", verantwortliche2);
      //this.lehrgang.verantwortliche2 = verantwortliche2;
    },
  },
};
</script>

<style lang="scss"></style>
