<template>
  <div class="row">
    <div
      :class="[colClass]"
      class="block-dark block-kopf-dark"
      :style="systemColor()"
    >
      <div class="row">
        <div class="col-xl-4">
          <span class="primary-headline-text text-white">
            <font-awesome-icon icon="fa-regular fa-calendar-alt" class="mr-2" />
            {{ timestamp }}
          </span>
        </div>
        <div class="col-xl-4 text-center">
          <span class="primary-headline-text text-white">{{ headline }}</span>
        </div>
        <div class="col-xl-4 text-right" v-if="anzahl">
          <span class="primary-headline-text text-white"
            >{{ $t("global.numberofselectedentries") }}: {{ anzahl }} /
            {{ gesamtanzahl }}
          </span>
        </div>
        <div class="col-xl-4 text-right" v-if="!anzahl && gesamtanzahl != null">
          <span class="primary-headline-text text-white"
            >{{ $t("global.totalentries") }}: {{ gesamtanzahl }}
          </span>
        </div>
        <div class="col-xl-4 text-right" v-if="showTime">
          <span class="primary-headline-text text-white">{{ clockstamp }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";

import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";

library.add(faCalendarAlt);

export default {
  name: "HeadMenu",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
    //anzeige Anzahl Datensätze im Headmenu
    anzahl: {
      type: Number,
      required: false,
      default: null,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    updateClock: {
      type: Boolean,
      default: false,
    },
    gesamtanzahl: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      timestamp: "",
      clockstamp: "",
    };
  },
  computed: {
    date() {
      const today = new Date();
      const weekDay = today.toLocaleString(this.$i18n.locale, {
        weekday: "long",
      });
      const month = today.toLocaleString(this.$i18n.locale, { month: "long" });
      return (
        weekDay.charAt(0).toUpperCase() +
        weekDay.slice(1) +
        " " +
        today.getDate() +
        ". " +
        month.charAt(0).toUpperCase() +
        month.slice(1) +
        " " +
        today.getFullYear()
      );
    },
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
  watch: {
    date() {
      this.getNow();
    },
  },
  mounted() {
    this.getNow();
    this.getTime();

    if (this.updateClock) {
      let that = this;
      setInterval(function () {
        that.getNow();
        that.getTime();
      }, 15 * 1000); // 60 * 1000 milsec
    }
  },
  methods: {
    getNow() {
      this.timestamp = this.date;
    },

    getTime() {
      //const today = new Date();
      const time = new Date().toLocaleTimeString("de-CH", {
        hour: "2-digit",
        minute: "2-digit",
      });

      this.clockstamp = time;
    },
    systemColor() {
      switch (this.$CONST("CONFIG").ENVIRONMENT) {
        case "DEV":
          return "background-color: #5F379B";
        case "TEST":
          return "background-color: #248C8C";
        default:
          return "background-color: #124862";
      }
    },
  },
};
</script>

<style></style>
