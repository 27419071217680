<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.kuerzel"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungsstatus"
                        :options="veranstaltungsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.eventstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filters.verantwortlicher"
                      :label="$t('global.Responsible')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :editable="true"
                      @confirmed="setFilterVerantwortlicher"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.semester"
                        :options="semester"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.semester')"
                      >
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>Semester</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.courses')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <button
                      class="btn btn-success"
                      @click="neueVeranstaltung"
                      v-if="berechtigungen.m_bildung.create"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_bildung.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(periode)="data">
                      <div v-if="data.value">
                        {{ data.value.bezeichnung }} ({{
                          data.value.startdatum
                        }}-{{ data.value.enddatum }})
                      </div>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>

            <div id="modal-loeschen" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>
                      <font-awesome-icon
                        icon="fa-regular fa-warning"
                        class="mr-2 text-warning"
                      />{{ $t("global.deleteevent") }}
                    </h4>
                  </div>
                  <div class="modal-body">
                    {{ $t("global.doyouwanttodeleteevents") }}
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-default"
                      @click="schliesseLoeschenModal"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                    <button class="btn btn-primary" @click="loeschen">
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "Kursliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    SingleSelectPerson,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.courses");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      kategorie: null,
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,

      sortBy: "bezeichnung",
      sortDesc: false,
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "startdatum",
          label: this.$t("global.startdate"),
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
          searchable: false,
        },
        {
          key: "enddatum",
          label: this.$t("global.enddate"),
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
          searchable: false,
        },
        {
          key: "periode",
          sortable: true,
          label: this.$t("global.semester"),
          searchable: false,
        },
        {
          key: "veranstaltungstyp.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.type"),
        },
        {
          key: "veranstaltungsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "anmeldungen",
          label: this.$t("global.registrations"),
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },
      ];
    },
    queryParams: function () {
      var params = {
        veranstaltungstyp: [
          "174D91487E8",
          "174D91487E0",
          "174D91487D6",
          "174D91487E4",
        ].join(","),
      };

      params.filterBezeichnung = this.setQueryParam(this.filters.bezeichnung);
      params.filterKurzbezeichnung = this.setQueryParam(this.filters.kuerzel);

      params.status = this.setQueryParam(this.filters.veranstaltungsstatus);
      params.verantwortlicher = this.setQueryParam(
        this.filters.verantwortlicher?.id
      );
      params.periode = this.setQueryParam(this.filters.semester);

      // TODO:

      // params.filterVTBez = this.filters['veranstaltungstyp.bezeichnung']
      // params.filterVSBez = this.filters['veranstaltungsstatus.bezeichnung']

      params.filterCount = this.setQueryParam(this.filters.count);

      return params;
    },

    semester: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    veranstaltungstypen: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungstypen;
      },
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
  },

  created() {
    this.initFilter("bildungs-liste-kurse", "veranstaltungen/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (this.veranstaltungstypen.length == 0) {
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
    }
  },
  methods: {
    details(veranstaltungen) {
      this.$router.push({
        name: "kurs",
        params: { kursid: veranstaltungen.id, anzeige: 0 },
      });
    },

    setFilterVerantwortlicher(person) {
      this.$set(this.filters, "verantwortlicher", person);
    },

    neueVeranstaltung() {
      this.$router.push({
        name: "kurs",
        params: { anzeige: 0 },
      });
    },

    loeschen() {
      Api.delete("veranstaltungen/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.schliesseLoeschenModal();

        /*
        this.listData = this.listData.filter(
          (v) => !response.data.success.includes(v.id)
        );
        */
        this.resetLoading(this);
      });
    },

    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },

    ausgebenStammblattPdf() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          `/pdf/stammblatt/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenPraesenzNotenPdf() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          `/pdf/praesenz-notenliste/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenAdressliste() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          `/pdf/teilnehmerliste-adressliste-anmeldung/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenPraesenzliste() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          `/pdf/praesenzliste/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenPraesenzlisteKW() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          `/pdf/praesenzliste-kw-anmeldung/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenTeilnehmerlisteAnmeldeantworten() {
      window.open(
        this.$CONST("CONFIG").BASEURL +
          `/pdf/teilnehmerliste-mit-anmeldeantworten/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },
  },
};
</script>

<style></style>
