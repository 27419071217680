<template>
  <div>
    <div :class="{ 'mt-30-px': !showNavbar }">
      <notifications></notifications>

      <navbar v-if="showNavbar" :docsName="docsName" />

      <!-- HEAD -->
      <!-- TODO: Headline Lehrganggruppe-Bezeichnung hinzufügen + Sprachvariable anpassen -->
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="$t('global.attendanceoverview')"
              ></head-menu>

              <div class="row mt-2 d-flex">
                <!--<div class="ml-4">
					 <button
					  class="btn btn-primary"
					  @click="assignSelectedCertificates"
					>
					  <span>Ausgewählte Zertifikate zuweisen</span>
					</button>
				  </div> -->
                <div class="ml-4">
                  <button
                    class="btn btn-default"
                    @click="openShowPDFForSelectedModal"
                    :disabled="selectedIds.length === 0"
                  >
                    <span>{{ $t("global.showcertificatesforselected") }}</span>
                  </button>
                </div>
                <div class="ml-2">
                  <button
                    class="btn btn-primary"
                    @click="openAssignPDFForSelectedModal"
                    :disabled="selectedIds.length === 0"
                  >
                    <span>{{
                      $t("global.assigncertificatesforselected")
                    }}</span>
                  </button>
                </div>
                <div class="ml-auto mr-2">
                  <button class="mr-3 btn btn-primary" @click="abbrechen">
                    <span>{{ $t("global.cancel") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- TABELLE -->
        <div class="row ml-0 w-100-p">
          <div class="col-12 block belegliste text-break">
            <div
              class="lehrgangsuebergreifendeNotenuebersichtsTabelle"
              v-if="loaded"
            >
              <b-table
                class="w-100-p"
                hover
                no-border-collapse
                striped
                responsive
                sticky-header="1000px"
                ref="selectableTable"
                small
                tbody-tr-class="item"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                selected-variant=""
              >
                <template #thead-top="data">
                  <tr>
                    <th v-for="field in data.fields" :key="field.key">
                      <div
                        v-if="
                          field.key != 'name' &&
                          field.key != 'registrationstatus' &&
                          field.key != 'total_attendance' &&
                          field.label_category
                        "
                      >
                        {{ field.label_category }}
                      </div>
                    </th>
                  </tr>
                </template>

                <template #cell()="data">
                  <div v-if="data.value === ''">-</div>
                  <div v-else-if="isDispensiert(data.item, data.field.key)">
                    {{ $t("global.dispensed") }}
                  </div>
                  <div v-else-if="isAttendance(data.field.key)">
                    {{ data.value + "%" }}
                  </div>
                  <div v-else>{{ data.value }}</div>
                </template>

                <template #cell(certificate)="data">
                  <!-- FÜHRUNGSAUSBILDUNG -->
                  <div
                    class="d-flex flex-wrap py-2"
                    :key="'proofOfCompetenceButtons-' + data.index"
                    v-if="bildungsgangtyp === '187EB127389'"
                  >
                    <button
                      class="btn btn-default mr-3 w-45-p"
                      @click="openShowProofOfCompetenceModal(data)"
                    >
                      {{ $t("global.showcompetencycertification") }}
                    </button>
                    <button
                      class="btn btn-primary w-45-p"
                      @click="openAssignProofOfCompetenceModal(data)"
                    >
                      {{ $t("global.assigncompetencycertification") }}
                    </button>
                  </div>

                  <!-- GRUNDAUSBILDUNG -->
                  <div
                    class="d-flex flex-wrap py-2"
                    :key="'certificateButtons-' + data.index"
                    v-else-if="bildungsgangtyp === '187EB11D0E7'"
                  >
                    <button
                      class="btn btn-default mr-3 w-45-p"
                      @click="openShowCertificateModal(data)"
                    >
                      {{ $t("global.showcertificate") }}
                    </button>
                    <button
                      class="btn btn-primary w-45-p"
                      @click="openAssignCertificateModal(data)"
                    >
                      {{ $t("global.assigncertificate") }}
                    </button>
                  </div>

                  <!-- WEITERBILDUNG -->
                  <div
                    class="d-flex flex-wrap py-2"
                    :key="'moduleConfirmationButtons-' + data.index"
                    v-else
                  >
                    <button
                      class="btn btn-default mr-3 w-45-p"
                      @click="openShowModuleConfirmationModal(data)"
                    >
                      {{ $t("global.viewmoduleconfirmation") }}
                    </button>
                    <button
                      class="btn btn-primary w-45-p"
                      @click="openAssignModuleConfirmationModal(data)"
                    >
                      {{ $t("global.assignmoduleconfirmation") }}
                    </button>
                  </div>
                </template>

                <template #head(selected)="header">
                  <b-form-checkbox
                    ref="headerCheckbox"
                    v-model="header.selected"
                    @input="selectAllRows(header)"
                  ></b-form-checkbox>
                </template>

                <template #cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- KOMPETENZNACHWEIS (FÜHRUNGSAUSBILDUNG)-->
    <zertifikat-auswahl-modal
      :id="'kompetenznachweis-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectmodule')"
      :certificateTypes="certificateTypes"
      @ok="showProofOfCompetence"
    />
    <zertifikat-auswahl-modal
      :id="'kompetenznachweis-fuer-selektierte-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectmodule')"
      :certificateTypes="certificateTypes"
      @ok="showPDFForSelected"
    />
    <zertifikat-auswahl-modal
      :id="'kompetenznachweis-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectmodule')"
      :certificateTypes="certificateTypes"
      @ok="assignProofOfCompetence"
    />
    <zertifikat-auswahl-modal
      :id="'kompetenznachweis-fuer-selektierte-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR']"
      :certificateTypesText="$t('global.selectmodule')"
      :certificateTypes="certificateTypes"
      @ok="assignPDFForSelected"
    />
    <!-- ZERTIFIKAT (GRUNDAUSBILDUNG)-->
    <zertifikat-auswahl-modal
      :id="'zertifikat-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR', 'IT']"
      @ok="showCertificate"
    />
    <zertifikat-auswahl-modal
      :id="'zertifikat-fuer-selektierte-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR', 'IT']"
      @ok="showPDFForSelected"
    />
    <zertifikat-auswahl-modal
      :id="'zertifikat-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR', 'IT']"
      @ok="assignCertificate"
    />
    <zertifikat-auswahl-modal
      :id="'zertifikat-fuer-selektierte-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR', 'IT']"
      @ok="assignPDFForSelected"
    />
    <!-- MODULBESTÄTIGUNG (WEITERBILDUNG)-->
    <zertifikat-auswahl-modal
      :id="'modulbestaetigung-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR']"
      @ok="showModuleConfirmation"
    />
    <zertifikat-auswahl-modal
      :id="'modulbestaetigung-fuer-selektierte-anzeigen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.viewpdf')"
      :languages="['DE', 'FR']"
      @ok="showPDFForSelected"
    />
    <zertifikat-auswahl-modal
      :id="'modulbestaetigung-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR']"
      @ok="assignModuleConfirmation"
    />
    <zertifikat-auswahl-modal
      :id="'modulbestaetigung-fuer-selektierte-zuweisen-modal'"
      :headerText="$t('global.configurepdf')"
      :okText="$t('global.assignpdf')"
      :languages="['DE', 'FR']"
      @ok="assignPDFForSelected"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    />
    <pdf-layer
      id="pdf-layer-multi"
      ref="pdfLayerMulti"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
      :files="pdfFiles"
    />
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";
import ZertifikatAuswahlModal from "@/components/Modals/ZertifikatAuswahlModal";

import Api from "@/Api";
import PdfGeneratorApi from "@/PdfGeneratorApi";
import { BSpinner } from "bootstrap-vue";

import server from "@/server";

import page from "@/mixins/Page";

export default {
  name: "Anwesenheitsuebersicht",
  components: {
    HeadMenu,
    Navbar,
    ZertifikatAuswahlModal,
    BSpinner,
  },

  metaInfo() {
    return {
      //TODO: Text anpassen
      titleTemplate: () => {
        return "BX: " + this.$t("global.attendanceoverview");
      },
    };
  },
  mixins: [page],
  props: {
    lehrgangid: {
      type: String,
      default: null,
    },
    bildungsgangtyp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      selectedIds: [],
      selectedObjects: [],
      selectAll: null,
      pdfPath: "",
      pdfFiles: [],
      assignCertificateError: [],
      assignCertificateSuccess: [],
      loaded: true,
      sortBy: "name",
      sortDesc: false,
      activePerson: null,
      activePersonTotalAttendance: null,
      backgroundColors: [
        "#a5dde326", // Himmelblau
        "#ffd70026", // Goldgelb
        "#b19cd926", // Lavendel
        "#f5a62326", // Pastellorange
        "#f2b6c626", // Zartes Rosa
        "#f7dc6f26", // Hellgelb
        "#AAB7B826", // Graublau
        "#FAD02E26", // Hellgelb
        "#C39BD326", // Flieder
        "#D0E6A526", // Zartes Grün
        "#D5DBDB26", // Hellgrau
        "#F3D6E426", // Zartes Rosa
      ],
      overview: [],
      participants: [],
    };
  },
  computed: {
    fields() {
      const nameField = {
        key: "name",
        label: this.$t("global.name"),
        sortable: true,
      };

      const registrationstatus = {
        key: "registrationstatus",
        label: this.$t("global.statusregistration"),
        sortable: true,
      };

      const average_attendance = {
        key: "average_attendance",
        label: this.$t("global.totalattendance"),
      };

      const certificateButtons = {
        key: "certificate",
        label: this.$t("global.certificates"),
        tdClass: "certButtons",
      };

      const selected = { key: "selected", label: "", tdClass: "selection" };

      let categoryFields = [];
      this.overview.forEach((category) => {
        categoryFields.push({
          key: category.id + ".moduleAttendance",
          label: this.$t("global.moduleattendance"),
          label_category: category.designation,
          //tdClass: "tdBackgroundModuleEvaluation",
        });
        category.subjects.forEach((subject) => {
          categoryFields.push({
            key: category.id + "." + subject.id + ".evaluation",
            label: this.$t("global.evaluation"),
            label_category: subject.designation,
            tdClass: "tdBackgroundEvaluation",
          });
          categoryFields.push({
            key: category.id + "." + subject.id + ".attendance",
            label: this.$t("global.dashboard_presence"),
            tdClass: "tdBackgroundAttendance",
          });
        });
      });

      return [
        selected,
        nameField,
        registrationstatus,
        ...categoryFields,
        average_attendance,
        certificateButtons,
      ];
    },
    items() {
      const rows = [];

      for (const participant of this.participants) {
        const row = {
          name: participant.full_name,
          registrationstatus: participant.registrationstatus,
          average_attendance: participant.average_attendance,
          gpid: participant.id,
        };

        for (const classCategory of this.overview) {
          const correspondingItem = participant.classes.find(
            (item) => item.id === classCategory.id
          );

          if (correspondingItem) {
            row[classCategory.id] = {
              moduleAttendance: correspondingItem.average_attendance,
            };

            for (const subjectCategory of classCategory.subjects) {
              const correspondingSubject = correspondingItem.subjects.find(
                (subject) => subject.id === subjectCategory.id
              );
              if (correspondingSubject) {
                row[classCategory.id][correspondingSubject.id] = {
                  dispensed: correspondingSubject.dispensed,
                  evaluation: correspondingSubject.evaluation,
                  attendance: correspondingSubject.attendance,
                };
              } else {
                row[classCategory.id][correspondingSubject.id] = null;
              }
            }
          } else {
            row[classCategory.id] = null;
          }
        }

        rows.push(row);
      }
      return rows;
    },
    certificateTypes() {
      let modules = [];
      this.overview.forEach((mod) => {
        modules.push({ bezeichnung: mod.designation, id: mod.id });
      });

      return modules;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.getAnwesenheitsuebersicht();
  },
  methods: {
    async getAnwesenheitsuebersicht() {
      this.isBusy = true;

      await Api.get("lehrgaenge/anwesenheitsuebersicht/", {
        params: {
          id: this.lehrgangid,
        },
      })
        .then((response) => {
          this.overview = response.data?.overview;
          this.participants = response.data?.participants;
          this.ladeFehler = false;
        })
        .catch((e) => {
          console.log(e);
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });

      this.styleTdElements();
    },
    abbrechen() {
      this.$router.push({
        name: "bildungsgang",
        params: { lehrgangid: this.lehrgangid, anzeige: 0 },
      });
    },
    isDispensiert(item, key) {
      const keys = key.split(".");

      if (keys[2] && keys[2] === "evaluation") {
        if (item[keys[0]][keys[1]]?.dispensed) {
          return true;
        }
      }

      return false;
    },
    isAttendance(key) {
      const keys = key.split(".");

      if (
        keys[0] === "average_attendance" ||
        (keys[1] && keys[1] === "moduleAttendance") ||
        (keys[2] && keys[2] === "attendance")
      ) {
        return true;
      }

      return false;
    },
    getBackgroundColor(index) {
      return this.backgroundColors[index] || "";
    },
    styleTdElements() {
      const table = this.$refs.selectableTable.$el;

      const tdElements = table.querySelectorAll("td");

      tdElements.forEach((td, index) => {
        // if (td.className === "tdBackgroundModuleEvaluation") {
        //   const colIndex = td.getAttribute("aria-colindex");

        //   let colorIndex = 0;

        //   //nimm anhand des Col-Indexes die nächste Farbe im backgroundColors-Array
        //   colorIndex = (colIndex - 3) / 2;

        //   if (colorIndex >= this.backgroundColors.length) {
        //     colorIndex =
        //       colorIndex -
        //       Math.floor(colorIndex / this.backgroundColors.length) *
        //         this.backgroundColors.length;
        //   }

        //   const backgroundColor = this.getBackgroundColor(colorIndex);

        //   td.style.backgroundColor = backgroundColor;
        // } else if (td.className === "tdBackgroundEvaluation") {
        if (td.className === "tdBackgroundEvaluation") {
          const colIndex = td.getAttribute("aria-colindex");

          let colorIndex = 0;

          //nimm anhand des Col-Indexes die nächste Farbe im backgroundColors-Array
          colorIndex = (colIndex - 4) / 2;

          if (colorIndex >= this.backgroundColors.length) {
            colorIndex =
              colorIndex -
              Math.floor(colorIndex / this.backgroundColors.length) *
                this.backgroundColors.length;
          }

          const backgroundColor = this.getBackgroundColor(colorIndex);

          td.style.backgroundColor = backgroundColor;
        } else if (td.className === "tdBackgroundAttendance") {
          const colIndex = td.getAttribute("aria-colindex");

          let colorIndex = 0;

          //nimm anhand des Col-Indexes die nächste Farbe im backgroundColors-Array
          colorIndex = (colIndex - 5) / 2;

          if (colorIndex >= this.backgroundColors.length) {
            colorIndex =
              colorIndex -
              Math.floor(colorIndex / this.backgroundColors.length) *
                this.backgroundColors.length;
          }

          const backgroundColor = this.getBackgroundColor(colorIndex);

          td.style.backgroundColor = backgroundColor;
        }
      });
    },
    getBackgroundColorTdClass(index) {
      const backgroundColor = this.getBackgroundColor(index);
      return {
        "background-color": backgroundColor,
      };
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((item) => {
          this.$set(item, "selected", true);
        });
        this.selectedObjects = this.$refs.selectableTable.items;
        this.selectedIds = this.$refs.selectableTable.items.map(
          (item) => item.gpid
        );
      } else {
        this.$refs.selectableTable.items.forEach((item) => {
          this.$set(item, "selected", false);
        });
        this.selectedIds = [];
        this.selectedObjects = [];
      }

      this.selectAll = Date.now();
    },
    onRowSelected() {
      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 100) return;
      }
      this.selectedObjects = this.$refs.selectableTable.items.filter(
        (item) => item.selected
      );

      this.selectedIds = this.selectedObjects.map((item) => item.gpid);
    },
    openShowProofOfCompetenceModal(data) {
      this.activePerson = data.item.gpid;
      this.$bus.$emit("open-modal", "kompetenznachweis-anzeigen-modal");
    },
    showProofOfCompetence(json) {
      const fileName =
        "Kompetenznachweis_Fuehrungsausbildung_" +
        json.certificateType?.designation?.replaceAll(" ", "_");

      const reportName = "kompetenznachweis-fausbildung-" + json.language;

      if (json.certificateType?.id) {
        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${json.certificateType.id}&ids=${this.activePerson}&date=${json.date}`;
        this.$refs.pdfLayer.show();
      }
    },
    openAssignProofOfCompetenceModal(data) {
      this.activePerson = data.item.gpid;
      this.$bus.$emit("open-modal", "kompetenznachweis-zuweisen-modal");
    },
    assignProofOfCompetence(json) {
      this.loading = true;

      const fileName =
        "Kompetenznachweis_Fuehrungsausbildung_" +
        json.certificateType?.designation?.replaceAll(" ", "_");

      const reportName = "kompetenznachweis-fausbildung-" + json.language;

      if (json.certificateType?.id) {
        const pdfPath = `/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${json.certificateType.id}&ids=${this.activePerson}&date=${json.date}`;

        PdfGeneratorApi.get(pdfPath)
          .then((response) => {
            Api.post(
              "dokument/",
              {},
              {
                params: {
                  id: this.activePerson,
                  fileID: response.data.fileID,
                  fileName: fileName + ".pdf",
                },
              }
            ).then(() => {
              this.$notify({
                type: "success",
                title: this.$t("global.actionsuccessful"),
                duration: 5000,
                text: this.$t("global.assignpdfsuccess"),
              });
            });
          })
          .catch(() => {
            this.$notify({
              type: "error",
              title: this.$t("global.actionfailed"),
              duration: 5000,
              text: this.$t("global.assignpdferror"),
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    openShowCertificateModal(data) {
      this.activePerson = data.item.gpid;
      this.activePersonTotalAttendance = data.item.average_attendance;

      this.$bus.$emit("open-modal", "zertifikat-anzeigen-modal");
    },
    showCertificate(json) {
      const reportName = "zertifikat-grundausbildung-" + json.language;
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Zertifikat_Grundausbildung.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&date=${json.date}&ids=${this.activePerson}&auftragID=${this.activePersonTotalAttendance}`;
      this.$refs.pdfLayer.show();
    },
    openAssignCertificateModal(data) {
      this.activePerson = data.item.gpid;
      this.activePersonTotalAttendance = data.item.average_attendance;

      this.$bus.$emit("open-modal", "zertifikat-zuweisen-modal");
    },

    assignCertificate(json) {
      this.loading = true;

      const reportName = "zertifikat-grundausbildung-" + json.language;

      const pdfPath = `/pdfcache.act?filename=Zertifikat_Grundausbildung.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&date=${json.date}&ids=${this.activePerson}&auftragID=${this.activePersonTotalAttendance}`;

      PdfGeneratorApi.get(pdfPath)
        .then((response) => {
          Api.post(
            "dokument/",
            {},
            {
              params: {
                id: this.activePerson,
                fileID: response.data.fileID,
                fileName: "Zertifikat_Grundausbildung.pdf",
              },
            }
          ).then(() => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              duration: 5000,
              text: this.$t("global.assignpdfsuccess"),
            });
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            duration: 5000,
            text: this.$t("global.assignpdferror"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openShowModuleConfirmationModal(data) {
      this.activePerson = data.item.gpid;
      this.$bus.$emit("open-modal", "modulbestaetigung-anzeigen-modal");
    },
    showModuleConfirmation(json) {
      //Modulbestätigung = Kursbestätigung
      const reportName = "modulbestaetigung-" + json.language;

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=Kursbestaetigung_Weiterbildung.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&ids=${this.activePerson}&date=${json.date}`;
      this.$refs.pdfLayer.show();
    },
    openAssignModuleConfirmationModal(data) {
      this.activePerson = data.item.gpid;
      this.$bus.$emit("open-modal", "modulbestaetigung-zuweisen-modal");
    },
    assignModuleConfirmation(json) {
      this.loading = true;

      const reportName = "modulbestaetigung-" + json.language;

      const pdfPath = `/pdfcache.act?filename=Kursbestaetigung_Weiterbildung.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&ids=${this.activePerson}&date=${json.date}`;

      PdfGeneratorApi.get(pdfPath)
        .then((response) => {
          Api.post(
            "dokument/",
            {},
            {
              params: {
                id: this.activePerson,
                fileID: response.data.fileID,
                fileName: "Kursbestaetigung_Weiterbildung.pdf",
              },
            }
          ).then(() => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              duration: 5000,
              text: this.$t("global.assignpdfsuccess"),
            });
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            title: this.$t("global.actionfailed"),
            duration: 5000,
            text: this.$t("global.assignpdferror"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openShowPDFForSelectedModal() {
      if (this.bildungsgangtyp === "187EB127389") {
        //Führungsausbildung
        this.$bus.$emit(
          "open-modal",
          "kompetenznachweis-fuer-selektierte-anzeigen-modal"
        );
      } else if (this.bildungsgangtyp === "187EB11D0E7") {
        //Grundausbildung
        this.$bus.$emit(
          "open-modal",
          "zertifikat-fuer-selektierte-anzeigen-modal"
        );
      } else {
        //Weiterbildung
        this.$bus.$emit(
          "open-modal",
          "modulbestaetigung-fuer-selektierte-anzeigen-modal"
        );
      }
    },
    openAssignPDFForSelectedModal() {
      if (this.bildungsgangtyp === "187EB127389") {
        //Führungsausbildung
        this.$bus.$emit(
          "open-modal",
          "kompetenznachweis-fuer-selektierte-zuweisen-modal"
        );
      } else if (this.bildungsgangtyp === "187EB11D0E7") {
        //Grundausbildung
        this.$bus.$emit(
          "open-modal",
          "zertifikat-fuer-selektierte-zuweisen-modal"
        );
      } else {
        //Weiterbildung
        this.$bus.$emit(
          "open-modal",
          "modulbestaetigung-fuer-selektierte-zuweisen-modal"
        );
      }
    },
    async showPDFForSelected(json) {
      await this.selectedObjects.forEach((person, index) => {
        //Generiere das PDF für die ausgewählten Personen und füge es dem PDF-Layer hinzu.
        this.generatePDFforSelectedView(person, index, json);
      });
      //Zeige den PDF-Layer mit den hinzugefügten Files, sobald alle PDFs generiert wurden.
      this.$refs.pdfLayerMulti.show();
    },
    generatePDFforSelectedView(person, index, certificateData) {
      this.pdfFiles = [];
      let kursname = "";
      if (certificateData.certificateType?.bezeichnung) {
        kursname = certificateData.certificateType?.bezeichnung?.replaceAll(
          " ",
          "_"
        );
      }
      let date = certificateData.date;
      let language = certificateData.language;
      let pdfAnzeigename = person.name;

      let reportName = "";
      let certificateName = "";
      let pdfPath = "";

      let fileName = "";
      switch (this.bildungsgangtyp) {
        case "187EB127389": //Führungsausbildung
          reportName = "kompetenznachweis-fausbildung-" + language;
          switch (language) {
            case "FR":
              certificateName = "Certificat_de_module";
              break;
            case "IT":
              certificateName = "Certificato_di_modulo";
              break;
            case "EN":
              certificateName = "Module_Certificate";
              break;
            default:
              certificateName = "Modulabschluss_";
          }
          fileName =
            certificateName + kursname + "_" + person.name.replaceAll(" ", "_");
          pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${certificateData.certificateType.id}&ids=${person.gpid}&date=${date}`;
          break;
        case "187EB11D0E7": //Grundausbildung
          reportName = "zertifikat-grundausbildung-" + language;

          switch (language) {
            case "FR":
              certificateName = "Attestation_de_reussite_de_la_formation";
              break;
            case "IT":
              certificateName = "Certificato_di_qualifica_della_formazione";
              break;
            case "EN":
              certificateName = "Confirmation_of_Course_Completion";
              break;
            default:
              certificateName = "Bestaetigung_Lehrgangsabschluss";
          }
          fileName =
            certificateName + kursname + "_" + person.name.replaceAll(" ", "_");
          pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&ids=${person.gpid}&date=${date}&auftragID=${person.average_attendance}`;
          break;
        default: //Weiterbildung
          reportName = "modulbestaetigung-" + language;

          switch (language) {
            case "FR":
              certificateName = "Attestation";
              break;
            case "IT":
              certificateName = "Attestazione";
              break;
            case "EN":
              certificateName = "Attestation";
              break;
            default:
              certificateName = "Kursbestaetigung";
          }
          fileName =
            certificateName + kursname + "_" + person.name.replaceAll(" ", "_");
          pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&ids=${person.gpid}&date=${date}`;
          break;
      }

      Api.get(pdfPath).then((response) => {
        let retValue = {
          index: index,
          url: server.url + response.data.filepath,
          withCredentials: true,
          count: this.selectedIds.length,
          size: pdfAnzeigename, //Variable wurde im Backend 'size' benannt, wird aber für den Anzeigenamen im PDF-Viewer genutzt
        };

        this.pdfFiles.push(retValue);
      });
    },
    async assignPDFForSelected(json) {
      this.loading = true;

      await this.selectedObjects.forEach((person) => {
        //Generiere das PDF für die ausgewählten Personen und verknüpfe es mit der Person.
        this.generatePDFforSelectedAssign(person, json);
      });

      this.loading = false;

      //Zeige Erfolgs- oder Fehlermeldungen
      if (this.assignCertificateSuccess.length > 0) {
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          duration: 10000,
          text:
            this.$t("global.assigncertificatessuccess") +
            `: (${this.assignCertificateSuccess.length})`,
        });
      }
      if (this.assignCertificateError.length > 0) {
        this.$notify({
          type: "error",
          title: this.$t("global.actionfailed"),
          duration: 10000,
          text:
            this.$t("global.assigncertificateserror") +
            `: ${this.assignCertificateError.toString()}`,
        });
      }
    },

    generatePDFforSelectedAssign(person, certificateData) {
      this.pdfFiles = [];
      let kursname = "";
      if (certificateData.certificateType?.bezeichnung) {
        kursname = certificateData.certificateType?.bezeichnung?.replaceAll(
          " ",
          "_"
        );
      }
      let date = certificateData.date;
      let language = certificateData.language;

      let reportName = "";
      let certificateName = "";
      let pdfPath = "";
      switch (this.bildungsgangtyp) {
        case "187EB127389": //Führungsausbildung
          reportName = "kompetenznachweis-fausbildung-" + language;
          pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${certificateData.certificateType.id}&ids=${person.gpid}&date=${date}`;
          switch (language) {
            case "FR":
              certificateName = "Certificat_de_module";
              break;
            case "IT":
              certificateName = "Certificato_di_modulo";
              break;
            case "EN":
              certificateName = "Module_Certificate";
              break;
            default:
              certificateName = "Modulabschluss_";
          }
          break;
        case "187EB11D0E7": //Grundausbildung
          reportName = "zertifikat-grundausbildung-" + language;
          pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&ids=${person.gpid}&date=${date}&auftragID=${person.average_attendance}`;
          switch (language) {
            case "FR":
              certificateName = "Attestation_de_reussite_de_la_formation";
              break;
            case "IT":
              certificateName = "Certificato_di_qualifica_della_formazione";
              break;
            case "EN":
              certificateName = "Confirmation_of_Course_Completion";
              break;
            default:
              certificateName = "Bestaetigung_Lehrgangsabschluss";
          }
          break;
        default: //Weiterbildung
          reportName = "modulbestaetigung-" + language;
          pdfPath = `pdfgenerator/pdfcache.act?filename=${fileName}.pdf&report=/pdfgenerator/${reportName}.htm&sgid=${this.lehrgangid}&ids=${person.gpid}&date=${date}`;
          switch (language) {
            case "FR":
              certificateName = "Attestation";
              break;
            case "IT":
              certificateName = "Attestazione";
              break;
            case "EN":
              certificateName = "Attestation";
              break;
            default:
              certificateName = "Kursbestaetigung";
          }
          break;
      }

      const fileName =
        certificateName + kursname + "_" + person.name.replaceAll(" ", "_");

      let error = false;
      //Speichere das PDF als Dokument und verknüpfe es mit der entsprechenden Person.
      Api.get(pdfPath)
        .then((response) => {
          Api.post(
            "dokument/",
            {},
            {
              params: {
                id: person.gpid,
                fileID: response.data.fileID,
                fileName: fileName + ".pdf",
              },
            }
          ).then(() => {
            error = false;
          });
        })
        .catch(() => {
          error = true;
        });

      //Arrays müssen so befüllt werden, da ansonsten die reactivity nicht greift
      if (error) {
        this.assignCertificateError.push(person.name);
      } else {
        this.assignCertificateSuccess.push(person.name);
      }
    },
  },
};
</script>

<style lang="scss">
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .table
  > tbody
  > tr.b-table-row-selected {
  filter: brightness(95%);
  /*position: relative;*/
  z-index: 2;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .table
  > thead
  > tr
  > th[aria-sort="ascending"],
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .table
  > thead
  > tr
  > th[aria-sort="descending"] {
  background-color: #a7c1cc;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle .table > thead > tr > th {
  cursor: pointer;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .table
  > thead
  > tr
  > th:first-child {
  min-width: 31px;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .table
  > thead
  > tr
  > th:nth-child(6) {
  min-width: 45px;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .b-table-sticky-header
  > .table.b-table
  > thead
  > tr
  > th,
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .b-table-sticky-header
  > .table.b-table
  > thead
  > tr,
.lehrgangsuebergreifendeNotenuebersichtsTabelle
  .b-table-sticky-header
  > .table.b-table
  > thead {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 10;
  background: #b5cfdc;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle .b-table-sticky-header {
  scrollbar-width: thin;
  scrollbar-color: #b5cfdc #f5fbff;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle .table {
  width: auto !important;
  min-width: 100%;
  overflow-x: scroll;
}
.lehrgangsuebergreifendeNotenuebersichtsTabelle .table td {
  min-width: 150px;
}

.lehrgangsuebergreifendeNotenuebersichtsTabelle .table td.certButtons {
  min-width: 500px;
}

.lehrgangsuebergreifendeNotenuebersichtsTabelle .table td.selection {
  min-width: 50px;
}
</style>
