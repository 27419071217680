<template>
  <div class="col-xl-12 block br-t-l-0">
    <div class="eingabe-panel-kopf p-3 timeblockPanel" ref="timeblockPanel">
      <div class="row">
        <div class="col-xl-12">
          <button
            class="btn btn-primary btn-sm"
            @click="zeitblockHinzufuegen()"
            :disabled="!editable"
          >
            <font-awesome-icon icon="fa-regular fa-plus" class="mr-1" />
            {{ $t("global.addTimeBlock") }}
          </button>
          <button
            class="btn btn-primary btn-sm ml-2"
            @click="ganzerTag()"
            :disabled="!editable"
          >
            <font-awesome-icon icon="fa-regular fa-plus" class="mr-1" />
            {{ $t("global.addTimeBlockDay") }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8">
          <div class="form-group">
            <b-table
              ref="timeblockTable"
              tbody-tr-class="item"
              show-empty
              :items="zeitbloecke"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              sort-icon-left
              borderless
            >
              <template
                #cell(startzeit)="row"
                style="padding: 0"
                class="d-flex align-items-center"
              >
                <time-field
                  placeholder="HH:MM"
                  :noForm="true"
                  :initial="row.item.startzeit"
                  @update="(val) => (row.item.startzeit = val)"
                />
              </template>

              <template #cell(endzeit)="row">
                <time-field
                  :noForm="true"
                  placeholder="HH:MM"
                  :initial="row.item.endzeit"
                  @update="(val) => (row.item.endzeit = val)"
                />
              </template>

              <template #cell(stunden)="row">
                <div class="text-center">
                  {{ isNaN(row.item.stunden) ? "-" : row.item.stunden }}
                </div>
              </template>

              <template #cell(delete)="row">
                <button
                  class="btn btn-danger btn-sm"
                  @click="zeitblockLoeschen(row.item)"
                  style="height: 33px"
                  :disabled="!editable"
                >
                  <font-awesome-icon
                    icon="fa-duotone fa-trash"
                    class="mr-1 pl-1"
                  />
                </button>
              </template>

              <template #empty>
                <div>
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>

              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width:
                      field.key === 'delete' || field.key === 'stunden'
                        ? '20%'
                        : '30%',
                  }"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import reiter from "@/mixins/Reiter";
import { v4 as uuid } from "uuid";
import { parseDateTimeString, round } from "@/utils/bxchTools.js";
import TimeField from "@/components/TimeField";

export default {
  name: "Zeitblockliste",
  components: { TimeField },
  mixins: [reiter],
  props: {
    timesheet: {
      type: Object,
      required: true,
    },
    zeitbloecke: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      zeitkonto: {},
      fields: [
        {
          key: "startzeit",
          label: this.$t("global.starttime"),
          sortable: true,
          tdClass: "p-0 pb-1 pr-1",
        },
        {
          key: "endzeit",
          label: this.$t("global.endtime"),
          sortable: true,
          tdClass: "p-0 pb-1 pr-1",
        },
        {
          key: "stunden",
          label: this.$t("global.hours"),
          sortable: true,
          tdClass: "p-0 pb-1 pr-1",
        },
        {
          key: "delete",
          sortable: false,
          label: this.$t(""),
          tdClass: "p-0 pb-1 pr-1",
        },
      ],
    };
  },
  watch: {
    zeitbloecke: {
      deep: true,
      handler() {
        this.checkZeitBloecke();
      },
    },
  },
  computed: {},
  mounted() {
    this.setHeaders("global", ["starttime", "endtime", "hours", ""]);

    this.getZeitKonto();

    this.$bus.$on("mitarbeiter-changed", () => {
      this.getZeitKonto();
    });
  },
  created() {},
  methods: {
    getZeitKonto() {
      let now = new Date();
      now =
        now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear();
      Api.get("zeiterfassung/account/", {
        params: {
          date: now,
          ma: this.timesheet.mitarbeiter?.id,
        },
      }).then((response) => {
        /*  wählt das Zeitkonto des aktuellen Mitarbeiters auch nach refresh */
        this.zeitkonto = response.data.find((zeitkonto) => {
          return zeitkonto.mitarbeiter.id === this.timesheet.mitarbeiter?.id;
        });
      });
    },

    //Zeitblock für vollständigen Arbeitstag abhängig von Stellenprozenten ausfüllen
    ganzerTag() {
      let workdayhours = this.$CONST("CONFIG").WORKWEEKHOURS / 5; // bei 100% --> 8h, bei 80% --> 6.4h

      let effectiveHours = workdayhours * (this.zeitkonto.stellenprozent / 100);
      let hours = Math.floor(effectiveHours);
      let minutes = effectiveHours > hours ? 60 * (effectiveHours - hours) : 0;

      let now = new Date();
      now.setMinutes(minutes);
      now.setHours(8 + hours);

      // push den eben berechneten Zeitblock in Zeitblöcke-Array
      this.zeitbloecke.push({
        startdatum: this.timesheet.datum,
        startzeit: "08:00",
        enddatum: this.timesheet.datum,
        endzeit: now.toLocaleTimeString("ch-DE", {
          hour: "2-digit",
          minute: "2-digit",
        }),
        stunden: hours,
        id: uuid(),
      });
    },

    // push Zeitblock für gewähltes Datum in Zeitblöcke-Array
    zeitblockHinzufuegen() {
      this.zeitbloecke.push({
        startdatum: this.timesheet.datum,
        startzeit: null,
        enddatum: this.timesheet.datum,
        endzeit: null,
        stunden: 0,
        id: uuid(),
      });
    },

    // lösche übergebenen Zeitblock
    zeitblockLoeschen(item) {
      if (item.id) {
        this.zeitbloecke.splice(
          this.zeitbloecke.findIndex((el) => {
            return el.id === item.id;
          }),
          1
        );
      } else {
        console.log("noID");
      }
    },

    // Zeiteingaben definieren und Differenzberechnen
    checkZeitBloecke() {
      this.zeitbloecke.forEach((zeitblock) => {
        let startDatum = parseDateTimeString(
          zeitblock.startdatum,
          zeitblock.startzeit
        );
        let endDatum = parseDateTimeString(
          zeitblock.enddatum,
          zeitblock.endzeit
        );
        let timeDiff = endDatum - startDatum;
        zeitblock.stunden = round(timeDiff / 1000 / 60 / 60, 2); // Anzahl Stunden im jeweiligen Zeitblock
      });
    },

    timeEntry() {
      this.$bus.$emit("timeEntry", this.zeitbloecke);
    },
  },
};
</script>
