import axios from "axios";
import router from "./Router";

let kotlinAppApiConfig = {
  baseURL: process.env.VUE_APP_KOTLIN_API_URL,
  defaultURL: process.env.VUE_APP_SERVER_URL,
  withCredentials: true,
};

const KotlinAppApi = axios.create(kotlinAppApiConfig);

KotlinAppApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          router.push({ path: "/error/400" });
          break;
        case 401:
          if (
            router.currentRoute.name !== "login" &&
            router.currentRoute.name !== "leitsystem" &&
            router.currentRoute.name !== "resetpassword" &&
            router.currentRoute.name !== "newpassword"
          )
            router.push({ name: "login" });
          break;
        case 402:
          router.push({ path: "/error/402" });
          break;
        case 403:
          router.push({ path: "/error/403" });
          break;
        case 404:
          router.push({ path: "/error/404" });
          break;
        case 405:
          router.push({ path: "/error/405" });
          break;
        /* case 409:
         router.push({ path: "/error/409" });
         break;*/
      }
    }

    /*
    if (error.response.status === 401) {
      if (
        router.currentRoute.name !== "login" &&
        router.currentRoute.name !== "leitsystem"
      )
        router.push({ name: "login" });
    }
    if (error.response.status === 403) {
      console.log("ERROR 403: FORBIDDEN");
    }

    return Promise.reject(error);*/
  }
);

export default KotlinAppApi;
