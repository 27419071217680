<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.timeaccount')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.timeaccount')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <zeitkonto-angaben :id="id" :editable="editable" />
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import store from "@/store";

import page from "@/mixins/Page";
import ZeitkontoAngaben from "@/components/Zeiterfassung/Zeitkontoangaben";
export default {
  name: "Zeitkonto",
  components: {
    Navbar,
    ZeitkontoAngaben,
    HeadMenu,
  },
  mixins: [page],
  store,
  data() {
    return {
      newZeitkonto: {},
    };
  },

  methods: {
    createZeitkonto() {
      const json = this.newZeitkonto;
      Api.post("zeiterfassung/account/", json);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
