<template>
    <div class="form-group">
      <span v-if="editable" class="editIcon" v-on:click="oeffneLGSModal"
        ><font-awesome-icon icon="fa-duotone fa-pencil-alt"
      /></span>
      <span v-if="allowOpen" class="openIcon" v-on:click="openLehrgang"
        ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
      /></span>
      <ValidationProvider
        :rules="required ? 'required' : ''"
        immediate
        v-slot="{ passed }"
        ><input v-model="lehrgang.id" type="hidden" />
        <b-form-input
          v-model="lehrgangTitel"
          v-bind:class="{ 'border-danger': !passed }"
          readonly
          placeholder=" "
        />
        <label v-bind:class="{ 'text-danger': !passed }">{{ label }}</label>
      </ValidationProvider>
  
      <lehrgang-select-modal
        :id="modalId"
        :filterTypen="typen"
        :filterStati="filterStati"
        :filterLehrgang="lehrgang"
        @confirmed="onLGSResult"
        remove
      ></lehrgang-select-modal>
    </div>
  </template>
  
  <script>
  import LehrgangSelectModal from "@/components/Modals/LehrgangSelectModal.vue";
  import { v4 as uuid } from "uuid";
  
  export default {
    name: "SingleSelectLehrgang",
    components: { LehrgangSelectModal },
    props: {
      id: {
        type: String,
        default: uuid(),
      },
      lehrgang: {
        type: Object,
        required: false,
        default: function () {
          return { lehrgangTitel: this.$t("global.notlinked") };
        },
      },
      allowOpen: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
        required: true,
      },
      typen: {
        type: Array,
        required: false,
      },
      editable: {
        type: Boolean,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      filterStati: {
        type: Array,
        required: false,
      },
    },
    data() {
      return {
        editLehrgang: null,
      };
    },
    computed: {
      modalId() {
        return "lehrgang-modal-" + this.id;
      },
      lehrgangTitel: function () {
        if (!this.lehrgang || !this.lehrgang.id)
          return this.$t("global.notlinked");
  
        return (
          (this.lehrgang.kuerzel ? this.lehrgang.kuerzel + " / " : "") +
          this.lehrgang.bezeichnung
        );
      },
    },
    mounted: function () {},
    methods: {
      oeffneLGSModal() {
        this.$bus.$emit("sucheModalOpen");
        $("#" + this.modalId).modal("show");
      },
  
      onLGSResult(selectedLehrgang) {
        if (selectedLehrgang) {
          this.$emit("confirmed", selectedLehrgang[0]);
        }
      },
  
      openLehrgang() {
        if (this.lehrgang.id) return;  
        
        this.$router.push({
            name: "lehrgang",
            params: { lehrgangid: this.lehrgang.id, anzeige: 0 },
          });
        } /* else {
          console.log(typ.id);
          console.log("Veranstaltungstyp nicht identifiziert");
        } */
      },
  };
  </script>
  