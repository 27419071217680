<template>
  <div>
    <div id="projekterfassung-modal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">
              {{ $t("global.projectentry") }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="projekterfassung.projekt"
                  :options="projekte"
                  label="projektName"
                  :placeholder="$t('global.project')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.project") }}</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="projekterfassung.projektaufgabe"
                  :options="projekterfassung.projekt.aufgaben"
                  label="bezeichnung"
                  :placeholder="$t('global.projecttask')"
                >
                  <span slot="no-options">{{
                    $t("notification.taptosearch")
                  }}</span>
                </v-select>
                <label>{{ $t("global.projecttask") }}</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="projekterfassung.stunden"
                  @change="notifyIfError"
                  class="form-control"
                />
                <label>{{ $t("global.hours") }}</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model="projekterfassung.bemerkung"
                  placeholder=""
                  rows="3"
                  class="form-control"
                ></textarea>
                <label>{{ $t("global.remarks") }}</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="isValid"
              class="btn btn-primary"
              @click="uebernehmen"
            >
              {{ $t("global.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { LADE_AUSWAHLWERTE_ZEITERFASSUNG } from "@/store/zeiterfassung/actions.type";

export default {
  name: "ProjekterfassungModal",
  components: { ModalCloseButton },
  props: {
    arbeitszeit: {
      type: Number,
      required: true,
    },
    rapportierteStunden: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      projekterfassung: {
        stunden: null, // wird aktuell als String gespeichert
        timesheet: null,
        id: null,
        bemerkung: "",
        projekt: { projektName: "", aufgaben: [{ bezeichnung: "" }] },
        projektaufgabe: { bezeichnung: "" },
      },
      stundenVorUpdate: null,
      shown: false,
    };
  },
  computed: {
    // Wenn aufgabe erfasst, die Stunden mehr als 0 sind, und die gesamte rapportierte Zeit die Arbeitszeit nicht übersteigt, dann false bzw. "übernehmen" enabled
    isValid: function () {
      if (
        this.projekterfassung.projektaufgabe?.id &&
        parseFloat(this.projekterfassung.stunden) > 0 &&
        this.rapportierteStunden +
          (parseFloat(this.projekterfassung.stunden) - this.stundenVorUpdate) <=
          this.arbeitszeit
      )
        return false;
      else return true;
    },
    projekte: {
      get() {
        return this.$store.state.zeiterfassung.projekte;
      },
    },
  },
  watch: {},
  created() {},
  mounted() {
    // mit Props lösen statt emits?
    this.$bus.$on("projekterfassung-modal", (data) => {
      if (!this.shown) {
        if (data) {
          if (data.projekterfassung)
            this.projekterfassung = Object.assign({}, data.projekterfassung);

          this.projekterfassung.timesheet = data.timesheet;
        }
        this.stundenVorUpdate = this.projekterfassung.stunden; // setzt die Stunden aus dem GET einmal vor dem öffnen des Modals

        this.openModal();
      }
    });

    if (this.projekte.length == 0)
      this.$store.dispatch(`zeiterfassung/${LADE_AUSWAHLWERTE_ZEITERFASSUNG}`);
  },
  methods: {
    //wird onchange des Projekterfassungs-Inputs überprüft
    notifyIfError() {
      if (
        this.projekterfassung.projektaufgabe?.id &&
        parseFloat(this.projekterfassung.stunden) > 0 &&
        this.rapportierteStunden +
          (parseFloat(this.projekterfassung.stunden) - this.stundenVorUpdate) >
          this.arbeitszeit
      ) {
        this.$notify({
          type: "error",
          title: this.$t("notification.actionunsuccessful"),
          text: "Mehr rapportierte Stunden als Arbeitszeit!",
        });
      }
    },

    openModal() {
      this.shown = true;

      $("#projekterfassung-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      this.$emit("abbrechen");
      $("#projekterfassung-modal").modal("hide");
      this.projekterfassung = {
        stunden: null,
        timesheet: null,
        id: null,
        projekt: { projektName: "", aufgaben: [{ bezeichnung: "" }] },
        projektaufgabe: { bezeichnung: "" },
      };
    },

    uebernehmen() {
      this.$emit("uebernehmeProjekt", this.projekterfassung);
      this.closeModal();
    },
  },
};
</script>

<style>
</style>
