<template>
  <div class="w-100-p overflow-auto max-h-70-vh styled-scrollbar">
    <b-table
      ref="dubletteTable"
      small
      hover
      selectable
      tbody-tr-class="item"
      select-mode="range"
      :items="tableData"
      :fields="fields"
    >
      <template v-slot:thead-top="row">
        <td
          class="border-none"
          v-for="(field, index) in row.fields"
          :key="'head-row-' + field.key"
        >
          <div
            v-if="index === 0"
            class="btn btn-success"
            @click="dublettenZusammenfuehren"
          >
            {{ $t("global.mergeduplicates") }}
          </div>
          <div
            v-if="index > 1 && !eintrag.duplicates[index - 2].locked"
            class="btn btn-outline-secondary"
            @click="sperreEintrag(field.key, index)"
            v-tooltip.hover
            :title="$t('global.entrytooltiptext')"
          >
            <font-awesome-icon class="mr-2" icon="fa-solid fa-unlock" />
            <span>{{ $t("global.lockentry") }}</span>
          </div>
          <div
            v-if="index > 1 && eintrag.duplicates[index - 2].locked"
            class="btn btn-outline-danger"
            @click="entsperreEintrag(field.key, index)"
            v-tooltip.hover
            :title="$t('global.entrytooltiptext')"
          >
            <font-awesome-icon class="mr-2" icon="fa-solid fa-lock" />
            <span>{{ $t("global.entrylocked") }}</span>
          </div>
        </td>
      </template>

      <template v-slot:top-row="{ fields }">
        <td v-for="(field, index) in fields" :key="'top-row-' + index">
          <div v-if="field.label != ''" class="d-flex align-items-center">
            <button
              class="btn btn-outline-primary btn-sm"
              :ref="`button-${field.key}`"
              @click="setAllRadioButtonsOfColumn(field.key)"
            >
              {{ $t("global.selectall") }}
            </button>
          </div>
        </td>
      </template>

      <template v-slot:cell()="cell">
        <div
          v-if="
            typeof cell.value === 'object' &&
            ('street_nr' in cell.value ||
              'zip' in cell.value ||
              'city' in cell.value)
          "
          class="d-flex align-items-center"
        >
          <input
            v-if="cell.field.key != 'description'"
            class="mr-2"
            type="radio"
            @click="
              setMasterValue(
                cell.field.key,
                cell.index,
                cell.field.duplicateIndex
              )
            "
            :data-cellkey="cell.field.key"
            :data-cellindex="cell.index"
            :data-cellduplicateindex="cell.field.duplicateIndex"
            :ref="`radio-${cell.field.key}-${cell.index}`"
            :name="'radio-' + cell.index"
            :value="cell.value"
          />
          <div
            class="mr-2"
            v-if="cell.value.street_nr || cell.value.zip || cell.value.city"
          >
            {{ cell.value.street_nr ? cell.value.street_nr : ""
            }}{{ cell.value.zip ? ", " + cell.value.zip + " " : "" }}
            {{ cell.value.city ? cell.value.city : "" }}
          </div>
          <div v-else>-</div>
        </div>
        <div v-else class="d-flex align-items-center">
          <input
            v-if="cell.field.key != 'description'"
            class="mr-2"
            type="radio"
            @click="
              setMasterValue(
                cell.field.key,
                cell.index,
                cell.field.duplicateIndex
              )
            "
            :data-cellkey="cell.field.key"
            :data-cellindex="cell.index"
            :data-cellduplicateindex="cell.field.duplicateIndex"
            :ref="`radio-${cell.field.key}-${cell.index}`"
            :name="'radio-' + cell.index"
            :value="cell.value"
          />
          <div>{{ cell.value ? cell.value : "-" }}</div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import CrmApi from "@/CrmApi";

export default {
  name: "DublettenEintrag",
  components: {},
  mixins: [],
  props: {
    eintrag: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tableData: [],
      master: {},
    };
  },
  created() {
    this.initializeMasterObject();
  },
  mounted() {
    this.initializeTableData();

    this.$nextTick(() => {
      this.setAllRadioButtonsOfColumn("master");
    });
  },
  computed: {
    fields() {
      //Fields erweitern, je nach anzahl der gefundenen Duplikate
      let fields = [
        {
          key: "description",
          sortable: false,
          label: "",
        },
        {
          key: "master",
          sortable: false,
          label: this.$t("global.master"),
        },
      ];

      this.eintrag.duplicates.forEach((duplicate, index) => {
        fields.push({
          key: `eintrag-${index}`,
          sortable: false,
          label: `${this.$t("global.entry2")} ${index + 1}`,
          duplicateIndex: index,
        });
      });

      return fields;
    },
    fieldKeys() {
      //wird benötigt, um die TableData korrekt aufzubauen
      return [
        "person_number",
        "last_name",
        "first_name",
        "academic_title.designation",
        "birthday",
        "gender.designation",
        "salutation.designation",
        "status.designation",
        "civil_status.designation",
        "civil_status_date",
        "workload",
        "function",
        "tel_g",
        "tel_p",
        "tel_m",
        "mail_main.designation",
        "mail_secondary.designation",
        "billing_adress",
        "correspondence_adress",
        "ssecurity_nr",
        "occupation",
        "city_of_origin",
        "city_of_residence",
        "first_profession",
        "educating_company",
        "joining_date",
        "leaving_date",
        "comment",
        "current_occupation",
        "allow_picture",
        "deficit_compensation",
        "nationality.designation",
        "residency_permit.designation",
        "residence_valid_till",
        "subsidy_canton.designation",
        "surdossier",
        "mothertongue.designation",
        "correspondence_language.designation",
        "name_surname_chief",
        "name_surname_coach",
        "leadfunction",
        "direct_superior",
        "amount_working_years",
        "starting_date_jv",
        "criminal_record.designation",
        "employment.designation",
      ];
    },
    fieldObjects() {
      //wird benötigt, um die neuen Werte beim Klick auf einen Radio-Button dem Master-Objekt korrekt zuzuweisen
      return [
        "person_number",
        "last_name",
        "first_name",
        "academic_title",
        "birthday",
        "gender",
        "salutation",
        "status",
        "civil_status",
        "civil_status_date",
        "workload",
        "function",
        "tel_g",
        "tel_p",
        "tel_m",
        "mail_main",
        "mail_secondary",
        "billing_adress",
        "correspondence_adress",
        "ssecurity_nr",
        "occupation",
        "city_of_origin",
        "city_of_residence",
        "first_profession",
        "educating_company",
        "joining_date",
        "leaving_date",
        "comment",
        "current_occupation",
        "allow_picture",
        "deficit_compensation",
        "nationality",
        "residency_permit",
        "residence_valid_till",
        "subsidy_canton",
        "surdossier",
        "mothertongue",
        "correspondence_language",
        "name_surname_chief",
        "name_surname_coach",
        "leadfunction",
        "direct_superior",
        "amount_working_years",
        "starting_date_jv",
        "criminal_record",
        "employment",
      ];
    },
    //Key-Werte für die Spalte ganz links
    fieldKeysDescription() {
      return {
        person_number: this.$t("global.bpartnernum"),
        last_name: this.$t("global.surname"),
        first_name: this.$t("global.firstname"),
        academic_title: { designation: this.$t("global.academictitle") },
        birthday: this.$t("global.dateofbirth"),
        gender: { designation: this.$t("global.gender") },
        salutation: { designation: this.$t("global.salutation") },
        status: { designation: this.$t("global.status") },
        civil_status: { designation: this.$t("global.civilstatus") },
        civil_status_date: this.$t("global.dateofcivilstatus"),
        workload: this.$t("global.workload"),
        function: this.$t("global.function"),
        tel_g: this.$t("global.businessphone"),
        tel_p: this.$t("global.privatephone"),
        tel_m: this.$t("global.mobilephone"),
        mail_main: { designation: this.$t("global.mailmain") },
        mail_secondary: { designation: this.$t("global.mailsecondary") },
        billing_adress: {
          street_nr: this.$t("global.invoiceaddress"),
          zip: "",
          city: "",
        },
        correspondence_adress: {
          street_nr: this.$t("global.correspondenceaddress"),
          zip: "",
          city: "",
        },
        // ahv_number: this.$t("global.ahv_number"),
        ssecurity_nr: this.$t("global.socialsecuritynumber"),
        occupation: this.$t("global.professioneducation"),
        city_of_origin: this.$t("global.hometown"),
        city_of_residence: this.$t("global.residence"),
        first_profession: this.$t("global.apprenticeship"),
        educating_company: this.$t("global.Company"),
        joining_date: this.$t("global.entry"),
        leaving_date: this.$t("global.exit"),
        comment: this.$t("global.giftexit"),
        current_occupation: this.$t("global.currentactivity"),
        // publish: this.$t("global.publish"),
        allow_picture: this.$t("global.photoconsentform"),
        // publish_details: this.$t("global.publish_details"),
        deficit_compensation: this.$t("global.disadvantagecompensation"),
        nationality: { designation: this.$t("global.nationality") },
        residency_permit: { designation: this.$t("global.residencepermit") },
        residence_valid_till:
          this.$t("global.residencepermit") +
          " " +
          this.$t("global.validuntil"),
        subsidy_canton: { designation: this.$t("global.subsidycanton") },
        surdossier: this.$t("global.surdossier"),
        mothertongue: { designation: this.$t("global.firstlanguage") },
        correspondence_language: {
          designation: this.$t("global.correspondencelanguage"),
        },
        name_surname_chief: this.$t("global.nameofficemanagement"),
        name_surname_coach: this.$t("global.nameparcticecoach"),
        leadfunction: this.$t("global.managementfunction"),
        direct_superior: this.$t("global.directsupervisor"),
        amount_working_years: this.$t("global.yearsofworkexperience"),
        starting_date_jv: this.$t("global.startofemployment"),
        criminal_record: {
          designation: this.$t("global.criminalrecordextract"),
        },
        employment: { designation: this.$t("global.employment") },
      };
    },
  },
  methods: {
    initializeTableData() {
      //TableData aufbereiten, sodass pro Spalte alle Werte eines Eintrags aufgelistet werden (Keys, Master, Eintrag 1, usw.)
      this.tableData = [];

      this.fieldKeys.forEach((fieldKey, index) => {
        let row = {};
        row.description = this.getObjectValue(
          this.fieldKeysDescription,
          fieldKey
        );
        row.master = this.getObjectValue(this.eintrag, fieldKey);

        this.eintrag.duplicates.forEach((duplicate, index) => {
          row[`eintrag-${index}`] = this.getObjectValue(duplicate, fieldKey);
        });

        this.tableData.push(row);
      });
    },
    getObjectValue(object, path) {
      // Helfer-Funktion um den Wert von einem nested object property korrekt zu erhalten (z.B. gender.designation)
      const keys = path.split(".");
      let value = object;

      for (const key of keys) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          value = value[key];
        } else {
          value = null;
          break;
        }
      }
      return value;
    },
    initializeMasterObject() {
      //Nicht-reaktive Kopie des Eintrags erstellen. Anstatt Object.assign wird hier JSON.pars(JSON.stringify(obj)) verwendet,
      //um auch die nested objects innerhalb des Eintrags nicht-reaktiv zu kopieren.
      //Diese Kopie wird im Modal durch den User modifiziert und im PUT nach ins Back-End geschickt.
      this.master = JSON.parse(JSON.stringify(this.eintrag));
    },
    setAllRadioButtonsOfColumn(columnName) {
      //Setze alle Radio-Buttons einer Spalte auf true

      Object.keys(this.$refs).forEach((ref) => {
        if (ref.includes(columnName + "-")) {
          this.$refs[ref].checked = true;
        }
      });
    },
    setMasterValue(cellKey, cellIndex, cellDuplicateIndex) {
      //Finde das korrekte objekt anhand cell.index und duplicateIndex und weise es dem Master-Objekt zu
      if (cellKey != "master") {
        this.master[`${this.fieldObjects[cellIndex]}`] =
          this.eintrag.duplicates[cellDuplicateIndex][
            `${this.fieldObjects[cellIndex]}`
          ];
        console.log(this.master[`${this.fieldObjects[cellIndex]}`]);
      } else {
        this.master[`${this.fieldObjects[cellIndex]}`] =
          this.eintrag[`${this.fieldObjects[cellIndex]}`];
      }
    },
    sperreEintrag(key, fieldIndex) {
      let eintragIndex = fieldIndex - 2; // index - 2 aufgrund Description- und Master-Spalte

      //Setze neuen oder bereits vorhandenen key "locked" auf true. Anhand diesem Key wird die Dublette beim PUT
      //mitgeschickt oder nicht
      if (this.master?.duplicates[eintragIndex])
        this.$set(this.master.duplicates[eintragIndex], "locked", true);

      if (this.eintrag?.duplicates[eintragIndex])
        this.$set(this.eintrag.duplicates[eintragIndex], "locked", true);

      this.disableAllRadioButtonsOfColumn(key);
    },
    disableAllRadioButtonsOfColumn(columnName) {
      //Disable alle RadioButtons und den Alle-wählen-Button der Column
      //Finde anhand des Names der zu sperrenden Column und den darin enthaltenen angehakten
      //Radio-Buttons die entsprechenden Master-Radio-Buttons und hake stattdessen diese an

      for (const ref in this.$refs) {
        if (ref.includes("button-" + columnName)) {
          //Disable Alle-wählen-Button
          this.$refs[ref][0].disabled = true;
        }
        if (ref.includes("radio-" + columnName + "-")) {
          //Disable Radio-Button der Column
          this.$refs[ref].disabled = true;
          if (this.$refs[ref].checked) {
            //Setze den Originalen Value im Master-Objekt, wenn der Radio-Button der Column aktiv war
            this.setMasterValue(
              "master",
              this.$refs[ref].dataset.cellindex,
              this.$refs[ref].dataset.cellduplicateindex
            );

            //Suche den entsprechenden Master-Radio-Button und setze den Haken
            const name = this.$refs[ref].name;

            const masterRefKey = Object.keys(this.$refs).find(
              (refKey) =>
                refKey.includes("master") && this.$refs[refKey].name === name
            );

            if (masterRefKey) {
              this.$refs[masterRefKey].checked = true;
            }
          }
        }
      }
    },
    entsperreEintrag(key, fieldIndex) {
      let eintragIndex = fieldIndex - 2; // index - 2 aufgrund Description- und Master-Spalte
      this.master.duplicates[eintragIndex].locked = false;
      this.eintrag.duplicates[eintragIndex].locked = false;

      this.enableAllRadioButtonsOfColumn(key);
    },
    enableAllRadioButtonsOfColumn(columnName) {
      for (const ref in this.$refs) {
        if (ref.includes("button-" + columnName)) {
          //Enable Alle-wählen-Button
          this.$refs[ref][0].disabled = false;
        }

        if (ref.includes("radio-" + columnName + "-")) {
          //Enable Radio-Button der Column
          this.$refs[ref].disabled = false;
        }
      }
    },
    dublettenZusammenfuehren() {
      //das JSON ist dasselbe beim PUT wie beim GET, jedoch mit den geänderten Werten des Master-Eintrages und gesperrte Duplikate werden nicht mitgeschickt

      this.entferneGesperrteEintraege();

      this.disableGesperrteEintraege();

      let json = this.master;

      CrmApi.put("person/" + json.id, json, {})
        .then(() => {
          this.$emit("eintragSchliessen");
        })
        .catch((e) => {
          this.$notify({
            type: "danger",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.mergingerror"),
          });
        });
    },
    entferneGesperrteEintraege() {
      this.master.duplicates = this.master.duplicates.filter(
        (duplicate) => !duplicate.locked
      );
    },

    disableGesperrteEintraege() {
      let duplicatesToDisable = this.eintrag.duplicates.filter(
        (duplicate) => duplicate.locked
      );

      let masterID = this.master.id;

      let duplicateIDs = duplicatesToDisable.map((duplicate) => duplicate.id);

      let data = {
        masterID: masterID,
        duplicateIDs: duplicateIDs,
      };

      CrmApi.post("duplicate-archive/", data)
        .then(() => {})
        .catch((e) => {
          this.$notify({
            type: "danger",
            title: this.$t("global.actionfailed"),
            text: this.$t("global.mergingerror"),
          });
        });
    },
  },
};
</script>

	  <style>
</style>
