<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.invoices')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between"></div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="rechnungen"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
                thead-tr-class="pointer"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>
  </div>
</template>

<script>
import server from "@/server";

import Api from "@/Api";
import {apiErrorToAlert} from "@/utils/Errorhandler";
import {BSpinner} from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";

import numeral from "numeral";
import KotlinAppApi from "@/KotlinAppApi";

export default {
  components: {
    ContainerHeadline,
    BSpinner
  },
  mixins: [reiter],
  props: {
    lead: {
      type: String,
      required: false,
    },
    person: {
      type: String,
      required: false
    },
    firma: {
      type: String,
      required: false
    },
    shown: {
      type: Boolean,
      required: false
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 99,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      rechnungen: [],
      pdfPath: "",
      selected: [],
      selectedAnmeldungIds: []
    };
  },
  watch: {
    shown(val) {
      if (val && this.rechnungen.length == 0) this.getRechnungen();
    }
  },
  computed: {
    fields() {
      return [
        {key: "selected", label: ""},
        {
          key: "auftragstatus",
          sortable: false,
          label: this.$t("global.statusorder"),
          formatter: (value) => {
            return value ? value.bezeichnung : "-";
          }
        },
        {
          key: "auftragsnummer",
          sortable: true,
          label: this.$t("global.invoicenr"),
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          key: "rechnungsdatum",
          sortable: false,
          label: this.$t("global.invoicedate"),
          formatter: (val) => {
            return new Date(val).toLocaleDateString('de-DE');
          }
          /*tdClass: "text-right",
          thClass: "text-right",*/
        },
        {
          key: "total_brutto",
          sortable: true,
          label: this.$t("global.totalgross"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
          thClass: "text-right"
        },
        {
          key: "total_netto",
          sortable: true,
          label: this.$t("global.totalnet"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
          thClass: "text-right"
        },
        {
          key: "buchungstatus",
          sortable: true,
          sortKey: "sortierung",
          label: this.$t("global.statusbooking"),
          formatter: (value) => {
            return value ? value.bezeichnung : "-";
          }
        },
        {
          key: "zahlungstatus",
          sortable: false,
          label: this.$t("global.statuspayment"),
          formatter: (value) => {
            return value ? value.bezeichnung : "-";
          }
        },
        {
          key: "rechnungsadresse",
          sortable: false,
          label: this.$t("global.invoiceaddress"),
          formatter(value) {
            return value ? value.strasse_nr + ", " + value.plz + " " + value.ort : "-"
          },
        },
        {
          key: "empfaenger",
          sortable: false,
          label: this.$t("global.receiver")
        }
      ];
    }
  },
  mounted() {
    if (this.shown && this.rechnungen.length === 0) this.getRechnungen();
  },
  created() {
  },
  methods: {
    getRechnungen() {
      KotlinAppApi.get("beratungsauftrag/auftrag", {
        params: {
          id: this.lead
        }
      }).then((response) => {
        this.rechnungen = response.data
      })

      /*this.isBusy = true;

            Api.get("auftrag/", {
              params: {
                person: this.person,
                firma: this.firma,
                page: this.page,
                count: this.anzahlProPage,
              },
            })
              .then((response) => {
                //response.data.shift();
                this.rechnungen = response.data;
                this.ladeFehler = false;
              })
              .catch((e) => {
                this.$notify(apiErrorToAlert(e));
                this.ladeFehler = true;
              })
              .finally(() => {
                this.isBusy = false;
              });*/
    },

    details(rechnung) {
      this.$router.push({
        name: "auftrag.id",
        params: {id: rechnung.id}
      });
    }
    ,

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedAnmeldungIds = [];
      }
      this.$forceUpdate();
    }
    ,

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedAnmeldungIds.push(row.item.id);
      } else {
        this.selectedAnmeldungIds = this.selectedAnmeldungIds.filter(
          (id) => id != row.item.id
        );
      }
    }
    ,
    showNotenausweisPdfModal() {
      this.$bus.$emit("open-modal", "notenausweis-pdf-modal");
    },


    /* else {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.nodataavailable"),
            });
          } */
  }
};
</script>
