import Api from "@/Api";

export default {
  install(Vue) {
    let appKeys = {
      ROLLEN: {
        DOZENTEN: [],
        STUDENTEN: [],
        TEILNEHMER_INFOEVENT: [],
        MITARBEITER: [],
        INTERESSENTEN: [],
        ALUMNIS: [],
        KURSTEILNEHMER: [],
        DIPLOMARBEIT: [],
        TRAEGERSCHAFT: [],
        LIEFERANTEN: [],
        REFERENTEN: [],
        KUNDEN: [],
        ARBEITGEBER: [],
        JOBCOACH: [],
        PRAXISBILDNER: [],
        IVBERATER: [],
        NOTFALLKONTAKT: [],
        ANSTALTSDIREKTOR: [],
        NICHTZUGEORDNET: [],
      },
      EVENTEDITABLESTATI: [],
      ADRESSVERWENDUNGSTYPEN: {
        /*INVOICE: [],
        CORRESPONDENCE: []*/
      },
      VERANSTALTUNGSTYPEN: {
        PRECOURSE: [],
        COURSE: [],
      },
      PRINTOUTS: {},
      CARBONEPRINTOUTS: {},
      CONFIG: { APPNAME: [], APPTYPE: [] },
    };

    Api.get("appkeys/").then((response) => {
      for (var list in response.data) {
        if (!appKeys[list.toUpperCase()]) appKeys[list.toUpperCase()] = [];

        response.data[list].forEach((element) => {
          //console.log(list + " - " + element.appkey);

          appKeys[list.toUpperCase()][element.appkey.toUpperCase()] =
            element.id;

          if (list == "printouts") {
            if (element.carbone == false) {
              appKeys[list.toUpperCase()][element.appkey.toUpperCase()] =
                element.value;
            } else {
              appKeys["CARBONE" + list.toUpperCase()][element.appkey.toUpperCase()] =
                element;
            }

          }

          if (list == "config")
            appKeys[list.toUpperCase()][element.appkey.toUpperCase()] =
              element.value;

          if (list == "rollen") {
            let uRolle = element.appkey.toUpperCase();

            if (uRolle.indexOf("CUSTOMER") == 0)
              appKeys.ROLLEN.KUNDEN.push(element.id);

            if (uRolle.indexOf("POTENTIAL") == 0)
              appKeys.ROLLEN.KUNDEN.push(element.id);

            if (uRolle.indexOf("INSTRUCTOR") == 0)
              appKeys.ROLLEN.DOZENTEN.push(element.id);

            if (uRolle.indexOf("STUDENT") == 0)
              appKeys.ROLLEN.STUDENTEN.push(element.id);

            if (uRolle.indexOf("INFOEVENT") == 0)
              appKeys.ROLLEN.TEILNEHMER_INFOEVENT.push(element.id);

            if (uRolle.indexOf("EMPLOYEE") == 0)
              appKeys.ROLLEN.MITARBEITER.push(element.id);

            if (uRolle.indexOf("PROSPECTIVE") == 0)
              appKeys.ROLLEN.INTERESSENTEN.push(element.id);

            if (uRolle.indexOf("ALUMNI") == 0)
              appKeys.ROLLEN.ALUMNIS.push(element.id);

            if (uRolle.indexOf("COURSE") == 0)
              appKeys.ROLLEN.KURSTEILNEHMER.push(element.id);

            if (uRolle.indexOf("THESIS") == 0)
              appKeys.ROLLEN.DIPLOMARBEIT.push(element.id);

            if (uRolle.indexOf("SPONSORSHIP") == 0)
              appKeys.ROLLEN.TRAEGERSCHAFT.push(element.id);

            if (uRolle.indexOf("SUPPLIER") == 0)
              appKeys.ROLLEN.LIEFERANTEN.push(element.id);

            if (uRolle.indexOf("SPEAKER") == 0)
              appKeys.ROLLEN.REFERENTEN.push(element.id);

            if (uRolle.indexOf("EMPLOYER") == 0)
              appKeys.ROLLEN.ARBEITGEBER.push(element.id);

            if (uRolle.indexOf("COACH") == 0)
              appKeys.ROLLEN.JOBCOACH.push(element.id);

            if (uRolle.indexOf("PRACTICETRAINER") == 0)
              appKeys.ROLLEN.PRAXISBILDNER.push(element.id);

            if (uRolle.indexOf("IVCONSULTANT") == 0)
              appKeys.ROLLEN.IVBERATER.push(element.id);

            if (uRolle.indexOf("EMERGENCYCONTACT") == 0)
              appKeys.ROLLEN.NOTFALLKONTAKT.push(element.id);

            if (uRolle.indexOf("PRINCIPAL") == 0)
              appKeys.ROLLEN.ANSTALTSDIREKTOR.push(element.id);

            if (uRolle.indexOf("NOROLE") == 0)
              appKeys.ROLLEN.NICHTZUGEORDNET.push(element.id);
          }

          /* if (list == "addressverwendungstypen") {
            let uAdressverwendung = element.appkey.toUpperCase();

            if (uAdressverwendung.indexOf("INVOICE") == 0)
              appKeys.ADRESSVERWENDUNGSTYPEN.INVOICE.push(element.id);

            if (uAdressverwendung.indexOf("CORRESPONDENCE") == 0)
              appKeys.ADRESSVERWENDUNGSTYPEN.CORRESPONDENCE.push(element.id);
          }*/
        });
      }

      /*** Stati setzen die Veranstaltung als Editierbar definieren ***/

      appKeys.EVENTEDITABLESTATI.push(appKeys.VERANSTALTUNGSSTATI.OPEN);
      appKeys.EVENTEDITABLESTATI.push(appKeys.VERANSTALTUNGSSTATI.PLANNED);
    });

    //  create global Constants
    Vue.prototype.$CONST = (key) => {
      return appKeys[key];
    };
  },
};
