<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{
              neuePosition
                ? $t("global.newposition")
                : $t("global.editposition")
            }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row ml-0 mr-0">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <container-headline
                    :headline="$t('global.masterdata')"
                    :col="6"
                  ></container-headline>
                  <div class="row mb-3">
                    <div class="col-xl-12 block br-t-l-0">
                      <div class="eingabe-panel-kopf p-3">
                        <div class="row">
                          <div class="col-xl-3">
                            <div class="form-group">
                              <input
                                class="form-control"
                                placeholder=" "
                                v-model.number="position.artikel.artikelnummer"
                                readonly="true"
                              />
                              <label>{{ $t("global.itemnumber") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-9">
                            <div class="form-group">
                              <v-select
                                v-model="position.artikel"
                                label="bezeichnung"
                                :options="artikelListe"
                                :get-option-label="
                                  (e) =>
                                    e.artikelnummer
                                      ? `${e.artikelnummer} / ${e.kurzbezeichnung} / ${e.bezeichnung} / ${e.mwstcode.bezeichnung}`
                                      : ``
                                "
                                :placeholder="$t('global.item')"
                                :clearable="false"
                                @input="resetManuelleBezeichnung()"
                              >
                              </v-select>
                              <label>{{ $t("global.item") }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-3">
                            <div class="form-group">
                              <input
                                class="form-control"
                                label="bezeichnung"
                                placeholder=" "
                                v-model="
                                  position.artikel.artikeltyp.bezeichnung
                                "
                                readonly
                              />
                              <label class="">{{
                                $t("global.itemtype")
                              }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8">
                            <div class="form-group" v-if="auftrag.person">
                              <input
                                class="form-control"
                                placeholder=" "
                                :value="`${auftrag.nummer} / ${
                                  auftrag.auftragsstatus.bezeichnung
                                } / ${
                                  auftrag.person
                                    ? auftrag.person.personName
                                    : ''
                                }`"
                                readonly="true"
                              />
                              <label>{{ $t("global.order") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-4">
                            <div class="form-group">
                              <input
                                class="form-control"
                                placeholder=" "
                                :value="`${auftrag.quelleAuftrag.bezeichnung}`"
                                readonly="true"
                              />
                              <label>{{ $t("global.source") }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-3">
                            <div class="form-group">
                              <input
                                class="form-control"
                                placeholder=" "
                                v-model.trim="position.kurz"
                              />
                              <label>{{ $t("global.shortdesignation") }}</label>
                            </div>
                          </div>
                          <div class="col-xl-9">
                            <div class="form-group">
                              <textarea
                                v-model="position.bezeichnung"
                                class="form-control"
                                placeholder=" "
                                rows="3"
                              />
                              <label v-if="this.manuelleBezeichnung"
                                >Bezeichnung (manuell)</label
                              >
                              <label v-if="!this.manuelleBezeichnung">{{
                                $t("global.designation")
                              }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-12" v-if="position.artikel.id != null">
                <container-headline
                  headline="Preise"
                  :col="6"
                ></container-headline>
                <div class="row">
                  <div class="col-xl-12 block br-t-l-0">
                    <div class="eingabe-panel-kopf p-3">
                      <div class="row">
                        <div class="col-xl-2">
                          <div class="form-group">
                            <input
                              class="form-control mb-3"
                              placeholder=" "
                              v-model.number="position.anzahl"
                            />
                            <label>{{ $t("global.qty") }}</label>
                          </div>
                        </div>
                        <div class="col-xl-2">
                          <div class="form-group">
                            <input
                              v-model="artikeleinheit"
                              class="form-control mb-3"
                              placeholder=""
                              readonly
                            />
                            <label class="">{{ $t("global.unit") }}</label>
                          </div>
                        </div>
                        <div class="col-xl-2">
                          <div class="form-group">
                            <input
                              class="form-control"
                              placeholder=" "
                              :value="`${auftrag.waehrung.bezeichnung}`"
                              readonly="true"
                            />
                            <label>{{ $t("global.currency") }}</label>
                          </div>
                        </div>
                        <div class="col-xl-4">
                          <div class="form-group">
                            <v-select
                              v-model="preis"
                              label="preis"
                              :options="preise"
                              placeholder="Preise"
                              :clearable="false"
                              @input="preisChanged(preis)"
                            >
                            </v-select>
                            <label>{{ $t("global.unitprice") }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4">
                          <div class="form-group">
                            <input
                              class="form-control"
                              placeholder=" "
                              v-model.number="manuellerpreis"
                              @input="manuellerpreisChanged"
                            />
                            <label>{{ $t("global.individualprice") }}</label>
                          </div>
                        </div>
                        <div class="col-xl-4">
                          <div class="form-group">
                            <input
                              class="form-control"
                              placeholder=" "
                              v-model.number="position.totalnetto"
                              readonly="true"
                            />
                            <label>{{ $t("global.totalnet") }}</label>
                          </div>
                        </div>

                        <div class="col-xl-4">
                          <div class="form-group">
                            <input
                              class="form-control"
                              placeholder=" "
                              v-model="position.totalbrutto"
                              readonly="true"
                            />
                            <label>{{ $t("global.totalgross") }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pt-4" v-if="position.artikel.id != null">
                <container-headline
                  :headline="$t('global.accountassignment')"
                  :col="6"
                ></container-headline>
                <div class="row mb-3">
                  <div class="col-xl-12 block br-t-l-0">
                    <div class="eingabe-panel-kopf p-3">
                      <div class="row">
                        <div class="col-xl-6">
                          <div class="form-group">
                            <input
                              v-model="position.ertragskonto.bezeichnung"
                              class="form-control"
                              placeholder=" "
                              readonly
                            />

                            <label class="">{{
                              $t("global.revenueaccount")
                            }}</label>
                          </div>
                        </div>
                        <div class="col-xl-6">
                          <div class="form-group">
                            <input
                              v-model="position.ertragskostenstelle.bezeichnung"
                              class="form-control"
                              placeholder=" "
                              readonly
                            />

                            <label class="">{{
                              $t("global.incomecostcenter")
                            }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="speichern"
            :disabled="!editable"
          >
            {{ $t("global.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import ContainerHeadline from "@/components/ContainerHeadline";
import { LADE_ARTIKEL } from "@/store/artikel/actions.type";

export default {
  name: "AuftragPositionModal",
  mixins: [modal],
  components: {
    ContainerHeadline,
    ModalCloseButton,
  },
  props: {
    positionId: {
      type: String,
      required: false,
      default: null,
    },
    auftrag: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      position: {
        artikel: { artikeltyp: { bezeichnung: this.$t("global.notlinked") } },
        preis: null,
        anzahl: 1,
        ertragskonto: {},
        ertragskostenstelle: {},
        einheit: {},
        manuellerpreis: null,
        totalbrutto: 0,
        totalnetto: 0,
        totalmwst: 0,
      },
      preis: null,
      preise: [],
      manuellerpreis: null,
      artikeleinheit: null,
      rabattprozent: null, // Rabattrechnung aktuell nicht implementiert
      rabattbetrag: null, // Rabattrechnung aktuell nicht implementiert
    };
  },
  computed: {
    artikelListe() {
      return this.$store.state.artikel.artikel;
    },

    neuePosition() {
      return this.positionId ? false : true;
    },
    manuelleBezeichnung() {
      if (
        this.position.kurzbezeichnung != this.position.artikel.kurz ||
        this.position.bezeichnung != this.position.artikel.bezeichnung
      ) {
        return true;
      } else return false;
    },
  },
  watch: {
    positionId(val) {
      if (val) {
        Api.get("auftrag/position/", {
          params: { id: val },
        }).then((response) => {
          this.position = response.data;
          if (this.position.manuellerpreis) {
            this.preis = null;
            this.manuellerpreis = this.position.preis;
          } else {
            this.preis = this.position.preis;
            this.manuellerpreis = null;
          }
        });
      }
    },

    ["position.anzahl"]: {
      handler() {
        this.calculatePreise();
      },
    },

    preis() {
      this.calculatePreise();
    },

    ["position.artikel"]: {
      deep: true,
      handler() {
        this.artikelChanged();
      },
    },

    shown() {},
  },
  created() {
    if (!this.artikelListe) this.$store.dispatch(`artikel/${LADE_ARTIKEL}`);
  },

  mounted() {},

  methods: {
    artikelChanged() {
      if (this.shown) {
        this.artikeleinheit = this.position.artikel.einheit.bezeichnung;
        this.position.ertragskonto = this.position.artikel.ertragskonto;
        this.position.ertragskostenstelle =
          this.position.artikel.ertragskostenstelle;

        Api.get("artikel/preis/", {
          params: { artikel: this.position.artikel.id },
        }).then((response) => {
          if (response.data.length != 0) {
            this.preise = response.data;
            this.preis = this.position.preis;

            if (this.position.manuellerpreis) {
              this.preis = null;
            } else {
              if (this.position.preis) {
                this.preis = this.preise.find(
                  (p) => p.preis == this.position.preis
                )?.preis
                  ? this.preise.find((p) => p.preis == this.position.preis)
                      ?.preis
                  : this.preise[0].preis;
              } else {
                this.preis = this.preise[0].preis;
              }
              this.position.preis = this.preis;

              if (!this.position.totalnetto) {
                if (this.position.preis) {
                  this.position.totalnetto = this.preise.find(
                    (p) => p.preis == this.position.preis
                  )?.preis
                    ? this.preise.find((p) => p.preis == this.position.preis)
                        ?.preis
                    : this.preise[0].preis;
                } else {
                  this.position.totalnetto = this.preise[0].preis;
                }
              }
            }

            if (this.position.artikel.mwstcode?.mehrwertsteuersatz.satz) {
              this.position.totalbrutto =
                this.position.totalnetto *
                ((100 +
                  this.position.artikel.mwstcode?.mehrwertsteuersatz.satz) /
                  100);
            } else {
              this.position.totalbrutto = this.position.totalnetto;
            }
          } else {
            this.preise = [];
          }
          // console.log(this.neuePosition);
          if (this.neuePosition) {
            this.position.bezeichnung = this.position.artikel.bezeichnung;
            this.position.kurz = this.position.artikel.kurzbezeichnung;
          }
          this.calculatePreise();
        });
        if (!this.manuelleBezeichnung) {
          this.position.kurz = this.position.artikel.kurzbezeichnung;
          this.position.bezeichnung = this.position.artikel.bezeichnung;
        }
      }
    },

    preisChanged(val) {
      this.preis = val.preis;
      this.calculatePreise();
    },

    manuellerpreisChanged() {
      this.preis = null;
      this.calculatePreise();
    },

    speichern() {
      var json = Object.assign({}, this.position);

      json.auftrag = this.auftrag.id;
      if (this.position.artikel) json.artikel = this.position.artikel.id;
      if (this.preis) {
        json.preis = this.preis;
        json.manuellerpreis = false;
      } else {
        json.preis = this.manuellerpreis;
        json.manuellerpreis = true;
      }

      json.totalmwst =
        this.position.totalnetto *
        (this.position.artikel.mwstcode?.mehrwertsteuersatz.satz / 100);

      json.totalbrutto = this.position.totalnetto + json.totalmwst;

      if (this.positionId) {
        Api.put("auftrag/position/", json, {
          params: { id: this.positionId },
        }).then((response) => {
          this.$emit("position-changed", response.data);
          this.closeModal();
        });
      } else {
        Api.post("auftrag/position/", json).then((response) => {
          this.$emit("position-created", response.data);
          this.closeModal();
        });
      }
    },

    abbrechen() {
      this.closeModal();
    },

    calculatePreise() {
      if (!this.shown) return;

      if (this.preis) {
        this.position.totalnetto =
          this.preis * this.position.anzahl - (this.rabattbetrag || 0);
      } else {
        this.position.totalnetto =
          this.manuellerpreis * this.position.anzahl - (this.rabattbetrag || 0);
      }

      if (this.position.artikel.mwstcode?.mehrwertsteuersatz.satz) {
        let tempBrutto =
          this.position.totalnetto *
          ((100 + this.position.artikel.mwstcode?.mehrwertsteuersatz.satz) /
            100);

        let roundedBrutto = Math.round(tempBrutto * 20) / 20; // Schweizer Rundung. Sieht komisch aus ist aber richtig :)

        this.position.totalbrutto = roundedBrutto;
      } else {
        this.position.totalbrutto = this.position.totalnetto;
      }
    },

    calculateRabattBetrag() {
      // Rabattrechnung aktuell nicht implementiert
      if (this.rabattprozent && this.preis) {
        this.rabattbetrag =
          (this.preis * this.position.anzahl * this.rabattprozent) / 100;
      } else {
        this.rabattbetrag = 0;
      }
    },

    resetManuelleBezeichnung() {
      //Setzt beim Wechsel des Artikels die manuelle Positionsbezeichnung auf die Bezeichnung des neuen Artikels
      this.position.kurzbezeichnung = this.position.artikel.kurz;
      this.position.bezeichnung = this.position.artikel.bezeichnung;
    },

    closeModal() {
      this.shown = false; // vorab auf null setzen damit Watcher abgefangen werden können
      this.position = {
        artikel: { artikeltyp: { bezeichnung: this.$t("global.notlinked") } },
        preis: {},
        anzahl: 1,
        ertragskonto: {},
        ertragskostenstelle: {},
        artikeleinheit: {},
        manuellerpreis: null,
      };
      this.manuellerpreis = null;
      this.preis = null;
      this.preise = [];

      $("#position-modal").modal("hide");
      this.$emit("closed");
    },
  },
};
</script>

<style></style>
