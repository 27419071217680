<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="
                  navbarTitel ? navbarTitel : $t('global.lecturerbooking')
                "
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-shortkey.once="['ctrl', 's']"
                    v-if="editable"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>

                  <button
                    class="btn btn-primary mr-2"
                    :disabled="invalid"
                    @click="ausgebenEinzelvertrag"
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-print"
                      class="mr-2"
                    />
                    {{ $t("global.deploymentcontract") }}
                  </button>

                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />
                    {{ $t("global.tolist") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_dozierende.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-person
                      id="dozent"
                      :person="dozentenbuchung.person"
                      :label="$t('global.lecturer')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      :rollen="filterRollen"
                      :editable="editable"
                      @confirmed="setPerson"
                    />
                  </div>
                  <div class="col-xl-6">
                    <single-select-veranstaltung
                      id="dozentenbuchung-veranstaltung"
                      :veranstaltung="dozentenbuchung.veranstaltung"
                      :label="$t('global.event')"
                      :allowOpen="berechtigungen.m_bildung.read"
                      :editable="editable"
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="dozentenbuchung.dozentenstatus"
                        label="bezeichnung"
                        :options="dozentenstati"
                        :reduce="(ds) => ds.id"
                        :disabled="!editable && !isAdmin"
                        :placeholder="$t('global.statuslecturer')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.statuslecturer") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="dozentenbuchung.abrechnungsstatus"
                        label="bezeichnung"
                        :options="abrechnungsstati"
                        :reduce="(as) => as.id"
                        :disabled="!editable"
                        :placeholder="$t('global.statusbilling')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.statusbilling") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="id" class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model.trim="anstellung"
                        class="form-control"
                        placeholder=" "
                        readonly="true"
                      />
                      <label>{{ $t("global.employment") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <input
                        v-model.trim="dozentenbuchung.total_lektionen"
                        class="form-control"
                        placeholder=" "
                        readonly="true"
                      />
                      <label>{{ $t("global.lessonstotal") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="id" class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="dozentenbuchung.dozentenbuchungtyp"
                        label="bezeichnung"
                        :options="dozentenbuchungtypen"
                        :reduce="(as) => as.id"
                        :disabled="!editable"
                        :placeholder="$t('global.type')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.type") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Lohninformationen -->
          <div v-if="id">
            <container-headline
              :headline="$t('global.wageinformation')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="dozentenbuchung.lohnansatz"
                          label="bezeichnung"
                          :options="lohnansaetze"
                          :reduce="(l) => l.id"
                          :disabled="!editable"
                          :placeholder="$t('global.wagerate')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.wagerate") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <v-select
                          v-model="dozentenbuchung.lohnart"
                          label="bezeichnung"
                          :options="lohnarten"
                          :disabled="!editable"
                          :reduce="(l) => l.id"
                          :placeholder="$t('global.wagetype')"
                        >
                          <span slot="no-options">{{
                            $t("global.wagetype")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.wagetype") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="dozentenbuchung.stundensatz"
                          class="form-control"
                          :readonly="!editable"
                          placeholder=" "
                        />
                        <label>{{ $t("global.hourlyrate") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-model.trim="dozentenbuchung.bemerkung"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                          rows="4"
                        ></textarea>
                        <label>{{ $t("global.comment") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Lohninformationen -->
        </div>
        <!-- ENDE Rechte Seite -->
        <div v-if="id" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'dozentenbuchung',
                      params: { id: id, anzeige: 0 },
                    }"
                    >{{ $t("global.lecturerdeployment") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <!-- Start Dozenteneinsatz -->
                  <dozenteneinsatzliste
                    :dozentenbuchung="id"
                    :editable="editable"
                    :shown="anzeige == 0 ? true : false"
                  />
                  <!-- Ende Dozenteneinsatz -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loeschen-modal
      id="dozentenbuchung-loeschen-modal"
      @confirmed="dozentenbuchungLoeschen"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>

    <change-watcher-alert />
  </div>
</template>

<script>
import page from "@/mixins/Page";

import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import server from "@/server";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Dozenteneinsatzliste from "@/components/Reiter/Dozenteneinsatzliste";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";

import Api from "@/Api";
import store from "@/store";

import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";
import { LADE_AUSWAHLWERTE_LOHN } from "@/store/lohn/actions.type";

import ChangeWatcher from "@/mixins/ChangeWatcher";

export default {
  name: "Dozentenbuchung",
  components: {
    Dozenteneinsatzliste,
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoeschenModal,
    SingleSelectPerson,
    SingleSelectVeranstaltung,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: false,
      pdfPath: "",

      filterRollen: this.$CONST("ROLLEN").DOZENTEN,

      dozentenbuchung: {
        person: {
          personName: this.$t("global.notlinked"),
        },
        bemerkung: null,
        lohnart: null,
        stundensatz: null,
        lohnansatz: null,
        lektionen: null,
        veranstaltung: {
          bezeichnung: this.$t("global.notlinked"),
        },
        abrechnungsstatus: null,
        dozentenstatus: null,
        total_lektionen: null,
      },
      anstellung: "Keine Angabe",
      isAdmin: false,
      dozentName: null,
      bezeichnungKuerzel: null,
      bezeichnung: "",
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.lecturerbooking");
      } else {
        return this.$t("global.newlecturerbooking");
      }
    },

    dozentenstati: {
      get() {
        return this.$store.state.dozent.dozentenstati;
      },
    },
    dozentenbuchungtypen: {
      get() {
        return this.$store.state.dozent.dozentenbuchungtypen;
      },
    },
    lohnarten: {
      get() {
        return this.$store.state.lohn.lohnarten;
      },
    },
    lohnansaetze: {
      get() {
        return this.$store.state.lohn.lohnansaetze;
      },
    },
    abrechnungsstati: {
      get() {
        return this.$store.state.dozent.abrechnungsstati;
      },
    },
  },
  watch: {
    id: {
      handler: function (val) {
        if (val) {
          this.ladeDozentenbuchung(val);
        }
      },
      immediate: true,
    },
  },
  created() {
    Api.get("dozentenbuchung/", { params: { id: this.id } }).then(
      (response) => {
        this.initializeDozentenbuchung(response.data);
      }
    );
  },
  mounted: function () {
    if (this.dozentenstati.length == 0)
      this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);

    if (this.lohnarten.length == 0)
      this.$store.dispatch(`lohn/${LADE_AUSWAHLWERTE_LOHN}`);

    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    ladeDozentenbuchung(id) {
      this.loading = true;

      Api.get("dozentenbuchung/", { params: { id: id } }).then((response) => {
        this.initializeDozentenbuchung(response.data);
      });
    },

    setVeranstaltung(veranstaltung) {
      this.dozentenbuchung.veranstaltung = veranstaltung;
    },

    setPerson(person) {
      this.dozentenbuchung.person = person;
    },

    abbrechen() {
      this.$router.push({ name: "dozentenbuchung-liste" });
    },

    initializeDozentenbuchung(dozentenbuchung) {
      if (dozentenbuchung) {
        this.dozentenbuchung = dozentenbuchung;

        this.editable =
          dozentenbuchung.dozentenstatus.id ==
          this.$CONST("DOZENTENSTATI").UNREGISTERED
            ? false
            : true;

        if (!this.berechtigungen.m_dozierende.update) this.editable = false;

        this.dozent = dozentenbuchung.person;

        if (dozentenbuchung.person) {
          this.dozentName = dozentenbuchung.person
            ? dozentenbuchung.person.personName
            : this.$t("global.notlinked");
          this.anstellung = dozentenbuchung.person.anstellung
            ? dozentenbuchung.person.anstellung.bezeichnung
            : this.$t("global.notspecified");
        }
        /*
        this.bemerkung = dozentenbuchung.bemerkung;
        this.lohnart = dozentenbuchung.lohnart?.id;
        this.stundensatz = dozentenbuchung.stundensatz;
        this.lohnansatz = dozentenbuchung.lohnansatz?.id;
        this.lektionen = dozentenbuchung.lektionen;
        this.abrechnungsstatus = dozentenbuchung.abrechnungsstatus?.id;
        this.dozentenstatus = dozentenbuchung.dozentenstatus?.id;
        this.veranstaltung = dozentenbuchung.veranstaltung;
        this.total_lektionen = dozentenbuchung.total_lektionen;
        this.bezeichnung = dozentenbuchung.bezeichnung;
      */
        this.initChangeWatcher(this.dozentenbuchung);
      } else {
        console.error("dozentenbuchung ist null");
      }

      this.loading = false;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.dozentenbuchung);

      /*var json = {
        bemerkung: this.dozentenbuchung.bemerkung,
        person: this.dozentenbuchung.person.id,
        veranstaltung: this.dozentenbuchung.veranstaltung?.id,
        dozentenstatus: this.dozentenbuchung.dozentenstatus,
        abrechnungsstatus: this.dozentenbuchung.abrechnungsstatus,
        lohnart: this.dozentenbuchung.lohnart,
        lohnansatz: this.dozentenbuchung.lohnansatz,

        stundensatz:
          typeof this.dozentenbuchung.stundensatz === "number"
            ? this.dozentenbuchung.stundensatz
            : parseFloat(this.dozentenbuchung.stundensatz), //bxchTools.parseNumber()

        total_lektionen:
          typeof this.dozentenbuchung.total_lektionen === "number"
            ? this.dozentenbuchung.total_lektionen
            : parseFloat(this.dozentenbuchung.total_lektionen),
      };*/

      if (!this.dozentenbuchung) {
        Api.post("dozentenbuchung/", json)
          .then((response) => {
            this.initializeDozentenbuchung(response.data);
            console.log(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.lecturerbookingsuccessfullysaved"),
            });

            this.$router.replace({
              name: "DozentenbuchungMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("dozentenbuchung/", json, {
          params: { id: this.dozentenbuchung.id },
        })
          .then((response) => {
            this.initializeDozentenbuchung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.lecturerbookingsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    dozentenbuchungLoeschen() {
      Api.delete("dozentenbuchung/", {
        params: { ids: this.id},
      }).then(() => {
        this.$router.push({ name: "dozentenbuchung-liste" });
      });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "dozentenbuchung-loeschen-modal");
    },

    ausgebenEinzelvertrag() {
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=EV${filename}.pdf&report=/pdfgenerator/einsatzvertrag.htm&ids=${this.id}`;
      this.$refs.pdfLayer.show();
    },
  },
};
</script>

<style></style>
