import Timesheetliste from "@/pages/Dashboardlisten/Timesheetliste";
import Timesheet from "@/pages/Zeiterfassung/Timesheet";
import Overview from "@/pages/Zeiterfassung/Uebersicht";
import Projekterfassunguebersichtsliste from "@/pages/Zeiterfassung/Projekterfassunguebersichtsliste"
import Zeitkonto from "@/pages/Zeiterfassung/Zeitkonto"

export default [
  {
    path: "/zeiterfassung/projektuebersicht",
    name: "project-overview",
    component: Projekterfassunguebersichtsliste,
  },
  {
    path: "/zeiterfassung/zeitkonto",
    name: "timeaccount",
    component: Zeitkonto,
  },
  {
    path: "/zeiterfassung/uebersicht",
    name: "overview",
    component: Overview,
  },
  {
    path: "/zeiterfassung/zeitblaetter",
    name: "timesheet-liste",
    component: Timesheetliste,
  },
  {
    path: "/zeiterfassung/zeitblatt/:id",
    name: "timesheet",
    component: Timesheet,
    props: (route) => {
      return {
        id: route.params.id,
      };
    },
  },
  {
    path: "/zeiterfassung/zeitblatt/:datum?",
    name: "timesheet-neu",
    component: Timesheet,
    props: (route) => {
      return {
        datum: route.params.datum,
      };
    },
  },
];
