<template>
  <div class="col-xl-12 pr-4">
    <!-- <container-headline
      :headline="$t('global.content')"
      :col="3"
    ></container-headline> -->
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row" v-if="mail.gesendet || mail.testmail_gesendet">
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="mail.gesendet"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.sent") }}</label>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <input
                  v-model.trim="mail.testmail_gesendet"
                  readonly
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.testmailsent") }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <div v-if="!mail.isVorlage">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.absendermail"
                      v-bind:class="{ 'border-danger': !passed }"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.senderemail")
                    }}</label>
                  </ValidationProvider>
                </div>

                <div v-if="mail.isVorlage && mail.absenderadresse && lang">
                  <ValidationProvider
                    :rules="mail.defaultSprache == lang.id ? 'required' : ''"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.absenderadresse[lang.kuerzel].text"
                      v-bind:class="{ 'border-danger': !passed }"
                      class="form-control"
                      :readonly="!editable"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.senderemail")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-2">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="mail.zeitpunkt"
                  :disabled="!editable"
                  placeholder=" "
                  :show-icon="true"
                  @update="(val) => (mail.zeitpunkt = val)"
                />
                <label>{{ $t("global.date") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <div v-if="!mail.isVorlage">
                  <ValidationProvider
                    rules="required"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.betreff"
                      v-bind:class="{ 'border-danger': !passed }"
                      :readonly="!editable"
                      class="form-control"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.subject")
                    }}</label>
                  </ValidationProvider>
                </div>

                <div v-if="mail.isVorlage && mail.translation && lang">
                  <ValidationProvider
                    :rules="mail.defaultSprache == lang.id ? 'required' : ''"
                    immediate
                    v-slot="{ passed }"
                  >
                    <input
                      v-model.trim="mail.translation[lang.kuerzel].betreff"
                      v-bind:class="{ 'border-danger': !passed }"
                      :readonly="!editable"
                      class="form-control"
                      placeholder=" "
                    />
                    <label v-bind:class="{ 'text-danger': !passed }">{{
                      $t("global.subject")
                    }}</label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="form-group">
                <v-select
                  v-model="mail.mailbanner"
                  label="bezeichnung"
                  :options="mailbanner"
                  :disabled="!editable"
                  @input="mailBannerUpdated"
                  placeholder=" "
                  :clearable="true"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.mailbanner") }}</label>
              </div>
            </div>
          </div>

          <div class="row" v-if="!mail.isVorlage">
            <div class="col-xl-12">
              <ValidationProvider
                rules="required"
                immediate
                v-slot="{ passed }"
              >
                <div
                  class="form-group"
                  :style="{
                    border: passed ? '' : '1px solid red',
                    'padding-top': passed ? '' : '0',
                    'margin-top': passed ? '' : '16px',
                  }"
                >
                  <ckeditor
                    :editor="editor"
                    :disabled="!editable"
                    v-model="mail.inhalt"
                  ></ckeditor>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="row" v-if="mail.isVorlage && mail.translation && lang">
            <div class="col-xl-12">
              <ValidationProvider
                :rules="mail.defaultSprache == lang.id ? 'required' : ''"
                immediate
                v-slot="{ passed }"
              >
                <div
                  class="form-group"
                  :style="{
                    border: passed ? '' : '1px solid red',
                    'padding-top': passed ? '' : '0',
                    'margin-top': passed ? '' : '16px',
                  }"
                >
                  <ckeditor
                    :editor="editor"
                    :disabled="!editable"
                    v-model="mail.translation[lang.kuerzel].text"
                  ></ckeditor>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ContainerHeadline from "@/components/ContainerHeadline";

import store from "@/store";
import DatePicker from "@/components/Datepicker";

import ClassicEditor from "/custom_ckeditor/build/ckeditor";

import { LADE_AUSWAHLWERTE_MAIL } from "@/store/mail/actions.type";

import Api from "@/Api";
import { dropzoneOptionsAttachments } from "@/config/Dropzone";

export default {
  name: "MailInhalt",
  components: {
    //  ContainerHeadline,
    DatePicker,
  },
  mixins: [],
  store,
  props: {
    mail: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    lang: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      dropzoneOptions: dropzoneOptionsAttachments(
        Api.defaults.baseURL,
        this.id
      ),
    };
  },
  computed: {
    mailbanner: {
      get() {
        return this.$store.state.mail.mailbanner;
      },
    },
  },
  watch: {},

  created() {},

  mounted: function () {
    if (this.mailbanner.length == 0)
      this.$store.dispatch(`mail/${LADE_AUSWAHLWERTE_MAIL}`);
  },

  methods: {
    uploadFiles() {
      this.$refs.dokumenteUpload.processQueue();
    },

    mailBannerUpdated(val) {
      if (val && val.id) this.$emit("mailbannerupdated", val);
      else this.$emit("mailbannerupdated", null);
    },

    // setAbsender(absender) {
    //   this.mail.absender = absender;

    //   if (absender.mailadressen.length > 0)
    //     this.mail.absendermail = absender.mailadressen[0]?.bezeichnung;
    // },
  },
};
</script>

<style></style>
