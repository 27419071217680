<template>
  <div id="journaleintrag-bearbeiten-modal" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{
              journaleintrag.id
                ? $t("global.editjournalentry")
                : $t("global.addjournalentry")
            }}
          </h4>
        </div>
        <div class="modal-body" :key="key">
          <div class="row">
            <div class="col-xl-6">
              <single-select-person
                id="journaleintrag-verantwortlicher"
                :person="verantwortlicher"
                :label="$t('global.Responsible')"
                editable
                :allowOpen="false"
                @confirmed="setVerantwortlicher"
                :tabindex="8"
              />
            </div>
            <div class="col-xl-6">
              <single-select-person
                id="journaleintrag-person"
                :person="kontaktPerson"
                :label="$t('global.person')"
                editable
                :allowOpen="!neuerJournalEintrag"
                @confirmed="setPerson"
                :tabindex="1"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="journaleintrag.datum"
                  placeholder=" "
                  :show-icon="true"
                  :tabindex="2"
                  @update="(val) => (journaleintrag.datum = val)"
                />
                <label>{{ $t("global.date") }}</label>
              </div>
            </div>
            <div class="col-xl-3">
              <time-field
                :label="$t('global.Time')"
                placeholder=""
                :initial="journaleintrag.zeit"
                :tabindex="3"
                @update="(val) => (journaleintrag.zeit = val)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="journaleintrag.typ"
                  label="bezeichnung"
                  :options="typen"
                  :tabindex="6"
                  :placeholder="$t('global.type')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.type") }}</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="journaleintrag.status"
                  label="bezeichnung"
                  :options="stati"
                  :tabindex="7"
                  :placeholder="$t('global.status')"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.status") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="journaleintrag.bezeichnung"
                  class="form-control"
                />
                <label>{{ $t("global.dashboard_topic") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <ckeditor
                  :editor="editor"
                  v-model="journaleintrag.beschreibung"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="templateModal">
            {{ $t("global.selecttemplate") }}
          </button>
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button type="button" class="btn btn-primary" @click="uebernehmen">
            {{ $t("global.apply") }}
          </button>
        </div>
      </div>
    </div>
    <journal-eintrag-template-modal
      @journalEintragTemplateSelected="templateSelected"
    />
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import JournalEintragTemplateModal from "@/components/Journal/JournalEintragTemplateModal.vue";
import ClassicEditor from "/custom_ckeditor/build/ckeditor";
import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField";

import { LADE_AUSWAHLWERTE_JOURNAL } from "@/store/journal/actions.type";

export default {
  name: "JournalEintragModal",
  components: {
    ModalCloseButton,
    SingleSelectPerson,
    DatePicker,
    TimeField,
    JournalEintragTemplateModal,
  },
  props: {},
  data() {
    return {
      kontaktPerson: {},
      verantwortlicher: {},
      journaleintrag: { person: {}, verantwortlicher: {}, typ: {}, status: {} },
      neuerJournalEintrag: false,
      shown: false,
      editor: ClassicEditor,
      key: 0,
    };
  },
  computed: {
    typen() {
      if (this.$store) return this.$store.state.journal.journaleintragtypen;
      return [];
    },
    stati() {
      if (this.$store) return this.$store.state.journal.journaleintragstati;
      return [];
    },
    templates() {
      if (this.$store) return this.$store.state.journal.journaleintragtemplates;
      return [];
    },
  },
  watch: {},
  mounted() {
    if (this.typen.length == 0)
      this.$store.dispatch(`journal/${LADE_AUSWAHLWERTE_JOURNAL}`);

    this.$bus.$on("journaleintrag-modal-show", (data) => {
      this.journaleintrag = Object.assign({}, data);
      this.kontaktPerson = this.journaleintrag.person;
      this.verantwortlicher = this.journaleintrag.verantwortlicher;

      this.neuerJournalEintrag = data.neu ? true : false;
      if (data.neu) {
        let today = new Date();

        this.journaleintrag.datum = today.toLocaleDateString("de-CH", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        });
        this.journaleintrag.zeit = today.toLocaleTimeString("de-CH", {
          hour: "numeric",
          minute: "numeric",
        });

        this.journaleintrag.status = this.stati.find(
          (status) => status.id == "17FC5698C11"
        );
        this.journaleintrag.typ = this.typen.find(
          (typ) => typ.id == "17FC5693DCE"
        );
      }

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    templateModal() {
      this.$bus.$emit("journaleintrag-template-modal-show", {});
    },

    templateSelected(template) {
      this.journaleintrag.bezeichnung = template.bezeichnung;
      this.journaleintrag.beschreibung = template.beschreibung;

      this.journaleintrag.typ = template.typ;
      this.key++;
    },

    setPerson(person) {
      this.kontaktPerson = person;
    },

    setVerantwortlicher(person) {
      this.verantwortlicher = person;
    },

    openModal() {
      this.shown = true;

      $("#journaleintrag-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#journaleintrag-bearbeiten-modal").modal("hide");
    },

    uebernehmen() {
      this.journaleintrag.person = this.kontaktPerson;
      this.journaleintrag.verantwortlicher = this.verantwortlicher;

      console.log(this.journaleintrag.typ);

      if (this.neuerJournalEintrag) {
        this.$emit("journalEintragCreated", this.journaleintrag);
      } else {
        this.$emit("journalEintragChanged", this.journaleintrag);
      }

      this.closeModal();
    },
  },
};
</script>

<style></style>
