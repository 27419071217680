<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="
                  navbarTitel +
                  ' ' +
                  (anmeldung.person
                    ? $t('global.from') + anmeldung.person.personName
                    : '') +
                  (anmeldung.veranstaltung.id
                    ? $t('global.for') + anmeldung.veranstaltung.bezeichnung
                    : '')
                "
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4 mr-2">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>

                  <button
                    v-if="editable && anmeldungid"
                    class="btn btn-primary mr-2"
                    @click="showAnmeldungUebertragen"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-sign-out-alt"
                      class="mr-2"
                    />
                    <span>{{ $t("global.transferregistration") }}</span>
                  </button>
                  <button
                    v-if="
                      editable &&
                      anmeldungid &&
                      anmeldung.veranstaltung.veranstaltungstyp.istklasse ==
                        true
                    "
                    class="btn btn-primary mr-2"
                    @click="showLernzielvereinbarungBewertung"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-bullseye-arrow"
                      class="mr-2"
                    />
                    <span>{{ $t("global.learningobjectivesagreement") }}</span>
                  </button>
                  <!-- Lernzielvereinbarung Förderunterricht nur anzeigen bei Bildungsgantyp Förderunterricht des verknüpften Lehrgangs-->
                  <!--
                                      <button
                    v-if="
                      editable &&
                      anmeldungid &&
                      anmeldung.veranstaltung.veranstaltungstyp.istklasse ==
                        true &&
                      anmeldung.veranstaltung.lehrgang.bildungsgangtyp.id ==
                        '18356014D31'
                    "
                    class="btn btn-primary mr-2"
                    @click="showLernzielvereinbarungFoerderunterricht"
                  >
                  -->
                  <button
                    v-if="
                      editable &&
                      anmeldungid &&
                      anmeldung.veranstaltung.veranstaltungstyp.istklasse ==
                        true
                    "
                    class="btn btn-primary mr-2"
                    :disabled="!anmeldung.lernzielvereinbarungFU"
                    @click="showLernzielvereinbarungFoerderunterricht"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-bullseye-arrow"
                      class="mr-2"
                    />
                    <span>{{
                      $t("global.learningobjectivesagreementremedation")
                    }}</span>
                  </button>
                  <button
                    v-if="
                      editable &&
                      anmeldungid &&
                      anmeldung.veranstaltung.veranstaltungstyp.istklasse ==
                        true &&
                      anmeldung.kompetenzraster
                    "
                    class="btn btn-primary mr-2"
                    :disabled="
                      anmeldung.kompetenzraster
                        ? anmeldung.kompetenzraster.id != rasterID
                        : true
                    "
                    @click="showKompetenzrasterBewertung"
                  >
                    <font-awesome-icon
                      icon="fa-regular fa-bullseye-arrow"
                      class="mr-2"
                    />
                    <span>{{ $t("global.competencegrid") }}</span>
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_anmeldungen.delete && anmeldungid"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->

          <container-headline
            :headline="$t('global.masterdata')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <v-select
                              v-model="anmeldung.anmeldestatus"
                              label="bezeichnung"
                              v-bind:class="{ 'border-info': !passed }"
                              :options="anmeldestati"
                              :disabled="!editable"
                              :placeholder="$t('global.status')"
                            >
                              <span slot="no-options">{{
                                $t("global.taptosearch")
                              }}</span>
                            </v-select>
                            <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.status")
                            }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <v-select
                              v-model="anmeldung.anmeldetyp"
                              label="bezeichnung"
                              v-bind:class="{ 'border-info': !passed }"
                              :options="anmeldetypen"
                              :disabled="!editable"
                              :placeholder="$t('global.type')"
                            >
                              <span slot="no-options">{{
                                $t("global.taptosearch")
                              }}</span>
                            </v-select>
                            <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.type")
                            }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-person
                      id="anmeldung-von"
                      :person="anmeldung.person"
                      :label="$t('global.person')"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      required
                      :editable="editable"
                      @confirmed="setPerson"
                    />
                  </div>
                  <div class="col-xl-6">
                    <single-select-veranstaltung
                      id="anmeldung-veranstaltung"
                      :veranstaltung="anmeldung.veranstaltung"
                      :label="$t('global.event')"
                      :allowOpen="berechtigungen.m_bildung.read"
                      :editable="editable"
                      required
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.abschluss"
                        label="bezeichnung"
                        :options="abschluesse"
                        :disabled="!editable"
                        :placeholder="$t('global.degree')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.degree") }}</label>
                    </div>
                  </div>
                  <div
                    v-if="
                      editable &&
                      anmeldungid &&
                      anmeldung.veranstaltung.veranstaltungstyp.istklasse ==
                        false
                    "
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.niveau"
                        label="bezeichnung"
                        :options="niveaus"
                        :disabled="!editable"
                        placeholder="Niveau"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>Niveau</label>
                    </div>
                  </div>
                  <div
                    v-if="
                      editable &&
                      anmeldungid &&
                      anmeldung.veranstaltung.veranstaltungstyp.istklasse ==
                        true
                    "
                    class="col-xl-6"
                  >
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.kompetenzraster"
                        label="bezeichnung"
                        :options="kompetenzrasters"
                        :disabled="!editable"
                        :placeholder="$t('global.competencegrid')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.competencegrid") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6"></div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.anmeldegrund"
                        multiple
                        label="bezeichnung"
                        :options="anmeldegruende"
                        :disabled="!editable"
                        :placeholder="$t('global.reasonforregistration')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.reasonforregistration") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div v-if="anmeldung" class="form-group">
                      <v-select
                        v-model="anmeldung.abmeldegrund"
                        multiple
                        label="bezeichnung"
                        :options="abmeldegruende"
                        :disabled="!editable"
                        :placeholder="$t('global.reasonforderegistration')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.reasonforderegistration") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.ablehnungsgrund"
                        label="bezeichnung"
                        :options="ablehnunggruende"
                        :disabled="!editable"
                        :placeholder="$t('global.reasonforrejection')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.reasonforrejection") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-check">
                      <input
                        :disabled="!editable"
                        type="checkbox"
                        v-model="anmeldung.nicht_subventioniert"
                        id="nicht_subventioniert"
                        class="form-check-input"
                      />
                      <label>{{ $t("global.notsubsidized") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6"></div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-check">
                      <input
                        :disabled="!editable"
                        type="checkbox"
                        v-model="anmeldung.datenschutz"
                        id="datenschutz"
                        class="form-check-input"
                      />
                      <label>{{ $t("global.dataprivacy") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-check">
                      <input
                        :disabled="!editable"
                        type="checkbox"
                        v-model="anmeldung.schweigepflicht"
                        id="schweigepflicht"
                        class="form-check-input"
                      />
                      <label>{{ $t("global.confidentiality") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="anmeldung.bemerkung"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      ></textarea>
                      <label>{{ $t("global.comment") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
          <!-- START Verlauf -->
          <div v-if="anmeldungid">
            <container-headline
              :headline="$t('global.process')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.anmeldungsdatum"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.anmeldungsdatum = val)"
                        />
                        <label>{{ $t("global.registration") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.anmeldungsdatum_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.abgemeldet"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.abgemeldet = val)"
                        />
                        <label>{{ $t("global.canceld") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.abgemeldet_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.warteliste"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.warteliste = val)"
                        />
                        <label>{{ $t("global.waitinglist") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.warteliste_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.gebucht"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.gebucht = val)"
                        />
                        <label>{{ $t("global.booked") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.gebucht_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.abgelehnt"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.abgelehnt = val)"
                        />
                        <label>{{ $t("global.rejected") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.abgelehnt_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.besucht"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.besucht = val)"
                        />
                        <label>{{ $t("global.visited") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.besucht_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.zurueckgestellt"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.zurueckgestellt = val)"
                        />
                        <label>{{ $t("global.deferred") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.zurueckgestellt_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.noteneingabe"
                          :disabled="!editable"
                          @update="(val) => (anmeldung.noteneingabe = val)"
                        />
                        <label>{{ $t("global.scoreinput") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <div class="form-group">
                        <input
                          v-model.trim="anmeldung.noteneingabe_uhrzeit"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.Time") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Verlauf -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Finanzen und Rechnung -->
          <container-headline
            :headline="$t('global.financesandinvoice')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.verrechnung"
                        multiple
                        label="bezeichnung"
                        :options="verrechnungen"
                        :disabled="!editable"
                        :placeholder="$t('global.offsetting')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.offsetting") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.zahlungsplan"
                        label="bezeichnung"
                        :options="zahlungsplaene"
                        :disabled="!editable"
                        :placeholder="$t('global.paymentschedule')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.paymentschedule") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6"></div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="anmeldung.rechnungshinweis"
                        class="form-control"
                        placeholder=" "
                        :disabled="!editable"
                      ></textarea>
                      <label>{{ $t("global.invoicenotice") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="anmeldung.adresszusatz"
                        class="form-control"
                        placeholder=" "
                        :disabled="!editable"
                      ></textarea>
                      <label>{{ $t("global.addresssuffix") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Finanzen und Rechnung -->
          <!--START Rechnungsplit -->
          <container-headline
            :headline="$t('global.invoicesplit')"
            :col="6"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-5">
                    <single-select-firma
                      id="firma-rechnungs-split"
                      :rechnungstraeger="anmeldung.person.id"
                      :firma="anmeldung.firma_rechnungssplit"
                      :key="'firmakey ' + firmakey"
                      :editable="editable"
                      :allowOpen="true"
                      :label="'2. ' + $t('global.invoicepartycompany')"
                      @confirmed="setFirmaRechnungssplit"
                    />
                  </div>
                  <div class="col-xl-5">
                    <single-select-person
                      id="person-rechnungs-split"
                      :person="anmeldung.person_rechnungssplit"
                      :firma="anmeldung.firma_rechnungssplit"
                      :label="'2. ' + $t('global.invoicepartyperson')"
                      :allowOpen="true"
                      :rollen="filterRollen"
                      :editable="editable"
                      @confirmed="setPersonRechungssplit"
                    />
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <input
                        v-model.number="anmeldung.anteil"
                        class="form-control"
                        placeholder=" "
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.shareinpercent") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="anmeldung.adresse_rechnungssplit"
                        label="adresseKomplett"
                        :options="adressenVerbObjects"
                        :disabled="!editable"
                        :placeholder="$t('global.addressesinvoicesplit')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.addressesinvoicesplit") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <textarea
                        :readonly="!editable"
                        v-model.trim="anmeldung.rg_adresse_zusatz"
                        class="form-control"
                        placeholder=" "
                        rows="4"
                      />
                      <label>{{ $t("global.invoiceaddressaddition") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--ENDE Rechnungsplit -->
          <!-- START Bildungsportfolio -->
          <div v-if="anmeldungid">
            <container-headline
              :headline="$t('global.educationportfolio')"
              :col="6"
            ></container-headline>
            <div class="row mb-3">
              <div class="col-xl-12 block br-t-l-0">
                <div class="eingabe-panel-kopf p-3">
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.zugelassen_am"
                          :disabled="!editable"
                          placeholder=" "
                          @update="(val) => (anmeldung.zugelassen_am = val)"
                        />
                        <label>{{ $t("global.approvedon") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.bestanden_am"
                          :disabled="!editable"
                          placeholder=" "
                          @update="(val) => (anmeldung.bestanden_am = val)"
                        />
                        <label>{{ $t("global.passedon") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.zertifizierung"
                          placeholer=" "
                          :disabled="!editable"
                          @update="(val) => (anmeldung.zertifizierung = val)"
                        />
                        <label>{{ $t("global.certification") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="anmeldung.diplom_versendet"
                          placeholder=" "
                          :disabled="!editable"
                          @update="(val) => (anmeldung.diplom_versendet = val)"
                        />
                        <label>{{ $t("global.diplomasent") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Bildungsportfolio -->
        </div>
        <!-- ENDE Rechte Seite -->
        <div v-if="anmeldungid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'anmeldung.anzeige',
                      params: { anmeldungid: anmeldungid, anzeige: 0 },
                    }"
                    >{{ $t("global.correspondence") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'anmeldung.anzeige',
                      params: { anmeldungid: anmeldungid, anzeige: 1 },
                    }"
                    >{{ $t("global.dashboard_mails") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'anmeldung.anzeige',
                      params: { anmeldungid: anmeldungid, anzeige: 2 },
                    }"
                    >{{ $t("global.presence") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'anmeldung.anzeige',
                      params: {
                        anmeldungid: anmeldungid,
                        anzeige: 3,
                      },
                    }"
                    >{{ $t("global.clearingitems") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 4 }"
                    :to="{
                      name: 'anmeldung.anzeige',
                      params: { anmeldungid: anmeldungid, anzeige: 4 },
                    }"
                    >{{ $t("global.documents") }}</router-link
                  >
                </li>
                <!--
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 4 }"
                  :to="{
                    name: 'anmeldung',
                    params: { anmeldungid: anmeldungid, anzeige: 4 },
                  }"
                  >Finanzen</router-link
                >
              </li>
              --></ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <!-- Start Kommunikation -->
                  <korrespondenzliste
                    :anmeldung="anmeldungid"
                    :person="anmeldung.person.id"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div v-show="anzeige === 1">
                  <!-- Start Kommunikation -->
                  <mailliste
                    :anmeldung="anmeldungid"
                    :person="anmeldung.person.id"
                    :mailadressen="mailadressen"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div v-show="anzeige === 2">
                  <!-- Start Präsenz -->
                  <anwesenheitsliste
                    :anmeldung="anmeldungid"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                  />
                  <!-- Ende Präsenz -->
                </div>
                <div v-show="anzeige === 3">
                  <bildungsgang-verrechnungspositionen
                    :anmeldung="anmeldung"
                    :shown="anzeige == 3 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div v-show="anzeige === 4">
                  <dokumentenliste
                    :person="anmeldung.person.id"
                    :dokumentEditable="editable"
                    :shown="anzeige == 4 ? true : false"
                    :key="componentKey"
                  />
                </div>
                <!--
              <div v-show="anzeige === 4">

                TODO: Auftrag und Verrechnungsposition (Listen einfügen) !

              </div>
              --></div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <anmeldung-uebertragen-modal
      v-if="anmeldungid"
      :id="AnmeldungUebertragenModalId"
      :allowCopy="true"
      @copied="anmeldungGeaendert"
      @changed="anmeldungGeaendert"
    ></anmeldung-uebertragen-modal>

    <lernzielvereinbarung-bewertung-modal
      v-if="anmeldungid"
      :id="LernzielvereinbarungBewertungModalId"
      :anmeldungId="anmeldungid"
      @lernzieleDrucken="lernzieleDrucken()"
      @lernzieleVorlageDrucken="lernzieleVorlageDrucken()"
    ></lernzielvereinbarung-bewertung-modal>

    <lernzielvereinbarung-bewertung-foerderunterricht-modal
      v-if="anmeldungid"
      :id="LernzielvereinbarungBewertungFUModalId"
      :lernzielvereinbarungFUID="anmeldung.lernzielvereinbarungFU"
      @lernzieleDrucken="lernzieleDrucken()"
      @lernzieleVorlageDrucken="lernzieleVorlageDrucken()"
    ></lernzielvereinbarung-bewertung-foerderunterricht-modal>

    <kompetenzraster-bewertung-modal
      v-if="anmeldungid"
      :id="KompetenzrasterBewertungModalId + anmeldungid"
      @kompetenzrasterDrucken="kompetenzrasterDrucken()"
      @kompetenzrasterVorlageDrucken="kompetenzrasterVorlageDrucken()"
    ></kompetenzraster-bewertung-modal>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="anmeldung-loeschen-modal"
      @confirmed="anmeldungLoeschen"
    />

    <pdf-layer
      id="pdf-layer-anmeldung"
      ref="pdfLayerAnmeldung"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <change-watcher-alert />
  </div>
</template>

<script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";
import server from "@/server";

import HeadMenu from "@/components/HeadMenu";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import Navbar from "@/components/Navbar";

import AnmeldungUebertragenModal from "@/components/Modals/AnmeldungUebertragenModal";
import LernzielvereinbarungBewertungModal from "@/components/Modals/LernzielvereinbarungBewertungModal";
import LernzielvereinbarungBewertungFoerderunterrichtModal from "@/components/Modals/LernzielvereinbarungBewertungFoerderunterrichtModal";
import KompetenzrasterBewertungModal from "@/components/Modals/KompetenzrasterBewertungModal";

import Korrespondenzliste from "@/components/Reiter/Korrespondenzliste";
import Mailliste from "@/components/Reiter/Mailliste";
import Anwesenheitsliste from "@/components/Reiter/Anwesenheitsliste";
import Dokumentenliste from "@/components/Reiter/Dokumentenliste";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import BildungsgangVerrechnungspositionen from "@/components/Reiter/BildungsgangVerrechnungspositionen";

import LoeschenModal from "@/components/Modals/LoeschenModal.vue";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import store from "@/store";
import { LADE_AUSWAHLWERTE_ANMELDUNG } from "@/store/anmeldung/actions.type";

import { LADE_KATEGORIEN } from "@/store/rechnung/actions.type";

export default {
  name: "Anmeldung",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    Korrespondenzliste,
    Mailliste,
    Anwesenheitsliste,
    Dokumentenliste,
    DatePicker,
    AnmeldungUebertragenModal,
    LernzielvereinbarungBewertungModal,
    LernzielvereinbarungBewertungFoerderunterrichtModal,
    KompetenzrasterBewertungModal,
    SingleSelectPerson,
    SingleSelectVeranstaltung,
    LoeschenModal,
    SingleSelectFirma,
    BildungsgangVerrechnungspositionen,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    anmeldungid: {
      type: String,
      default: null,
    },
    personid: {
      type: String,
      default: null,
    },
    veranstaltungid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    kategorieProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      componentKey: 0,
      anmeldungen: [],
      adressenVerbObjects: [],
      adressen: [],
      adresse_rechnungssplit: null,
      filterRollen: [], //this.$CONST("ROLLEN").STUDENTEN,
      personId: "",
      firmaId: "",
      firmakey: 0,
      pdfPath: "",
      rasterID: null,
      anmeldung: {
        person: { personName: this.$t("global.notlinked") },
        person_rechnungssplit: { personName: this.$t("global.notlinked") },
        firma_rechnungssplit: { name: this.$t("global.notlinked") },
        veranstaltung: {
          bezeichnung: this.$t("global.notlinked"),
          lehrgang: { kompetenzraster: { id: "" } },
        },
        adresse_rechnungssplit: {},
        zahlungsplan: {
          id: "179230F9155" /*education-sample.batix.ch: 174E84CA3CA  */,
          bezeichnung: "Einmalzahlung",
          anzahl_raten: 1,
          sortierung: 10,
          rundung: 10,
          periodizitaet: 1,
          startrate: null,
          minimalrate: 10,
          standard: true,
          label: "1x (1 Tage)",
        },
        lernzielvereinbarungFU: "",
      },
      mailadressen: [],
      AnmeldungUebertragenModalId: "anmeldung-uebertragen-modal",
      LernzielvereinbarungBewertungModalId:
        "lernzielvereinbarung-bewertung-modal",
      LernzielvereinbarungBewertungFUModalId:
        "lernzielvereinbarung-bewertung-fu-modal",
      KompetenzrasterBewertungModalId: "kompetenzraster-bewertung-modal",
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id || this.anmeldungid) {
        return this.$t("global.registration");
      } else {
        return this.$t("global.newregistration");
      }
    },
    anmeldestati: {
      get() {
        return this.$store.state.anmeldung.anmeldestati;
      },
    },
    anmeldetypen: {
      get() {
        return this.$store.state.anmeldung.anmeldetypen;
      },
    },
    ablehnunggruende: {
      get() {
        return this.$store.state.anmeldung.ablehnunggruende;
      },
    },
    anmeldegruende: {
      get() {
        return this.$store.state.anmeldung.anmeldegruende;
      },
    },
    abmeldegruende: {
      get() {
        return this.$store.state.anmeldung.abmeldegruende;
      },
    },
    abschluesse: {
      get() {
        return this.$store.state.anmeldung.abschluesse;
      },
    },
    verrechnungen: {
      get() {
        return this.$store.state.anmeldung.verrechnungen;
      },
    },
    zahlungsplaene: {
      get() {
        return this.$store.state.rechnung.zahlungsplaene;
      },
    },
    kompetenzrasters: {
      get() {
        return this.$store.state.anmeldung.kompetenzrasters;
      },
    },
    niveaus: {
      get() {
        return this.$store.state.anmeldung.niveaus;
      },
    },
  },
  watch: {
    anmeldestati: function (value) {
      if (value && !this.anmeldung.anmeldestatus) {
        let status = value.find(
          (status) => status.id == this.$CONST("ANMELDESTATI").REGISTERED
        );
        if (status) this.anmeldung.anmeldestatus = status;
      }
    },
    anmeldetypen: function (value) {
      if (value && !this.anmeldung.anmeldetyp) {
        let typ = value.find(
          (typ) => typ.id == this.$CONST("ANMELDETYPEN").REGULAR
        );

        if (typ) this.anmeldung.anmeldetyp = typ;
      }
    },
    async personId(val) {
      if (val) {
        await this.ladeAdressen(val);
      }
    },
    async firmaId(val) {
      if (val) {
        await this.ladeAdressen(null, val);
      }
    },
  },

  created() {},

  mounted: function () {
    this.loading = true;

    if (this.anmeldungid) {
      this.ladeAnmeldung(this.anmeldungid);
      this.anmeldungen.push(this.anmeldungid);
    }

    if (this.personid) {
      this.loading = true;

      Api.get("geschaeftspartner/", { params: { id: this.personid } })
        .then((response) => {
          if (response.data.id) {
            this.anmeldung.person = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });

      this.editable = true;
    }

    if (this.veranstaltungid) {
      this.loading = true;

      Api.get("veranstaltungen/", {
        params: { id: this.veranstaltungid },
      })
        .then((response) => {
          if (response.data.id) {
            this.anmeldung.veranstaltung = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });

      this.editable = true;
    }

    if (this.veranstaltungid || this.personid) {
      // Anmeldung wurde von einer Veranstaltung oder einer Person erstellt
      this.initChangeWatcher(this.anmeldung);
    }

    if (this.anmeldestati.length == 0)
      this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);
    else {
      let anmeldungStatusAngemeldet = this.$CONST("ANMELDESTATI").REGISTERED;

      this.anmeldung.anmeldestatus = this.anmeldestati.find(
        (status) => status.id == anmeldungStatusAngemeldet
      );

      let anmeldungTypRegulaer = this.$CONST("ANMELDETYPEN").REGULAR;

      this.anmeldung.anmeldetyp = this.anmeldetypen.find(
        (typ) => typ.id == anmeldungTypRegulaer
      );
    }

    if (this.zahlungsplaene.length == 0)
      this.$store.dispatch(`rechnung/${LADE_KATEGORIEN}`);

    this.loading = false;
  },

  methods: {
    async ladeAdressen(personId, firmaId) {
      if (personId || firmaId) {
        let response = await Api.get("geschaeftspartner/adressverbindung/", {
          params: { gpid: personId, fiid: firmaId },
        });
        this.adressen = response.data;

        for (let i = 0; i < this.adressen.length; i++) {
          if (this.adressen[i].adresse) {
            let found = false;
            this.adressenVerbObjects.forEach((adr) => {
              if (adr.id == this.adressen[i].adresse.id) {
                found = true;
              }
            });
            if (!found) {
              this.adressenVerbObjects.push(this.adressen[i].adresse);
            }
          }
        }
      }
    },

    ladeAnmeldung(id) {
      Api.get("anmeldung/", { params: { id: id } })
        .then((response) => {
          this.inititalizeAnmeldung(response.data);
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    abbrechen() {
      this.$router.push({ name: "anmeldungen-liste" });
    },

    updateDokumentenliste() {
      this.componentKey++;
    },

    inititalizeAnmeldung(anmeldung) {
      if (anmeldung) {
        this.anmeldung = anmeldung;
        this.anmeldung.veranstaltung = anmeldung.veranstaltung;

        if (this.anmeldung.veranstaltung?.lehrgang) {
          this.anmeldung.veranstaltung.lehrgang =
            anmeldung.veranstaltung.lehrgang;
          this.anmeldung.veranstaltung.lehrgang.kompetenzraster =
            anmeldung.veranstaltung.lehrgang?.kompetenzraster;
        }

        this.rasterID = anmeldung.kompetenzraster?.id;

        if (!anmeldung.person)
          anmeldung.person = { personName: this.$t("global.notlinked") };
        if (!anmeldung.person_rechnungssplit) {
          anmeldung.person_rechnungssplit = {
            personName: this.$t("global.notlinked"),
          };
        }
        if (!anmeldung.firma_rechnungssplit) {
          anmeldung.firma_rechnungssplit = {
            name: this.$t("global.notlinked"),
          };
        }

        this.editable =
          this.anmeldestatus != this.$CONST("ANMELDESTATI").REGISTERED
            ? true
            : false;

        this.personId = anmeldung.person_rechnungssplit.id;

        if (this.anmeldung.firma_rechnungssplit?.id) {
          this.firmaId = anmeldung.firma_rechnungssplit.id;
        }

        if (this.anmeldung.adresse_rechnungssplit) {
          this.adresse_rechnungssplit = this.anmeldung.adresse_rechnungssplit;
        }
        if (!anmeldung.veranstaltung)
          anmeldung.veranstaltung = {
            bezeichnung: this.$t("global.notlinked"),
          };

        this.initChangeWatcher(this.anmeldung);
      } else {
        console.error("anmeldung ist null");
      }

      if (this.anmeldung.person.id) {
        Api.get("mailadresse/", {
          params: { gpid: this.anmeldung.person.id },
        }).then((response) => {
          this.mailadressen = response.data;
        });
      }

      this.editable = !this.berechtigungen.m_anmeldungen.update
        ? false
        : this.editable;
    },

    speichern() {
      if (this.loading) return;

      var json = Object.assign({}, this.anmeldung);

      //*** Einzelrelationen auf ID reduzieren ***/

      // TODO in Anmeldung Klasse überall die Hilfsroutine rein nehmen dann hier aufräumen

      json.veranstaltung = this.anmeldung.veranstaltung?.id;

      json.anmeldestatus = this.anmeldung.anmeldestatus?.id;
      json.anmeldetyp = this.anmeldung.anmeldetyp?.id;
      json.person = this.anmeldung.person?.id;
      json.ablehnungsgrund = this.anmeldung.ablehnungsgrund?.id;
      json.abschluss = this.anmeldung.abschluss?.id;
      json.zahlungsplan = this.anmeldung.zahlungsplan?.id;
      json.adresse_rechnungssplit = this.anmeldung.adresse_rechnungssplit?.id;
      /*
      if (!json.lernzielvereinbarungFU) {
        json.lernzielvereinbarungFU = {};
      }
      json.lernzielvereinbarungFU.zuletzt_bearbeitet_von =
        this.angemeldeterMitarbeiter?.person?.id;
*/
      if (this.anmeldung.anmeldegrund)
        json.anmeldegrund = this.anmeldung.anmeldegrund.map((amg) => {
          return amg.id;
        });

      if (this.anmeldung.abmeldegrund)
        json.abmeldegrund = this.anmeldung.abmeldegrund.map((abg) => {
          return abg.id;
        });

      if (this.anmeldung.verrechnung)
        json.verrechnung = this.anmeldung.verrechnung.map((avr) => {
          return avr.id;
        });

      // Zeitstempel setzen

      let now = new Date();

      switch (json.anmeldestatus) {
        case this.$CONST("ANMELDESTATI").BOOKED:
          if (!json.gebucht) {
            json.gebucht =
              now.getDate() +
              "." +
              (now.getMonth() + 1) +
              "." +
              now.getFullYear();
            json.gebucht_uhrzeit = now.getHours() + ":" + now.getMinutes();
          }
          break;
        case this.$CONST("ANMELDESTATI").REGISTERED:
          if (!json.anmeldungsdatum) {
            json.anmeldungsdatum =
              now.getDate() +
              "." +
              (now.getMonth() + 1) +
              "." +
              now.getFullYear();
            json.anmeldungsdatum_uhrzeit =
              now.getHours() + ":" + now.getMinutes();
          }
          break;
        case this.$CONST("ANMELDESTATI").WAITINGLIST:
          if (!json.warteliste) {
            json.warteliste =
              now.getDate() +
              "." +
              (now.getMonth() + 1) +
              "." +
              now.getFullYear();
            json.warteliste_uhrzeit = now.getHours() + ":" + now.getMinutes();
          }
          break;
        case this.$CONST("ANMELDESTATI").CANCELLED:
          if (!json.abgemeldet) {
            json.abgemeldet =
              now.getDate() +
              "." +
              (now.getMonth() + 1) +
              "." +
              now.getFullYear();
            json.abgemeldet_uhrzeit = now.getHours() + ":" + now.getMinutes();
          }
          break;
        case this.$CONST("ANMELDESTATI").DEFERRED:
          if (!json.zurueckgestellt) {
            json.zurueckgestellt =
              now.getDate() +
              "." +
              (now.getMonth() + 1) +
              "." +
              now.getFullYear();
            json.zurueckgestellt_uhrzeit =
              now.getHours() + ":" + now.getMinutes();
          }
          break;
        case this.$CONST("ANMELDESTATI").REFUSED:
          if (!json.abgelehnt) {
            json.abgelehnt =
              now.getDate() +
              "." +
              (now.getMonth() + 1) +
              "." +
              now.getFullYear();
            json.abgelehnt_uhrzeit = now.getHours() + ":" + now.getMinutes();
          }
          break;
        case this.$CONST("ANMELDESTATI").ATTENDED:
          if (!json.besucht) {
            json.besucht =
              now.getDate() +
              "." +
              (now.getMonth() + 1) +
              "." +
              now.getFullYear();
            json.besucht_uhrzeit = now.getHours() + ":" + now.getMinutes();
          }
          break;
      }

      this.loading = true;
      if (!this.anmeldungid) {
        Api.post("anmeldung/", json)
          .then((response) => {
            //this.inititalizeAnmeldung(response.data);

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.registrationsavedsuccessfully"),
            });

            this.disableChangeWatcher();

            this.$router.replace({
              name: "anmeldung",
              params: { anmeldungid: response.data.id },
            });
            this.inititalizeAnmeldung(response.data);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("anmeldung/", json, { params: { id: this.anmeldung.id } })
          .then((response) => {
            this.inititalizeAnmeldung(response.data);

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.registrationsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "anmeldung-loeschen-modal");
    },

    anmeldungLoeschen() {
      Api.delete("anmeldung/", {
        params: { id: this.anmeldungid },
      }).then(() => {
        this.$router.push({ name: "anmeldungen-liste" });
      });
      this.$notify({
        type: "success",
        title: this.$t("global.actionsuccessful"),
        text: this.$t("global.registrationdeleted"),
      });
    },

    setPerson(person) {
      this.anmeldung.person = person;
    },

    setPersonRechungssplit(person) {
      //this.personRechnungsplitKey++;
      this.anmeldung.person_rechnungssplit = person;
      this.ladeAdressen(person?.id, null);
    },

    setFirmaRechnungssplit(firma) {
      this.firmakey++;
      this.anmeldung.firma_rechnungssplit = firma;
      this.ladeAdressen(null, firma?.id);
    },

    setVeranstaltung(veranstaltung) {
      this.anmeldung.veranstaltung = veranstaltung;
    },

    oeffneVeranstaltung() {
      let thisPath = "/bildung/lehrgang/";
      if (
        this.anmeldung.veranstaltung.veranstaltungstyp.id ==
        this.$CONST("VERANSTALTUNGSTYPEN").MODULE
      )
        thisPath = "/bildung/fach/";
      else if (
        this.anmeldung.veranstaltung.veranstaltungstyp.id ==
        this.$CONST("VERANSTALTUNGSTYPEN").STUDIES
      )
        thisPath = "/bildung/klasse/";

      // TODO: Alle anderen Tpyen noch einbauen

      this.$router.push({
        path: thisPath + this.anmeldung.veranstaltung.id,
      });
    },

    showAnmeldungUebertragen() {
      this.$bus.$emit("openAnmeldungUebertragenModal", [this.anmeldungid]);
    },

    showLernzielvereinbarungBewertung() {
      this.$bus.$emit("openLernzielvereinbarungBewertungModal", {
        modalId: "lernzielvereinbarung-bewertung-modal",
        anmeldungId: this.anmeldungid,
      });
    },

    showLernzielvereinbarungFoerderunterricht() {
      this.$bus.$emit("openLernzielvereinbarungBewertungFUModal", {
        modalId: "lernzielvereinbarung-bewertung-fu-modal",
        anmeldungId: this.anmeldungid,
      });
    },

    showKompetenzrasterBewertung() {
      this.$bus.$emit("openKompetenzrasterBewertungModal", {
        id: "kompetenzraster-bewertung-modal" + this.anmeldungid,
        anmeldungId: this.anmeldungid,
        anmeldung: this.anmeldung,
      });
    },

    anmeldungGeaendert(id) {
      if (id) {
        if (id != this.anmeldungid) {
          this.$router.push({
            name: "anmeldung",
            params: { anmeldungid: id, anzeige: 0 },
          });
        } else {
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.registrationsuccessfullytransferred"),
          });
        }
      } else {
        this.$router.push({
          name: "anmeldungen-liste",
        });
      }
    },
    lernzieleDrucken() {
      let filename =
        "Lernziele_" +
        this.anmeldung.person.personName.replace(/\s/g, "") +
        "_" +
        new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/lernziele.htm&ids=${this.anmeldungid}`;

      this.$refs.pdfLayerAnmeldung.show();
    },

    lernzieleVorlageDrucken() {
      let filename =
        "LernzieleVorlage_" +
        this.anmeldung.person.personName.replace(/\s/g, "") +
        "_" +
        new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/lernzieleVorlage.htm&ids=${this.anmeldungid}`;

      this.$refs.pdfLayerAnmeldung.show();
    },

    kompetenzrasterDrucken() {
      let filename =
        "Kompetenzraster_" +
        this.anmeldung.person.personName.replace(/\s/g, "") +
        "_" +
        new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/kompetenzraster.htm&ids=${this.anmeldungid}&sgid=${this.anmeldung.kompetenzraster.id}&date=${this.anmeldung.veranstaltung.periodennummer}`;

      this.$refs.pdfLayerAnmeldung.show();
    },

    kompetenzrasterVorlageDrucken() {
      let filename =
        "KompetenzrasterVorlage_" +
        this.anmeldung.person.personName.replace(/\s/g, "") +
        "_" +
        new Intl.DateTimeFormat("ch").format(new Date());
      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=${filename}.pdf&report=/pdfgenerator/kompetenzrasterVorlage.htm&ids=${this.anmeldungid}&sgid=${this.anmeldung.kompetenzraster.id}&date=${this.anmeldung.veranstaltung.periodennummer}`;

      this.$refs.pdfLayerAnmeldung.show();
    },
  },
};
</script>

<style></style>
