<template>
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div
      class="modal-dialog modal-fluid modal-lernzielvereinbarungBewertungModal"
    >
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">{{ $t("global.competencegrid") }}</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-12">
            <!-- PRUEFE DATEN -->
            <div
              class="row"
              v-if="isBusy || ladeFehler || !this.kompetenzrasterBewertung.id"
            >
              <div class="text-center text-primary m-auto my-2" v-if="isBusy">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              <div
                class="text-center text-danger m-auto my-2"
                v-if="ladeFehler && !isBusy"
              >
                <strong>{{ $t("global.errorwhileloading") }}</strong>
              </div>
              <div
                class="text-center text-primary m-auto my-2"
                v-if="!this.kompetenzrasterBewertung.id && !isBusy"
              >
                <strong>{{ $t("global.nodataavailable") }}</strong>
              </div>
            </div>

            <!-- TABELLE -->
            <div
              class="row px-4 mt-4"
              v-if="!isBusy && !ladeFehler && this.kompetenzrasterBewertung.id"
            >
              <!-- BUTTONS SEKTIONEN EIN- & AUSBLENDEN -->
              <div class="col-xl-12 d-flex">
                <div
                  v-for="(fach, index) in kompetenzrasterBewertung.semester[
                    dieseAnmeldung.veranstaltung.periodennummer - 1
                  ].kompFaecher"
                  :key="fach.id"
                  class="my-4"
                >
                  <div
                    class="btn mr-2"
                    :class="
                      faecherAnzeige[index] ? 'btn-primary' : 'btn-secondary'
                    "
                    @click="sektionAnzeigen('fach', index)"
                    :key="buttonKey"
                  >
                    {{ fach.bezeichnung }}
                  </div>
                </div>
                <div
                  v-for="(
                    semester, semIndex
                  ) in kompetenzrasterBewertung.semester"
                  :key="semester.id"
                >
                  <div
                    v-for="bereich in kompetenzrasterBewertung.semester[
                      semIndex
                    ].kompBereiche"
                    :key="bereich.id"
                    class="my-4"
                  >
                    <div
                      class="btn mr-2"
                      :class="
                        bereicheAnzeige[semIndex]
                          ? 'btn-primary'
                          : 'btn-secondary'
                      "
                      @click="sektionAnzeigen('bereich', semIndex)"
                      :key="buttonKey"
                    >
                      {{ bereich.bezeichnung }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- INFO -->
              <div class="col-xl-12">
                <h4 class="mb-4">
                  {{ $t("global.pracompetencegrid") }}
                  {{ kompetenzrasterBewertung.semester[0].bezeichnung }}
                </h4>
                <div class="row mt-2 bg-primary text-white font-weight-bold">
                  <div class="col-4 p-3 border border-dark">
                    {{ $t("global.student") }}
                  </div>
                  <div
                    v-if="this.dieseAnmeldung"
                    class="col-8 p-3 border border-dark"
                  >
                    {{ this.dieseAnmeldung.person.personName }}
                  </div>
                </div>
                <!--<div class="row">
                  <div
                    class="
                      col-4
                      p-3
                      border border-dark border-bottom-0 border-right-0
                    "
                  >
                    Datum
                  </div>
                  <div class="col-4 p-3 border border-bottom-0 border-dark">
                    <date-picker
                      date-format="dd.mm.yy"
                      :initial="
                        kompetenzrasterBewertung.lernziele[0].bewertung
                          .datum
                      "
                      placeholder=" "
                      :show-icon="true"
                      :in-modal="true"
                      @update="(val) => setDate(val)"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 p-3 border border-dark border-right-0">
                    Lehrpersonen
                  </div>
                  <div
                    class="
                      lernzielbewertungFaecher
                      col-4
                      p-0
                      border border-dark border-right-0
                    "
                  >
                    <div
                      class="row px-3"
                      v-for="(fachbez, index) in kompetenzrasterBewertung
                        .info.faecher"
                      :key="index"
                    >
                      <div
                        class="col-12 py-3 border-bottom border-dark"
                        style="height: 58px"
                      >
                        {{ fachbez.bezeichnung }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      lernzielbewertungFaecher
                      col-4
                      p-0
                      border border-dark
                    "
                  >
                    <div
                      class="row px-3"
                      v-for="(fachver, index) in kompetenzrasterBewertung
                        .info.faecher"
                      :key="index"
                    >
                      <div
                        class="col-12 py-2 border-bottom border-dark"
                        style="height: 58px"
                      >
                        <v-select
                          v-model="fachver.verantwortlicher"
                          :options="fachver.verantwortliche"
                          label="name"
                          placeholder="Keine Lehrperson verknüpft"
                          :clearable="false"
                        >
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>-->
              </div>
              <!-- KOMPETENZEN FORTSCHRITTE -->
              <!-- <div class="col-xl-12 mt-5 px-0">
                <b-table
                  class="bg-transparent text-primary"
                  bordered
                  ref="seletableTable"
                  :items="kompetenzrasterBewertung.fortschritte"
                  :fields="fortschritteFields"
                  :table-variant="'dark'"
                >
                  <template v-slot:cell(bezeichnung)="row">
                    <div>{{ row.item.bezeichnung }}</div>
                  </template>

                  <template v-slot:cell(bewertung.lernende)="row">
                    <div class="m-0 p-0">
                      <textarea
                        style="min-width: 100%"
                        v-model="row.item.bezeichnung"
                        placeholder="Kompetenzen Fortschritte Lernende/r"
                        max-rows="2"
                        rows="2"
                        type="text"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(bewertung.lehrperson)="row">
                    <div class="m-0 p-0">
                      <textarea
                        style="min-width: 100%"
                        v-model="row.item.sektionen.bezeichnung"
                        placeholder="Kompetenzen Fortschritte Lehrperson"
                        max-rows="2"
                        rows="2"
                        type="text"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(bewertung.kuerzel)="row">
                    <div class="m-0 p-0">
                      <input
                        style="min-width: 100%"
                        v-model="row.item.sektionen.subSektionen.bezeichnung"
                        placeholder="Kürzel Lehrperson"
                        type="text"
                      />
                    </div>
                  </template>
                </b-table>
              </div>
               LERNZIELE -->

              <!-- <div class="col-xl-12 mt-5 px-0">
                <b-table
                  class="bg-transparent text-primary"
                  bordered
                  ref="seletableTable"
                  :items="kompetenzrasterBewertung.semester[0].kompBereiche[0].sektionen"
                  :fields="lernzieleFields"
                  :table-variant="'dark'"
                >
                  <template v-slot:cell(bezeichnung)="row">
                    <div>{{ row.item.bezeichnung }}</div>
                  </template>

                  
                  <template v-slot:cell(bezeichnungSektion)="row">
                    <div class="m-0 p-0">
                      <textarea
                        style="min-width: 100%"
                        v-model="row.item.bezeichnung"
                        placeholder="Lernziele Lernende/r"
                        max-rows="2"
                        rows="2"
                        type="text"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(bezeichnungSubSektion)="row">
                    <div class="row">
                      <div class="md-3" v-for="subSektion in row.item.subSektionen" :key="subSektion.id">
                      
                        {{subSektion.bezeichnung}}
                      
                    </div>
                    </div>
                    
                  </template>

                  <template v-slot:cell(bewertung.kuerzel)="row">
                    <div class="m-0 p-0">
                      <input
                        style="min-width: 100%"
                        v-model="row.item.sektionen.subSektionen.bezeichnung"
                        placeholder="Kürzel Lehrperson"
                        type="text"
                      />
                    </div>
                  </template>
                </b-table>
              </div>-->
              <div class="row col-xl-12">
                <div
                  class="col-xl-1 bg-medium-blue mb-2"
                  style="'font-weight: 600; padding: 40px 15px; border-radius: 3px;'"
                >
                  Abwesenheiten
                </div>
                <div class="col-md-1 mt-3">
                  <div style="font-style: italic; margin-bottom: 35px">
                    Entschuldigt
                  </div>
                  <div style="font-style: italic; margin-bottom: 10px">
                    Unentschuldigt
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info.entschuldigt
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info
                            .unentschuldigt
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-1 mt-3">
                  <div style="font-style: italic; margin-bottom: 35px">
                    Entschuldigt
                  </div>
                  <div style="font-style: italic; margin-bottom: 10px">
                    Unentschuldigt
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info
                            .entschuldigt2
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info
                            .unentschuldigt2
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-1 mt-3">
                  <div style="font-style: italic; margin-bottom: 35px">
                    Entschuldigt
                  </div>
                  <div style="font-style: italic; margin-bottom: 10px">
                    Unentschuldigt
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info
                            .entschuldigt3
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info
                            .unentschuldigt3
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-1 mt-3">
                  <div style="font-style: italic; margin-bottom: 35px">
                    Entschuldigt
                  </div>
                  <div style="font-style: italic; margin-bottom: 10px">
                    Unentschuldigt
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info
                            .entschuldigt4
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info
                            .unentschuldigt4
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-1 bg-medium-blue mb-2"
                  style="'font-weight: 600; padding: 40px 15px; border-radius: 3px;'"
                >
                  Lehrjahr
                </div>
                <div class="col-md-1">
                  <div class="form-group m-0 p-0">
                    <div class="form-group">
                      <input
                        v-model.trim="
                          kompetenzrasterBewertung.semester[0].info.lehrjahr
                        "
                        class="form-control"
                        placeholder=" "
                        :tabindex="203"
                      />
                    </div>
                  </div>
                </div>
                <!---->
              </div>

              <div
                class="row col-xl-12"
                v-for="(fach, index) in kompetenzrasterBewertung.semester[
                  dieseAnmeldung.veranstaltung.periodennummer - 1
                ].kompFaecher"
                :key="fach.id"
              >
                <div
                  class="w-100"
                  v-if="faecherAnzeige[index]"
                  :key="buttonKey"
                >
                  <div
                    class="col-xl-12 bg-medium-blue mb-2"
                    style="'font-weight: 600; height: 60px; padding: 40px 15px; border-radius: 3px;'"
                  >
                    {{ fach.bezeichnung }}
                  </div>
                  <div
                    class="row col-xl-12"
                    v-for="sektion in fach.sektionen"
                    :key="sektion.id"
                  >
                    <div
                      class="col-md-3 bg-medium-blue mb-2"
                      style="'font-weight: 600; height: 60px; padding: 40px 15px; border-radius: 3px;'"
                    >
                      {{ sektion.bezeichnung }}
                      <div class="col-md-12 mt-3">
                        <div
                          style="
                            margin-top: 20px;
                            text-align: right;
                            font-style: italic;
                          "
                        >
                          {{ $t("global.selfevaluation") }}
                        </div>
                        <div
                          style="
                            margin-top: 30px;
                            text-align: right;
                            font-style: italic;
                          "
                        >
                          {{ $t("global.thirdpartyevaluation") }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-3"
                      v-for="subsektion in sektion.subSektionen"
                      :key="subsektion.id"
                    >
                      <p style="height: 50px">{{ subsektion.bezeichnung }}</p>
                      <div class="form-group m-0 p-0">
                        <div class="form-group">
                          <input
                            v-model.trim="subsektion.eigenBewertung.bewertung"
                            class="form-control"
                            placeholder=" "
                            :tabindex="203"
                          />
                        </div>
                      </div>
                      <div class="form-group m-0 p-0">
                        <div class="form-group">
                          <input
                            v-model.trim="subsektion.fremdBewertung.bewertung"
                            class="form-control"
                            placeholder=" "
                            :tabindex="203"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Hier Bemerkungsfeld Fach(xyz) einfügen -->

                  <div class="mb-2">
                    <div
                      class="col-xl-12"
                      style="
                        background-color: rgb(181, 207, 220);
                        border-top: 2px solid white;
                        border-left: 2px solid white;
                        font-weight: 600;
                      "
                    >
                      {{ $t("global.comment") }}
                    </div>
                    <div
                      class="p-2"
                      style="
                        background-color: rgb(234, 234, 234);
                        border-top: 2px solid white;
                        border-left: 2px solid white;
                      "
                    >
                      <div class="form-group p-0 m-0">
                        <textarea
                          v-model="
                            kompetenzrasterBewertung.semester[0].kompFaecher[
                              index
                            ].bemerkung_Kompetenzblock
                          "
                          class="form-control"
                          placeholder=" "
                          rows="5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="(
                  semester, semIndex
                ) in kompetenzrasterBewertung.semester"
                :key="semester.id"
              >
                <div
                  class="w-100"
                  v-if="bereicheAnzeige[semIndex]"
                  :key="buttonKey"
                >
                  <div
                    class="col-xl-12 bg-medium-blue mb-2"
                    style="'font-weight: 600; height: 60px; padding: 40px 15px; border-radius: 3px;'"
                  >
                    {{
                      kompetenzrasterBewertung.semester[semIndex]
                        .kompBereiche[0].bezeichnung
                    }}
                  </div>
                  <div
                    class="row col-xl-12"
                    v-for="sektion in kompetenzrasterBewertung.semester[
                      semIndex
                    ].kompBereiche[0].sektionen"
                    :key="sektion.id"
                  >
                    <div
                      class="col-md-3 bg-medium-blue mb-2"
                      style="'font-weight: 600; height: 60px; padding: 40px 15px; border-radius: 3px;'"
                    >
                      {{ sektion.bezeichnung }}
                      <div class="col-md-12 mt-3">
                        <div
                          style="
                            margin-top: 20px;
                            text-align: right;
                            font-style: italic;
                          "
                        >
                          {{ $t("global.selfevaluation") }}
                        </div>
                        <div
                          style="
                            margin-top: 30px;
                            text-align: right;
                            font-style: italic;
                          "
                        >
                          {{ $t("global.thirdpartyevaluation") }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-3"
                      v-for="subsektion in sektion.subSektionen"
                      :key="subsektion.id"
                    >
                      <p style="height: 50px">{{ subsektion.bezeichnung }}</p>
                      <div class="form-group m-0 p-0">
                        <div class="form-group">
                          <input
                            v-model.trim="subsektion.eigenBewertung.bewertung"
                            class="form-control"
                            placeholder=" "
                            :tabindex="203"
                          />
                        </div>
                      </div>
                      <div class="form-group m-0 p-0">
                        <div class="form-group">
                          <input
                            v-model.trim="subsektion.fremdBewertung.bewertung"
                            class="form-control"
                            placeholder=" "
                            :tabindex="203"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Hier Bemerkungsfeld Bereich(xyz) einfügen -->

                  <div class="mb-2 mt-2">
                    <div
                      class="col-xl-12"
                      style="
                        background-color: rgb(181, 207, 220);
                        border-top: 2px solid white;
                        border-left: 2px solid white;
                        font-weight: 600;
                      "
                    >
                      {{ $t("global.comment") }}
                    </div>
                    <div
                      class="p-2"
                      style="
                        background-color: rgb(234, 234, 234);
                        border-top: 2px solid white;
                        border-left: 2px solid white;
                      "
                    >
                      <div class="form-group p-0 m-0">
                        <textarea
                          v-model="
                            kompetenzrasterBewertung.semester[semIndex]
                              .kompBereiche[0].bemerkung_Kompetenzblock
                          "
                          class="form-control"
                          placeholder=" "
                          rows="5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="speichern">
            {{ $t("global.save") }}
          </button>
          <button type="button" class="btn btn-primary" @click="drucken">
            {{ $t("global.print") }}
          </button>
          <button type="button" class="btn btn-primary" @click="vorlageDrucken">
            {{ $t("global.printtemplate") }}
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $t("global.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import store from "@/store";
import { modal } from "@/mixins/Modal";

//import DatePicker from "@/components/Datepicker";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";

import ModalCloseButton from "@/components/global/ModalCloseButton.vue";

export default {
  name: "KompetenzrasterBewertungModal",
  mixins: [modal],
  components: {
    BSpinner,
    // DatePicker,
    ModalCloseButton,
  },
  store,
  props: {
    id: {
      type: String,
      required: false,
      default: "kompetenzraster-bewertung-modal",
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      buttonKey: true,
      dieseAnmeldung: null,
      dieseAnmeldungId: null,
      faecherAnzeige: [],
      bereicheAnzeige: [],
      kompetenzrasterBewertung: {
        id: null,
        bezeichnung: "",
        info: {
          datum: "",
          entschuldigt: 0,
          unentschuldigt: 0,
          faecher: [
            {
              verantwortliche: [{ name: "" }],
              verantwortlicher: { name: "" },
              bezeichnung: "",
            },
          ],
        },
        fortschritte: [
          {
            bezeichnung: "",
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
        semester: [
          {
            bezeichnung: "",
            semester: "",
            kompFaecher: [
              {
                id: null,
                bezeichnung: "",
                bemerkung_Kompetenzblock: "",
                sortierung: "",
                sektionen: [
                  {
                    id: null,
                    bezeichnung: "",
                    sortierung: "",
                    bereich: null,
                    subSektionen: [
                      {
                        id: null,
                        bezeichnung: "",
                        sortierung: "",
                        bewertungen: [
                          {
                            id: null,
                            bezeichnung: "",
                            sortierung: "",
                            bereich: null,
                            bewertung: {
                              lernende: "",
                              lehrperson: "",
                              kuerzel: "",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            kompBereiche: [
              {
                id: null,
                bezeichnung: "",
                bemerkung_Kompetenzblock: "",
                sortierung: "",
                sektionen: [
                  {
                    id: null,
                    bezeichnung: "",
                    sortierung: "",
                    bereich: null,
                    subSektionen: [
                      {
                        id: null,
                        bezeichnung: "",
                        sortierung: "",
                        bewertungen: [
                          {
                            id: null,
                            bezeichnung: "",
                            sortierung: "",
                            bereich: null,
                            bewertung: {
                              lernende: "",
                              lehrperson: "",
                              kuerzel: "",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            bewertung: {
              lernende: "",
              lehrperson: "",
              kuerzel: "",
            },
          },
        ],
      },
    };
  },
  computed: {
    fortschritteFields() {
      return [
        {
          key: "bezeichnung",
          searchable: false,
          sortable: false,
          label: this.$t("global.skillsprogress"),
          thClass: "bg-primary text-white",
          thStyle: { width: "425px" },
        },
        {
          key: "bewertung.lernende",
          searchable: false,
          sortable: false,
          label: this.$t("global.Student"),
          thClass: "bg-primary text-white",
        },
        {
          key: "bewertung.lehrperson",
          searchable: false,
          sortable: false,
          label: this.$t("global.teacher"),
          thClass: "bg-primary text-white",
        },
        {
          key: "bewertung.kuerzel",
          searchable: false,
          sortable: false,
          label: this.$t("global.teacherabbreviation"),
          thClass: "bg-primary text-white",
          thStyle: { width: "50px" },
        },
      ];
    },
    lernzieleFields() {
      return [
        {
          key: "bezeichnung",
          searchable: false,
          sortable: false,
          label: this.$t("global.section"),
          thClass: "bg-primary text-white",
          thStyle: { width: "425px" },
        },
        /*{
          key: "bezeichnungSektion",
          searchable: false,
          sortable: false,
          label: "Subsektion",
          thClass: "bg-primary text-white",
        },*/
        {
          key: "bezeichnungSubSektion",
          searchable: false,
          sortable: false,
          label: this.$t("global.subsection"),
          thClass: "bg-primary text-white",
        },
        /*{
          key: "bewertung.kuerzel",
          searchable: false,
          sortable: false,
          label: "Kürzel Lehrperson",
          thClass: "bg-primary text-white",
          thStyle: { width: "50px" },
        },*/
      ];
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("openKompetenzrasterBewertungModal", (data) => {
      if (data.id == this.id && !this.shown) {
        this.openModal();

        this.dieseAnmeldung = data.anmeldung;
        this.dieseAnmeldungId = data.anmeldungId;
        this.getKompetenzrasterBewertung();
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("openKompetenzrasterBewertungModal");

    this.isBusy = false;
    this.ladeFehler = false;
    this.buttonKey = true;
    this.dieseAnmeldung = null;
    this.dieseAnmeldungId = null;
    this.faecherAnzeige = [];
    this.bereicheAnzeige = [];
    this.kompetenzrasterBewertung = {
      id: null,
      bezeichnung: "",
      info: {
        datum: "",
        entschuldigt: 0,
        unentschuldigt: 0,
        faecher: [
          {
            verantwortliche: [{ name: "" }],
            verantwortlicher: { name: "" },
            bezeichnung: "",
          },
        ],
      },
      fortschritte: [
        {
          bezeichnung: "",
          bewertung: {
            lernende: "",
            lehrperson: "",
            kuerzel: "",
          },
        },
      ],
      semester: [
        {
          bezeichnung: "",
          semester: "",
          kompFaecher: [
            {
              id: null,
              bezeichnung: "",
              bemerkung_Kompetenzblock: "",
              sortierung: "",
              sektionen: [
                {
                  id: null,
                  bezeichnung: "",
                  sortierung: "",
                  bereich: null,
                  subSektionen: [
                    {
                      id: null,
                      bezeichnung: "",
                      sortierung: "",
                      bewertungen: [
                        {
                          id: null,
                          bezeichnung: "",
                          sortierung: "",
                          bereich: null,
                          bewertung: {
                            lernende: "",
                            lehrperson: "",
                            kuerzel: "",
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          kompBereiche: [
            {
              id: null,
              bezeichnung: "",
              bemerkung_Kompetenzblock: "",
              sortierung: "",
              sektionen: [
                {
                  id: null,
                  bezeichnung: "",
                  sortierung: "",
                  bereich: null,
                  subSektionen: [
                    {
                      id: null,
                      bezeichnung: "",
                      sortierung: "",
                      bewertungen: [
                        {
                          id: null,
                          bezeichnung: "",
                          sortierung: "",
                          bereich: null,
                          bewertung: {
                            lernende: "",
                            lehrperson: "",
                            kuerzel: "",
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          bewertung: {
            lernende: "",
            lehrperson: "",
            kuerzel: "",
          },
        },
      ],
    };
  },
  methods: {
    getBewertung() {
      Api.get("bildung/kompetenzraster/kompetenzbewertung/", {
        params: {
          id: this.dieseAnmeldungId,
          rasterID: this.dieseAnmeldung?.kompetenzraster?.id,
          semester: 6,
          person: this.dieseAnmeldung?.person.id,
        },
      })
        .then((response) => {
          this.kompetenzrasterBewertung = response.data;
          this.setAnzeige();
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    getKompetenzrasterBewertung() {
      Api.get("anmeldung/", {
        params: {
          id: this.dieseAnmeldungId,
        },
      })
        .then((response) => {
          //this.inititalizeAnmeldung(response.data);
          this.dieseAnmeldung = response.data;
          this.getBewertung();
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });

      this.isBusy = true;
    },
    initializeKompetenzrasterBewertung(response) {
      this.kompetenzrasterBewertung = response.data;
    },
    setDate(date) {
      this.kompetenzrasterBewertung?.fortschritte?.forEach((fortschritt) => {
        fortschritt.bewertung.datum = date;
      });

      this.kompetenzrasterBewertung?.lernziele?.forEach((ziel) => {
        ziel.bewertung.datum = date;
      });
    },
    closeModal() {
      this.shown = false;
      $("#kompetenzraster-bewertung-modal").modal("hide");
    },
    speichern() {
      this.isBusy = true;

      var json = Object.assign({}, this.kompetenzrasterBewertung);

      Api.post("bildung/kompetenzraster/kompetenzbewertung/", json, {
        params: {
          anmeldungId: this.dieseAnmeldungId,
          semester: this.dieseAnmeldung?.veranstaltung.periodennummer,
          person: this.dieseAnmeldung?.person.id,
        },
      })
        .then((response) => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t(
              "notification.learningobjectiveagreementsuccessfullysaved"
            ),
          });
          this.ladeFehler = false;
          this.initializeKompetenzrasterBewertung(response);
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    drucken() {
      this.speichern();
      this.$emit("kompetenzrasterDrucken", this.dieseAnmeldung);
      this.closeModal();
    },
    vorlageDrucken() {
      this.speichern();
      this.$emit("kompetenzrasterVorlageDrucken", this.dieseAnmeldung);
      this.closeModal();
    },
    setAnzeige() {
      let faecherLength =
        this.kompetenzrasterBewertung.semester[
          this.dieseAnmeldung?.veranstaltung.periodennummer - 1
        ].kompFaecher.length;

      let bereicheLength =
        this.kompetenzrasterBewertung.semester[
          this.dieseAnmeldung?.veranstaltung.periodennummer - 1
        ].kompBereiche.length;

      for (let i = 0; i < faecherLength; i++) {
        this.faecherAnzeige[i] = true;
      }
      for (let j = 0; j < bereicheLength; j++) {
        this.bereicheAnzeige[j] = true;
      }
    },
    sektionAnzeigen(typ, index) {
      this.buttonKey = !this.buttonKey;
      let inaktiveFaecher = this.faecherAnzeige.find((fach) => fach == false);
      let inaktiveBereiche = this.bereicheAnzeige.find((fach) => fach == false);

      if (typ == "fach") {
        if (inaktiveFaecher == undefined && inaktiveBereiche == undefined) {
          this.faecherAnzeige.fill(false);
          this.bereicheAnzeige.fill(false);
          this.faecherAnzeige[index] = true;
        } else {
          this.faecherAnzeige[index] = !this.faecherAnzeige[index];
        }
      } else {
        if (inaktiveFaecher == undefined && inaktiveBereiche == undefined) {
          this.bereicheAnzeige.fill(false);
          this.faecherAnzeige.fill(false);
          this.bereicheAnzeige[index] = true;
        } else {
          this.bereicheAnzeige[index] = !this.bereicheAnzeige[index];
        }
      }
    },
  },
};
</script>

<style>
.lernzielbewertungFaecher > div:last-child > div {
  border-bottom: 0 !important;
}
</style>
