<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.lecturerbooking')"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="showNeueDozentenbuchung"
              v-if="berechtigungen.m_dozierende.create"
            >
              <font-awesome-icon
                icon="fa-duotone fa-calendar-check"
                class="mr-2"
              />
              {{ $t("global.new") }}
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.m_dozierende.delete"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="dozentenbuchungen"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <neue-dozentenbuchung-modal
      :veranstaltung="veranstaltung"
      @confirmed="getDozentenBuchungen"
      :id="dozentenbuchungModalId"
    ></neue-dozentenbuchung-modal>

    <loeschen-modal
      id="dozentenbuchung-loeschen-modal"
      @confirmed="dozentenbuchungLoeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import numeral from "numeral";

import NeueDozentenbuchungModal from "@/components/Modals/NeueDozentenbuchungModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    NeueDozentenbuchungModal,
    LoeschenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    veranstaltung: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    getListeKey: {
      type: Number,
    },
  },
  data() {
    return {
      dozentenbuchungModalId: "neue-dozentenbuchung-modal",
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      dozentenbuchungen: [],
      selected: [],
      selectedIds: [],
      NeueDozentenbuchungModalId: "neue-dozentenbuchung-modal",
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "dozentenstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.statuslecturer"),
        },
        {
          key: "lohnansatz.ansatz",
          sortable: true,
          label: this.$t("global.feerate"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00") + " CHF";
            } else {
              return "-";
            }
          },
        },

        /*{
          key: "konfliktstatus.bezeichnung",
          sortable: true,
          label: "Konflikt",
        },*/
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
        filterVeranstaltung: this.veranstaltung,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  watch: {
    shown(val) {
      if (val && this.dozentenbuchungen.length == 0)
        this.getDozentenBuchungen();
    },
    getListeKey: function () {},
  },

  created() {},
  mounted() {
    if (this.shown && this.dozentenbuchungen.length == 0)
      this.getDozentenBuchungen();
    /* Da die Terminliste beim Laden sowieso einen getDozentenBuchungen() ausführt.
     * Ist der bus an dieser stelle überflüssig
     * Der bus führte auch zu fehlern bei Dropdowns in neue Termine / Dozenteneinsatz
    /* this.$bus.$on("openNeuerTerminModal", () => {
      this.getDozentenBuchungen(); 
    });*/
  },

  methods: {
    getDozentenBuchungen() {
      this.isBusy = true;
      Api.get("dozentenbuchung/", {
        params: {
          veranstaltung: this.veranstaltung,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          //response.data.shift();
          this.dozentenbuchungen = response.data;
          this.$bus.$emit("getDozentenbuchungen", this.dozentenbuchungen);
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
      this.$emit("dbUpdated");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "dozentenbuchung-loeschen-modal");
    },

    dozentenbuchungLoeschen() {
      Api.delete("dozentenbuchung/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
          this.getDozentenBuchungen();
        });
    },

    details(dozentenbuchungen) {
      this.$router.push({
        name: "dozentenbuchung",
        params: { id: dozentenbuchungen.id, anzeige: 0 },
      });
    },

    infiniteHandler($state) {
      console.log("init");
      if (this.shown) {
        Api.get("dozentenbuchung/", {
          params: this.queryParams,
        }).then((response) => {
          console.log("final");
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.dozentenbuchungen.push(...response.data);
            $state.loaded();
          } else {
            this.dozentenbuchungen.push(...response.data);
            $state.complete();
          }
        });
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    showNeueDozentenbuchung() {
      this.$bus.$emit("openNeueDozentenBuchungModal", null);
    },
  },
};
</script>
