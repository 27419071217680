<template>
  <!-- inspiriert aus ProjekterfassungModal -->
  <!-- Validationobserver sollen noch eingefügt werden -->
  <div>
    <div id="neues-projekt-erfassen-modal" class="modal fade">
      <div class="modal-dialog">
        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-content">
            <div class="modal-header">
              <modal-close-button @confirmed="closeModal" />
              <h4 class="modal-title" v-if="neuesProjekt.useCase === 'create'">
                {{ neuesProjekt.modus }} erfassen
              </h4>
              <h4 class="modal-title" v-if="neuesProjekt.useCase === 'edit'">
                {{ neuesProjekt.modus }} bearbeiten
              </h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-xl-4" v-if="neuesProjekt.useCase === 'create'">
                  Zu erstellen:
                </div>
                <div class="col-xl-4" v-if="neuesProjekt.useCase === 'edit'">
                  Zu bearbeiten:
                </div>
                <!-- Es braucht keinen Kunden hier, da ein Kunde eine Verknüpfung aus der Tabelle "Firma" ist -->
                <!--  <div class="col-xl-2">
                <button
                  type="button"
                  :class="
                    neuesProjekt.modus === 'Kunde'
                      ? 'btn btn-primary'
                      : 'btn btn-default'
                  "
                  @click="neuesProjekt.modus = 'Kunde'"
                >
                  {{ $t("global.customer") }}
                </button>
              </div> -->
                <div class="col-xl-2">
                  <button
                    type="button"
                    :class="
                      neuesProjekt.modus === 'Projekt'
                        ? 'btn btn-primary'
                        : 'btn btn-default'
                    "
                    @click="neuesProjekt.modus = 'Projekt'"
                  >
                    {{ $t("global.project") }}
                  </button>
                </div>
                <div class="col-xl-2">
                  <button
                    type="button"
                    :class="
                      neuesProjekt.modus === 'Aufgabe'
                        ? 'btn btn-primary'
                        : 'btn btn-default'
                    "
                    @click="neuesProjekt.modus = 'Aufgabe'"
                  >
                    {{ $t("global.projecttask") }}
                  </button>
                </div>
              </div>
              <div v-if="neuesProjekt.modus">
                <div class="row" v-if="neuesProjekt.modus === 'Projekt'">
                  <div class="col-xl-12">
                    <!--  <div class="form-group">
                       <input
                        class="form-control"
                        v-model="neuesProjekt.kunde"
                        v-if="neuesProjekt.modus === 'Kunde'"
                        placeholder=""
                      />
                       <v-select
                          :options="kunden"
                          label="company"
                          :reduce="(k) => k.companyId"
                          v-model="neuesProjekt.kunde"
                          v-if="neuesProjekt.modus !== 'Kunde'"
                          :placeholder="$t('global.customer')"
                        ></v-select>

                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.customer")
                        }}</label>
                    </div> -->
                    <single-select-firma
                      id="kunde"
                      :firma="neuesProjekt.kunde"
                      :allowOpen="true"
                      required
                      editable
                      v-if="neuesProjekt.modus !== 'Kunde'"
                      :label="$t('global.customer')"
                      @confirmed="setFirma"
                    />
                  </div>
                </div>
                <div class="row" v-if="neuesProjekt.modus !== 'Kunde'">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <ValidationProvider rules="required" v-slot="{ passed }">
                        <input
                          class="form-control"
                          v-model="neuesProjekt.projekt"
                          v-if="neuesProjekt.modus === 'Projekt'"
                          placeholder=""
                        />
                        <v-select
                          :options="
                            filteredProjects ? filteredProjects : projekte
                          "
                          :getOptionLabel="(projekt) => projekt.projektName"
                          :reduce="(k) => k.id"
                          v-model="neuesProjekt.projekt"
                          v-if="neuesProjekt.modus !== 'Projekt'"
                          :placeholder="$t('global.project')"
                        ></v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.project")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    neuesProjekt.modus !== 'Kunde' &&
                    neuesProjekt.modus === 'Projekt'
                  "
                >
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        class="form-control"
                        v-model="neuesProjekt.kuerzel"
                        placeholder=""
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    neuesProjekt.modus !== 'Kunde' &&
                    neuesProjekt.modus === 'Projekt'
                  "
                >
                  <div class="col-xl-6">
                    <single-select-person
                      id="projektleiter"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      editable
                      :label="$t('global.projectlead')"
                      @confirmed="setProjectlead"
                      :person="neuesProjekt.projectLead"
                    />
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="ansprechpartner"
                      :allowOpen="berechtigungen.m_geschaeftspartner.read"
                      editable
                      :label="$t('global.contactperson')"
                      @confirmed="setAnsprechpartner"
                      :person="neuesProjekt.ansprechpartner"
                    />
                  </div>
                </div>
                <div class="row" v-if="neuesProjekt.modus === 'Aufgabe'">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <ValidationProvider rules="required" v-slot="{ passed }">
                        <input
                          class="form-control"
                          v-model="neuesProjekt.aufgabe"
                          placeholder=""
                        />
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                          $t("global.projecttask")
                        }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="neuesProjekt.modus !== 'Kunde'">
                  <div class="col-xl-6" v-if="neuesProjekt.modus === 'Aufgabe'">
                    <div class="form-group">
                      <input
                        class="form-control"
                        v-model.number="neuesProjekt.sollStunden"
                      />
                      <label>{{ $t("global.targethours") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6" v-if="neuesProjekt.modus === 'Projekt'">
                    <div class="form-group">
                      <v-select
                        :options="projektstati"
                        label="bezeichnung"
                        :reduce="(ps) => ps.id"
                        v-model="neuesProjekt.projektstatus"
                        :placeholder="$t('global.projectstatus')"
                      ></v-select>
                      <label>{{ $t("global.projectstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row mb-2" v-if="neuesProjekt.modus === 'Aufgabe'">
                  <div class="col-xl-6">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="neuesProjekt.abrechenbar"
                      />
                      <label class="form-check-label">{{
                        $t("global.billable")
                      }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="neuesProjekt.abgerechnet"
                      />
                      <label class="form-check-label">{{
                        $t("global.billed")
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <Datepicker
                        date-format="dd.mm.yy"
                        show-icon
                        @update="(val) => (neuesProjekt.startdatum = val)"
                        :in-modal="true"
                      />

                      <label>{{ $t("global.startdate") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6" v-if="neuesProjekt.modus !== 'Kunde'">
                    <div class="form-group">
                      <Datepicker
                        date-format="dd.mm.yy"
                        show-icon
                        @update="(val) => (neuesProjekt.enddatum = val)"
                        :in-modal="true"
                      />
                      <label>{{ $t("global.enddate") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" @click="closeModal">
                {{ $t("global.cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                v-if="neuesProjekt.useCase === 'create'"
                :disabled="invalid"
                @click="speichern"
              >
                {{ $t("global.save") }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-if="neuesProjekt.useCase === 'edit'"
                :disabled="invalid"
                @click="uebernehmen"
              >
                {{ $t("global.apply") }}
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import Datepicker from "@/components/Datepicker";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

export default {
  name: "NeuesProjektErfassenModal",
  components: {
    ModalCloseButton,
    Datepicker,
    SingleSelectPerson,
    SingleSelectFirma,
  },
  props: {
    // Kunden/Projekte könnten aus Projekterfassungsuebersichtliste als Prop hierher übergeben werden, da dieser Get dort vermutlich schon gemacht wird.
    kunden: {
      type: Array,
      required: true,
    },
    projekte: {
      type: Array,
      required: true,
    },
    projektstati: {
      type: Array,
      required: false,
    },
    /*   modalUseCase: {
      type: String,
      required: true,
    }, */
  },
  data() {
    return {
      shown: false,
      neuesProjekt: {
        useCase: "",
        modus: "Aufgabe",
        kunde: {},
        projekt: "",
        aufgabe: "",
        startdatum: null,
        enddatum: null,
        sollStunden: null,
        abrechenbar: null,
        kuerzel: "",
        projectLead: {},
        projektstatus: "18355F4C32C", // aktiv
        ansprechpartner: {},
        abgerechnet: false,
      },
      filteredProjects: null,
    };
  },
  computed: {},
  watch: {
    ["neuesProjekt.modus"]: {
      handler() {
        this.clearProjekt();
      },
    },
    ["neuesProjekt.kunde.id"]: {
      // Filter für Auswahlwerte im Projekte v-select
      // Werte sind dafür aber nicht vorhanden
      handler() {
        this.filteredProjects = this.projekte.filter((projekt) => {
          return projekt.companyId === this.neuesProjekt.kunde.id;
        });
      },
    },
  },
  created() {},
  mounted() {
    this.$bus.$on("neues-projekt-erfassen", () => {
      if (!this.shown) {
        this.neuesProjekt.useCase = "create";
        this.openModal();
      }
    });
    this.$bus.$on("bearbeite-projekt", () => {
      if (!this.shown) {
        this.neuesProjekt.useCase = "edit";
        this.openModal();

        //  GET alle Projekte und Aufgaben
        /*  if (this.neuesProjekt.modus === "Projekt") {
              GET dieses Projekt -> this.neuesProjekt = response.data
            }
                */
      }
    });
  },
  methods: {
    openModal() {
      this.shown = true;

      $("#neues-projekt-erfassen-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      this.neuesProjekt.modus = "Aufgabe";
      this.neuesProjekt.useCase = "";
      this.neuesProjekt.kunde = {};
      this.clearProjekt();
      this.$emit("abbrechen");
      $("#neues-projekt-erfassen-modal").modal("hide");
    },

    speichern() {
      const json = Object.assign({}, this.neuesProjekt);
      this.$emit("erfasseNeuesProjekt", json);

      this.closeModal();
    },
    clearProjekt() {
      this.neuesProjekt.projekt = "";
      this.neuesProjekt.aufgabe = "";
      this.neuesProjekt.startdatum = null;
      this.neuesProjekt.enddatum = null;
      this.neuesProjekt.sollStunden = null;
      this.neuesProjekt.abrechenbar = null;
      this.neuesProjekt.kuerzel = "";
      this.neuesProjekt.projectLead = {};
      this.neuesProjekt.projektstatus = "18355F4C32C"; // aktiv
      this.neuesProjekt.ansprechpartner = {};
      this.neuesProjekt.abgerechnet = false;
    },
    setProjectlead(person) {
      this.neuesProjekt.projectLead = person;
    },
    setAnsprechpartner(person) {
      this.neuesProjekt.ansprechpartner = person;
    },
    setFirma(firma) {
      this.neuesProjekt.kunde = firma;
    },
    uebernehmen() {
      const json = Object.assign({}, this.neuesProjekt);
      this.$emit("editProjekt", json);

      this.closeModal();
    },
  },
};
</script>

<style></style>
